import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { backend, mediaurl } from "../../../path";
import DashboardMain from "../main";
import DashboardSidebarMain from "../dashsidebar";
import HeaderDashMain from "../../layout/header/dash header";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Pagination from "../../components/pagination";
function DashboardCategoryMain() {
  // const [formData, setFormData] = useState([]);
  const [formData, setFormData] = useState({
    category_name: '',
    description: '', // Assuming description is a string
    logo: '', // Assuming logo is a string
    seo: '', // Assuming logo is a string
    meta_keyword: '', // Assuming logo is a string
    meta_description: '', // Assuming logo is a string
  });
  console.log(formData)
  const [data, setData] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [view, setView] = useState(0);
  const [logo, setLogo] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [dummyData, setDummyData] = useState({});
  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );
  const fetchData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getcategory?page_number=" + pageNumber + "&page_size=" + pageSize, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [pageNumber,pageSize]);

  const handlefileupload = async (e) => {
    try {
      console.log("File selected:", e.target.files[0]);
      let formdata = new FormData();
      formdata.append("csvFile", e.target.files[0]);

      const response = await fetch(backend + "csvhandlecategory", {
        method: "POST",
        body: formdata,
        "Content-Type": "multipart/form-data",
      });

      if (!response.ok) {
        throw new Error(`File upload failed with status ${response.status}`);
      }

      const data = await response.json();
    } catch (error) {
      console.error("Error during file upload:", error);
    }
  };

  const handleFileUpload = async (e) => {
    const fileInput = e.target;
    const file = fileInput.files[0];

    if (file) {
      const formDatas = new FormData();
      formDatas.append("csvFile", file);

      try {
        const response = await fetch(backend + "csvhandlecategory", {
          method: "POST",
          body: formDatas,
          mode: "no-cors",
        });

        if (response.ok) {
          // File successfully uploaded
          console.log("File uploaded successfully");
        } else {
          // Handle error if the request was not successful
          console.error("File upload failed");
        }
      } catch (error) {
        // Handle network errors or other issues
        console.error("Error during file upload:", error);
      }

      // Optional: Clear the file input after uploading
      fileInput.value = "";
    }
  };


  const handleStatusOff = (id) => {
    // formData.status = 1;

    dummyData.category_id = id;
    dummyData.status = 1;

    fetch(backend + "updatecategorystatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(dummyData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            console.error("Something Went Wrong");
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Something Went Wrong");
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          console.log("success");
          fetchData()
          // setView(1);
          // Additional success handling code here
          toast.success("Updated", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleStatus = (id) => {
    // formData.status = 1;

    dummyData.category_id = id;
    dummyData.status = 0;

    fetch(backend + "updatecategorystatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(dummyData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            console.error("Something Went Wrong");
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Something Went Wrong");
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          console.log("success");
          fetchData()
          // setView(1);
          // Additional success handling code here
          toast.success("Updated", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleView = (id) => {
    const edit = data.find((item) => item.category_id === id);
    setView(1);
    setFormData(edit);
  };

  const handlefileuploadEdit = (e) => {
    let formdata = new FormData();
    formdata.append("document", e.target.files[0]);

    fetch(backend + "fileuploadcategory", {
      method: "POST",
      body: formdata,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        setFormData({
          ...formData,
          logo: data,
        });
        setLogo = data;
      })
      .catch((error) => {
        console.error("Error during file upload:", error);
      });
  };

  const handleSubmit = (id) => {
    formData.status = 0;
    formData.category_id = id;

    fetch(backend + "editcategory", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          console.log("success");

          // Additional success handling code here
          toast.success("Category Added", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
        setView(0);
      })
      .catch((error) => {
        toast.error("Something Went Wrong", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handlePageClick = (pageNumber) => {
    setPageNumber(pageNumber);
  };
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-2 d-none d-lg-flex">
            <DashboardSidebarMain />
          </div>
          {view === 0 ?(
<div className="col-lg-10">
            <div className="login_wrap widget-taber-content background-white">
              <div className="padding_eight_all bg-white">
                <div className="padding_eight_all bg-white">
                  <div className="d-flex justify-content-between mb-20 mt-20">
                    <h3 className="">Category</h3>
                    <div className="d-flex justify-content-end gap-3">
                      <a href="/dash-category-add">
                        <button
                          type="button"
                          className="btn btn-fill-out btn-block hover-up"
                          //   onClick={handleSubmit}
                        >
                          Add New
                        </button>
                      </a>
                      <div>
                        {/* Icon component */}
                        <Icon
                          icon="fa6-solid:file-csv"
                          color="green"
                          className="hover-up"
                          fontSize={46}
                          onClick={() =>
                            document.getElementById("fileInput").click()
                          }
                          style={{ cursor: "pointer" }}
                        />

                        {/* File input */}
                        <input
                          type="file"
                          id="fileInput"
                          accept=".csv"
                          onChange={handlefileupload}
                          style={{ display: "none" }}
                        />
                      </div>
                    </div>
                  </div>
                  <br></br>
                </div>

                <div className="table-responsive">
                  <table className="table table-secondary table-hover">
                    <thead className="table-warning">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Category Name</th>
                        <th scope="col">Logo</th>
                        <th scope="col">Description</th>
                        <th scope="col">Created At</th>
                        <th scope="col">Updated At</th>
                        <th scope="col">Status</th>
                        <th scope="col">Actions</th>

                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{item.category_name}</td>
                          <td className="truncate">
                            {item.logo === "" ? (
                              ""
                            ) : (
                              <img
                                src={mediaurl + item.logo}
                                style={{ height: "50px", width: "100px" }}
                              />
                            )}
                          </td>
                          <td>{item.description}</td>
                          <td>{item.created_at}</td>
                          <td>
                            {item.updated_at === null ? (
                              "Not Updated"
                            ) : (
                              <>{item.updated_at}</>
                            )}
                          </td>
                          <td>
                            {item.status === 0 ? "Available" : "Not Available"}
                          </td>
                          <td>
                              {item.status === 0 ? (
                                <Icon
                                  icon="carbon:view-filled"
                                  color="green"
                                  className="cursor-pointer ml-2"
                                  fontSize={20}
                                  onClick={() => handleStatusOff(item.category_id)}
                                />
                              ) : item.status === 1 ? (
                                <Icon
                                  icon="carbon:view-off-filled"
                                  color="#b83e0c"
                                  className="cursor-pointer ml-2"
                                  fontSize={20}
                                  onClick={() => handleStatus(item.category_id)}
                                />
                              ) : (
                                ""
                              )}{" "}
                              <Icon
                                icon="basil:edit-solid"
                                color="#1a42cc"
                                className="cursor-pointer ml-2"
                                fontSize={20}
                                onClick={() => handleView(item.category_id)}
                              />
                            </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* <div className="form-group">
                <button
                  type="button"
                  className="btn btn-fill-out btn-block hover-up"
                  onClick={handleSubmit}
                >
                  Register
                </button>
              </div> */}
              <br></br>
              <Pagination
        currentPage={pageNumber}
        pageSize={pageSize}
        totalItems={data.length}
        onPageChange={handlePageClick}
      />
            </div>
          </div>
          ):(
            <div className="col-lg-10">
            <div className="login_wrap widget-taber-content background-white">
              <div className="padding_eight_all bg-white">
                <div className="padding_eight_all bg-white">
                  <div className="d-flex justify-content-between mb-20 mt-20">
                    <h3 className="">Add Category</h3>
                    {/* <a href=""> */}
                      <button
                        type="button"
                        className="btn btn-fill-out btn-block hover-up"
                        onClick={()=>setView(0)}
                      >
                        Back
                      </button>
                    {/* </a> */}
                  </div>
                  <br></br>
                </div>
                {/* <form
                // onSubmit={handleSubmit}
                > */}
                  <input
                    type="hidden"
                    name="_token"
                    value="bVwKUehCmCMBBOuQToUzdDhTTdTBfg78RlsalVJQ"
                    autocomplete="off"
                  />
                  <div className="form__content">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Category Name</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="category_name"
                            id="txt-name"
                            type="text"
                            required
                            value={formData.category_name || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                category_name: e.target.value,
                              })
                            }
                            placeholder="Category Name"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="logo">Logo</label>
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="logo"
                            id="txt-name"
                            type="file"
                            // value={formData.logo}
                            required
                            onChange={(event) => {
                              handlefileuploadEdit(event);

                            }}
                            
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label htmlFor="name">Description </label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          {/* <input
                            className="form-control"
                            name="category_name"
                            id="txt-name"
                            type="text"
                            required
                            value={formData.description || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                description: e.target.value,
                              })
                            }
                            placeholder="Description"
                          />         */}
                          {/* <Editor required onSave={handleEditorSave} /> */}

                          <CKEditor
                            editor={ClassicEditor}
                            data={formData.description}

                            onInit={editor => {
                              // You can store the "editor" and use when it is needed.
                              // console.log('Editor is ready to use!', editor);
                            }}

                            onChange={(event, editor) => {
                              const data = editor.getData();
                              console.log({ event, editor, data });
                              setFormData({
                                ...formData,
                                description: data,
                              })
                            }}
                            onBlur={(event, editor) => {
                              console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor, data) => {
                              console.log('Focus.', editor);
                            }}
                          />

                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Seo Title</label>{" "}
                          <input
                            className="form-control"
                            name="seo"
                            id="txt-name"
                            type="text"
                            value={formData.seo || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                seo: e.target.value,
                              })
                            }
                            placeholder="Seo Title"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Seo Meta keywords</label>{" "}
                          <input
                            className="form-control"
                            name="meta_keyword"
                            id="txt-name"
                            type="text"
                            value={formData.meta_keyword || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                meta_keyword: e.target.value,
                              })
                            }
                            placeholder="Seo Meta keywords"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Seo Meta Description</label>{" "}
                          <input
                            className="form-control"
                            name="meta_description"
                            id="txt-name"
                            type="text"
                            value={formData.meta_description || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                meta_description: e.target.value,
                              })
                            }
                            placeholder="Seo Meta Description"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-fill-out btn-block hover-up"
                      onClick={()=>handleSubmit(formData.category_id)}
                    >
                      Submit
                    </button>
                  </div>
                  <br></br>
                {/* </form> */}
              </div>
            </div>
          </div>
          )}
          
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
export default DashboardCategoryMain;
