import React, { useEffect, useState } from "react";
import UncontrolledExample from "./carousel";
import { backend, mediaurl, producturl } from "../../../../path";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import ScrollToTop from "../../../components/whatsapp connect";
import { Rating } from "@mui/material";
import { Helmet } from "react-helmet";
import LoaderMain from "../../../layout/header/loader_sub";

function Homepage(props) {

  // useEffect(()=>{
  //   let userdata = JSON.parse(localStorage.getItem('userdata'));
  //   fetch(backend + "tokentest", {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       Authorization:`Bearer ${userdata.access_token}`,
  //       "Content-Type": "application/json",
  //     }
  //   })
  //     .then((response) => {
  //       response.json().then((data) => {
  //       console.log(data)

  //       });
  //     })
  //     .catch((error) => {
  //       console.error("Error during login:", error);
  //     });
  // },[])
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [subEmail, setSubEmail] = useState('');

  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );

  const fetchData = () => {

    setLoading(true);

    fetch(backend + "getallspecialproduts", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // Scroll to the top of the page whenever a new page is rendered
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {

    fetchData();
  }, []);

  const handleAddCart = async (id) => {
    if (authenticated === null) {
      window.location.href = "/login";
      return;
    }

    const formData = {
      status: 1,
      quantity: 1,
      product_id: id,
    };

    try {
      const response = await fetch(backend + "addtocartuserside", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        toast.success("Item added to cart", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.setCartChange(!props.cartChange)
        await new Promise((resolve) => setTimeout(resolve, 3000));
        // navigate("/cart");
      } else if (response.status === 409) {
        toast.error("Conflict occurred. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (response.status === 401) {
        toast.error("Item already exits in the cart", {

          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Something went wrong. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleProductRoute = (productId) => {
    navigate(`/product/${productId}`);
    // window.location.reload();
  };
  const handleAddToWishList = async (id) => {
    if (authenticated === null) {
      window.location.href = "/login";
      return;
    }

    const formData = {
      status: 0,
      product_id: id,
    };

    try {
      const response = await fetch(backend + "addtowishlistuserside", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        toast.success("Item added to wishlist", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.setWishChange(!props.wishChange)
        await new Promise((resolve) => setTimeout(resolve, 3000));
        // navigate("/cart");
      } else if (response.status === 409) {
        toast.error("Conflict occurred. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (response.status === 401) {
        toast.error("Item already exits in the wishlist", {

          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Something went wrong. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleSubscribe = () => {

    fetch(backend + "addsubscription", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: subEmail }),
    })
      .then((response) => {
        setSubEmail('')
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            console.error("Email Already Exists");
            toast.error("Email Already Exists", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Registration failed");
            toast.error("Registration failed", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          toast.success("Subscribed Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <>
      {loading &&
        <LoaderMain />
      }


      <Helmet><title>Best online products in kerala</title>
        <meta name="description" content="Discover Kerala's finest products online! Handicrafts, spices, Ayurveda, handlooms & more. Fast & reliable shipping." />
        <meta name="keywords" content="online shopping,online shopping sites,online store" />
      </Helmet>
      <main className="main" id="main-section">
        <div className="ck-content">
          <h1 className="d-none">Handicrafts, Spices , Ayurveda , Handlooms</h1>
          <div>
            <section className="home-slider position-relative">
              <div className="container">
                <div className="row">
                  <div className="col-lg-9">
                    <div className="home-slide-cover mt-30">
                      <div className="hero-slider-1 style-5 dot-style-1 dot-style-1-position-2">
                        <UncontrolledExample />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 d-none d-lg-block d-md-block">
                    <div className="row mt-20">
                      <div className="col-md-6  col-lg-12 mt-10">
                        <div className="banner-img wow animate__animated animate__fadeInUp banner-1">
                          <div>
                            <div target="_blank">
                              <picture>
                                <source
                                  srcset="storage/general/ethnic.png"
                                  media="(min-width: 1200px)"
                                />
                                <source
                                  srcset="storage/general/ethnic.png"
                                  media="(min-width: 768px)"
                                />
                                <source
                                  srcset="storage/general/ethnic.png"
                                  media="(max-width: 767px)"
                                />
                                <img
                                  src="storage/general/ethnic.png"
                                  alt="Kerala Specials"
                                  className="page_speed_2025002599"
                                  style={{ width: '345px', height: '266px' }}
                                />
                              </picture>
                            </div>
                          </div>
                          <div className="banner-text">
                            <h4>
                              {/* Everyday Fresh &amp; Clean with Our Products */}
                            </h4>
                            {/* <Link to="/" className="btn btn-xs">
                              {" "}
                              Shop now{" "}
                              <i className="fi-rs-arrow-small-right"></i>
                            </Link> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-12 mt-5">
                        <div className="banner-img wow animate__animated animate__fadeInUp banner-2">
                          <div>
                            <div target="_blank">
                              <picture>
                                <source
                                  srcset="storage/general/herbal.png"
                                  media="(min-width: 1200px)"
                                />
                                <source
                                  srcset="storage/general/herbal.png"
                                  media="(min-width: 768px)"
                                />
                                <source
                                  srcset="storage/general/herbal.png"
                                  media="(max-width: 767px)"
                                />
                                <img
                                  src="storage/general/herbal.png"
                                  alt="Kerala Specials"
                                  className="page_speed_2025002599"
                                  style={{ width: '345px', height: '266px' }}

                                />
                              </picture>
                            </div>
                          </div>
                          <div className="banner-text">
                            {/* <h4>The best Organic Products Online</h4> */}
                            {/* <Link to="/" className="btn btn-xs">
                              {" "}
                              Shop now{" "}
                              <i className="fi-rs-arrow-small-right"></i>
                            </Link> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div>
            <section className="banners mt-30 d-none d-lg-block">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-4 col-md-6">
                    <div
                      className="banner-img wow animate__animated animate__fadeInUp "
                      data-wow-delay="0.2"
                    >
                      <div>
                        <Link to="/Unveiling Kerala's Gems">

                          <picture>
                            <source
                              srcset="storage/general/small banner 1 new.png"
                              media="(min-width: 1200px)"
                            />
                            <source
                              srcset="storage/general/small banner 1 new.png"
                              media="(min-width: 768px)"
                            />
                            <source
                              srcset="storage/general/small banner 1 new.png"
                              media="(max-width: 767px)"
                            />
                            <img
                              src="storage/general/small banner 1 new.png"
                              alt="Kerala Specials"
                              className="page_speed_2025002599"
                            />
                          </picture>
                        </Link>
                      </div>
                      <div className="banner-text">
                        {/* <h4>
                          Unveiling Kerala's Gems
                        </h4> */}
                        <Link to="/Unveiling Kerala's Gems" className="btn btn-xs">
                          Shop now <i className="fi-rs-arrow-small-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div
                      className="banner-img wow animate__animated animate__fadeInUp "
                      data-wow-delay="0.4"
                    >
                      <div>
                        <Link to="/Todays Featured Finds" target="_blank">
                          <picture>
                            <source
                              srcset="storage/general/small banner 2.png"
                              media="(min-width: 1200px)"
                            />
                            <source
                              srcset="storage/general/small banner 2.png"
                              media="(min-width: 768px)"
                            />
                            <source
                              srcset="storage/general/small banner 2.png"
                              media="(max-width: 767px)"
                            />
                            <img
                              src="storage/general/small banner 2.png"
                              alt="Kerala Specials"
                              className="page_speed_2025002599"
                            />
                          </picture>
                        </Link>
                      </div>
                      <div className="banner-text">
                        {/* <h4>Today's Featured Finds</h4> */}
                        <Link to="/Todays Featured Finds" className="btn btn-xs">
                          {" "}
                          Shop now <i className="fi-rs-arrow-small-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div
                      className="banner-img wow animate__animated animate__fadeInUp "
                      data-wow-delay="0.6"
                    >
                      <div>
                      <Link to="/Unveiling Ayurvedic Delights for the Day" >
                      <picture>
                            <source
                              srcset="storage/general/small banner 3.png"
                              media="(min-width: 1200px)"
                            />
                            <source
                              srcset="storage/general/small banner 3.png"
                              media="(min-width: 768px)"
                            />
                            <source
                              srcset="storage/general/small banner 3.png"
                              media="(max-width: 767px)"
                            />
                            <img
                              src="storage/general/small banner 3.png"
                              alt="Kerala Specials"
                              className="page_speed_2025002599"
                            />
                          </picture>
                        </Link>
                      </div>
                      <div className="banner-text">
                        {/* <h4>Ayurvedic Delights of the Day</h4> */}
                        <Link to="/Unveiling Ayurvedic Delights for the Day" className="btn btn-xs">
                          {" "}
                          Shop now <i className="fi-rs-arrow-small-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="banners mt-30 d-block d-lg-none d-md-block">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-12">
                    <div
                      className="banner-img wow animate__animated animate__fadeInUp "
                      data-wow-delay="0.2"
                    >
                      <div>
                        <Link to="/Unveiling Ayurvedic Delights for the Day" target="_blank">
                          <picture>
                            <source
                              srcset="storage/general/small banner 1 new.png"
                              media="(min-width: 1200px)"
                            />
                            <source
                              srcset="storage/general/small banner 1 new.png"
                              media="(min-width: 768px)"
                            />
                            <source
                              srcset="storage/general/small banner 1 new.png"
                              media="(max-width: 767px)"
                            />
                            <img
                              src="storage/general/small banner 1 new.png"
                              alt="Kerala Specials"
                              className="page_speed_2025002599"
                            />
                          </picture>
                        </Link>
                      </div>
                      <div className="banner-text">
                        {/* <h4>
                          Unveiling Kerala's Gems
                        </h4> */}
                        {/* <Link to="/Unveiling Ayurvedic Delights for the Day" className="btn btn-xs">
                          Shop now <i className="fi-rs-arrow-small-right"></i>
                        </Link> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div
                      className="banner-img wow animate__animated animate__fadeInUp "
                      data-wow-delay="0.4"
                    >
                      <div>
                        <Link to="/Todays Featured Finds" target="_blank">
                          <picture>
                            <source
                              srcset="storage/general/small banner 2.png"
                              media="(min-width: 1200px)"
                            />
                            <source
                              srcset="storage/general/small banner 2.png"
                              media="(min-width: 768px)"
                            />
                            <source
                              srcset="storage/general/small banner 2.png"
                              media="(max-width: 767px)"
                            />
                            <img
                              src="storage/general/small banner 2.png"
                              alt="Kerala Specials"
                              className="page_speed_2025002599"
                            />
                          </picture>
                        </Link>
                      </div>
                      <div className="banner-text">
                        {/* <h4>Today's Featured Finds</h4> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div
                      className="banner-img wow animate__animated animate__fadeInUp "
                      data-wow-delay="0.6"
                    >
                      <div>
                        <Link to="/Unveiling Kerala's Gems" target="_blank">
                          <picture>
                            <source
                              srcset="storage/general/small banner 3.png"
                              media="(min-width: 1200px)"
                            />
                            <source
                              srcset="storage/general/small banner 3.png"
                              media="(min-width: 768px)"
                            />
                            <source
                              srcset="storage/general/small banner 3.png"
                              media="(max-width: 767px)"
                            />
                            <img
                              src="storage/general/small banner 3.png"
                              alt="Kerala Specials"
                              className="page_speed_2025002599"
                            />
                          </picture>
                        </Link>
                      </div>
                      <div className="banner-text">
                        {/* <h4>Ayurvedic Delights of the Day</h4> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div>
            <section className="section-padding pb-5">
              <div className="container">
                <div
                  className="section-title wow animate__animated animate__fadeIn"
                  data-wow-delay="0"
                >
                  <Link className="btn btn-lg mb-2 w-100"
                    style={{
                      fontSize: '24px',
                      backdropFilter: 'inherit',
                      backgroundBlendMode: 'color-burn',
                      backgroundColor: '#ADD899',
                      fontWeight: 'bolder',
                      color: '#fff',
                      boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'
                    }}
                  >
                    Deals of the Day
                  </Link>
                  {/* <h1 className="hover-up d-none" style={{ fontWeight: 'bolder', color: '#3BB77E', display: 'none' }}>Deals of the Day</h1> */}
                </div>
                <div className="row justify-content-center">
                  {data?.getDealOfTheDay?.map((product) => (
                    <div key={product.id} className="col-xxl-3 col-xl-4 col-lg col-md-6">
                      <div className="product-cart-wrap style-2 wow animate__animated animate__fadeInUp" data-wow-delay="0"

                      >
                        <div className="product-img-action-wrap">
                          <div className="product-img">
                            <Link
                              to={`/product/${product.product_slug}`}
                              onClick={() => handleProductRoute(product.product_slug)}
                              // target="_blank"
                            >
                              <img src={producturl + product.thumbnail} />
                            </Link>
                          </div>
                        </div>
                        <div className="product-content-wrap"
                          style={{
                            boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;'
                          }}>
                          <div className="deals-countdown-wrap">
                            <div className="deals-countdown" data-countdown={product.countdown}></div>
                          </div>
                          <div className="deals-content" style={{
                            boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;'
                          }}>
                            <h2 className="text-truncate">
                              <Link
                                to={`/product/${product.product_slug}`}
                                onClick={() => handleProductRoute(product.product_slug)}
                                // target="_blank"
                              >
                                {product.product_name}
                              </Link>
                            </h2>
                            <div className="product-rate-cover">
                              <div className="d-inline-block">
                                <Rating name="read-only" value={product?.average_stars} readOnly size="small" />
                              </div>
                              <span className="font-small ml-5 text-muted" style={{ verticalAlign: 'text-bottom' }}>
                                ( {product?.comments?.length ?? "0"} reviews)
                              </span>
                            </div>
                            <div className="text-truncate">
                              <span className="font-small text-muted">Sold By <Link to="" target="_blank"> {product.vendor_name}</Link></span>
                            </div>
                            <div className="product-card-bottom">
                              <div className="product-price">
                                <span>₹ {product.mrp}</span>
                                <span className="old-price">{product.oldPrice}</span>
                              </div>
                              <div className="add-cart">
                                <Link
                                  aria-label="Add To Cart"
                                  className="action-btn add-to-cart-button add"
                                  data-id="11"
                                  onClick={() =>
                                    handleAddCart(product.product_id)
                                  }
                                >
                                  <i className="fi-rs-shopping-cart mr-5"></i>
                                  <span className="d-inline-block">Add</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                </div>

              </div>
            </section>
          </div>
          <div>
            <section className="product-tabs section-padding position-relative">
              <div className="container">
                <div className="section-title style-2 wow animate__animated animate__fadeIn">
                  <Link className="btn btn-lg mb-2 w-100"
                    style={{
                      fontSize: '24px',
                      backdropFilter: 'inherit',
                      backgroundBlendMode: 'color-burn',
                      backgroundColor: '#ADD899',
                      fontWeight: 'bolder',
                      color: '#fff',
                      boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'
                    }}
                  >
                    Popular Products
                  </Link>

                  <div className="title">

                    {/* <h1 className="hover-up d-none" style={{ fontWeight: 'bolder', color: '#3BB77E' }}>Popular Products</h1> */}
                  </div>
                </div>
                <div className="row product-grid-4">
                  {!loading && (
                    data?.getPopularProduct?.map((item, i) => (
                      <div
                        key={i}
                        className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-12 col-sm-3"
                      >
                        <div
                          className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                          data-wow-delay="0.1s"
                          style={{
                            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
                          }}
                        >
                          <div className="product-img-action-wrap">
                            <div className="product-img product-img-zoom">
                              <Link
                                to={`/product/${item.product_slug}`}
                                onClick={() => handleProductRoute(item.product_slug)}
                              >
                                <img
                                  className="default-img"
                                  style={{ height: "320px", width: "100%", objectFit: 'contain', objectPosition: 'center' }}
                                  src={producturl + item.thumbnail}
                                  alt={item.product_name}
                                />

                                <img
                                  className="hover-img"
                                  style={{ height: "320px", width: "100%", objectFit: 'contain', objectPosition: 'center' }}
                                  src={item.thumbnail2 ? producturl + item.thumbnail2 : producturl + item.thumbnail}
                                  alt={item.product_name}
                                />
                              </Link>
                            </div>
                            <div className="product-action-1">
                              <Link
                                aria-label="Quick View"
                                to={`/product/${item.product_slug}`}
                                onClick={() => handleProductRoute(item.product_slug)}
                                className="action-btn hover-up js-quick-view-button"
                              >
                                <i className="fi-rs-eye"></i>
                              </Link>
                              <Link
                                aria-label="Add To Wishlist"
                                onClick={() => handleAddToWishList(item.product_id)}
                                className="action-btn hover-up js-add-to-wishlist-button"
                              >
                                <i className="fi-rs-heart"></i>
                              </Link>
                              {/* <a
                            aria-label="Add To Compare"
                            onClick={() => handleProductRoute(item.product_id)}
                            className="action-btn hover-up js-add-to-compare-button"
                          >
                            <i className="fi-rs-shuffle"></i>
                          </Link> */}
                            </div>
                            <div className="product-badges product-badges-position product-badges-mrg"></div>
                          </div>
                          <div className="product-content-wrap">
                            <div className="product-category">
                              <Link
                                to={`/product/${item.product_slug}`}
                                onClick={() => handleProductRoute(item.product_slug)}
                              >
                                {item?.category_name}
                              </Link>
                            </div>
                            <h2 className="text-truncate">
                              <Link
                                to={`/product/${item.product_slug}`}
                                onClick={() => handleProductRoute(item.product_slug)}
                                title="Nestle Original Coffee-Mate Coffee Creamer"
                              >
                                {item?.product_name}
                              </Link>
                            </h2>
                            <div className="product-rate-cover">
                              <div className="d-inline-block">
                                <div className="d-inline-block">
                                  <Rating name="read-only" value={item?.average_stars} readOnly size="small" />
                                </div>
                                <span className="font-small ml-5 text-muted" style={{ verticalAlign: 'text-bottom' }}>
                                  ( {item?.comments?.length ?? "0"} reviews)
                                </span>
                              </div>
                            </div>
                            <div className="text-truncate">
                              <span className="font-small text-muted">
                                Sold By{" "}
                                <Link
                                  to={`/product/${item.product_slug}`}
                                  onClick={() =>
                                    handleProductRoute(item.product_slug)
                                  }
                                >
                                  {item?.vendor_name ?? "KeralaSpecial"}
                                </Link>
                              </span>
                            </div>
                            <div className="product-card-bottom">
                              <div className="product-price">
                                <span>₹ {item.mrp}</span>
                              </div>
                              <div className="add-cart">
                                <Link
                                  aria-label="Add To Cart"
                                  className="action-btn add-to-cart-button add"
                                  data-id="11"
                                  onClick={() =>
                                    handleAddCart(item.product_id)
                                  }
                                >
                                  <i className="fi-rs-shopping-cart mr-5"></i>
                                  <span className="d-inline-block">Add</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )))}
                </div>
              </div>
            </section>
          </div>
          <div>
            <div
              className="modal fade custom-modal"
              id="flash-sale-modal"
              data-id="flash-sale-id-1"
              tabindex="-1"
              aria-labelledby="onloadModalLabel"
              aria-hidden="true"
              role="dialog"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                  <div className="modal-body">
                    <div className="deal page_speed_1887552618">
                      <div className="deal-top">
                        <p className="mb-10 text-brand-2 font-heading h6">
                          Deal of the Day
                        </p>
                      </div>
                      <div className="deal-content detail-info">
                        <h4 className="product-title">
                          <Link
                            to="products/oroweat-country-buttermilk-bread.html"
                            className="text-heading"
                          >
                            Oroweat Country Buttermilk Bread
                          </Link>
                        </h4>
                        <div className="clearfix product-price-cover">
                          <div className="product-price primary-color float-left">
                            <span className="current-price text-brand">
                              856,191₫
                            </span>
                            <span>
                              <span className="save-price font-md color3 ml-15">
                                -70%% Off
                              </span>
                              <span className="old-price font-md ml-15">
                                2,853,969₫
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="deal-bottom">
                        <p className="mb-20">Hurry Up! Offer End In:</p>
                        <div
                          className="deals-countdown pl-5"
                          data-countdown="2023-12-22 00:00:00"
                        ></div>
                        <div className="product-detail-rating">
                          <div className="product-rate-cover text-end">
                            <div className="d-inline-block">
                              <div className="product-rating page_speed_865980334"></div>
                            </div>
                            <span className="font-small ml-5 text-muted">
                              (10)
                            </span>
                          </div>
                        </div>
                        <Link
                          to="products/oroweat-country-buttermilk-bread.html"
                          className="btn hover-up"
                        >
                          Shop Now <i className="fi-rs-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <section className="section-padding mb-30">
              <div className="container">
                <div className="row">
                  <div className="col-xl-3 col-lg-6 col-md-6 mb-md-0">
                    <h4 className="section-title style-1 mb-30 animated animated">
                      Top Selling
                    </h4>
                    <div className="product-list-small animated animated">
                      {!loading && (
                        data?.getTopSelling?.map((item, i) => (
                          <article key={i} className="row align-items-center hover-up">
                            <figure className="col-md-4 mb-0">
                              <Link to={`/product/${item.product_slug}`} onClick={() => handleProductRoute(item.product_slug)}>
                                <img
                                  src={producturl + item.thumbnail}
                                  loading="lazy"
                                  alt={item.product_name}
                                />
                              </Link>
                            </figure>
                            <div className="col-md-8 mb-0">
                              <p className="text-truncate font-heading h6">
                                <Link to={`/product/${item.product_slug}`} onClick={() => handleProductRoute(item.product_slug)} title={item.product_name}>
                                  {item.product_name}
                                </Link>
                              </p>
                              <div className="product-rate-cover">
                                <div className="d-inline-block">
                                  <Rating name="read-only" value={item?.average_stars} readOnly size="small" />
                                </div>
                                <span className="font-small ml-5 text-muted" style={{ verticalAlign: 'text-bottom' }}>
                                  ( {item?.comments?.length ?? "0"} reviews)
                                </span>
                              </div>
                              <div className="product-price">
                                <span>₹ {item.mrp}</span>
                              </div>
                            </div>
                          </article>
                        ))
                      )}
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-6 mb-md-0">
                    <h4 className="section-title style-1 mb-30 animated animated">
                      Trending Products
                    </h4>
                    <div className="product-list-small animated animated">
                      {!loading && (
                        data?.getTrending?.map((item, i) => (
                          <article key={i} className="row align-items-center hover-up">
                            <figure className="col-md-4 mb-0">
                              <Link to={`/product/${item.product_slug}`} onClick={() => handleProductRoute(item.product_slug)}>
                                <img
                                  src={producturl + item.thumbnail}
                                  loading="lazy"
                                  alt={item.product_name}
                                />
                              </Link>
                            </figure>
                            <div className="col-md-8 mb-0">
                              <p className="text-truncate font-heading h6">
                                <Link to={`/product/${item.product_slug}`} onClick={() => handleProductRoute(item.product_slug)} title={item.product_name}>
                                  {item.product_name}
                                </Link>
                              </p>
                              <div className="product-rate-cover">
                                <div className="d-inline-block">
                                  <Rating name="read-only" value={item?.average_stars} readOnly size="small" />
                                </div>
                                <span className="font-small ml-5 text-muted" style={{ verticalAlign: 'text-bottom' }}>
                                  ( {item?.comments?.length ?? "0"} reviews)
                                </span>
                              </div>
                              <div className="product-price">
                                <span>₹ {item.mrp}</span>
                              </div>
                            </div>
                          </article>
                        ))
                      )}
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-6 mb-md-0">
                    <h4 className="section-title style-1 mb-30 animated animated">
                      Recently Added
                    </h4>
                    <div className="product-list-small animated animated">
                      {!loading && (
                        data?.getRecentlyAdded?.map((item, i) => (
                          <article key={i} className="row align-items-center hover-up">
                            <figure className="col-md-4 mb-0">
                              <Link to={`/product/${item.product_slug}`} onClick={() => handleProductRoute(item.product_slug)}>
                                <img
                                  src={producturl + item.thumbnail}
                                  loading="lazy"
                                  alt={item.product_name}
                                />
                              </Link>
                            </figure>
                            <div className="col-md-8 mb-0">
                              <p className="text-truncate font-heading h6">
                                <Link to={`/product/${item.product_slug}`} onClick={() => handleProductRoute(item.product_slug)} title={item.product_name}>
                                  {item.product_name}
                                </Link>
                              </p>
                              <div className="product-rate-cover">
                                <div className="d-inline-block">
                                  <Rating name="read-only" value={item?.average_stars} readOnly size="small" />
                                </div>
                                <span className="font-small ml-5 text-muted" style={{ verticalAlign: 'text-bottom' }}>
                                  ( {item?.comments?.length ?? "0"} reviews)
                                </span>
                              </div>
                              <div className="product-price">
                                <span>₹ {item.mrp}</span>
                              </div>
                            </div>
                          </article>
                        ))
                      )}
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-6 mb-md-0">
                    <h4 className="section-title style-1 mb-30 animated animated">
                      Top Rated
                    </h4>
                    <div className="product-list-small animated animated">
                      {!loading && (
                        data?.getTopRated?.map((item, i) => (
                          <article key={i} className="row align-items-center hover-up">
                            <figure className="col-md-4 mb-0">
                              <Link to={`/product/${item.product_slug}`} onClick={() => handleProductRoute(item.product_slug)}>
                                <img
                                  src={producturl + item.thumbnail}
                                  loading="lazy"
                                  alt={item.product_name}
                                />
                              </Link>
                            </figure>
                            <div className="col-md-8 mb-0">
                              <p className="text-truncate font-heading h6">
                                <Link to={`/product/${item.product_slug}`} onClick={() => handleProductRoute(item.product_slug)} title={item.product_name}>
                                  {item.product_name}
                                </Link>
                              </p>
                              <div className="product-rate-cover">
                                <div className="d-inline-block">
                                  <Rating name="read-only" value={item?.average_stars} readOnly size="small" />
                                </div>
                                <span className="font-small ml-5 text-muted" style={{ verticalAlign: 'text-bottom' }}>
                                  ( {item?.comments?.length ?? "0"} reviews)
                                </span>
                              </div>
                              <div className="product-price">
                                <span>₹ {item.mrp}</span>
                              </div>
                            </div>
                          </article>
                        ))
                      )}
                    </div>
                  </div>


                </div>

              </div> 

            </section>

                                           {/* ad */}

                                           <div className="container-fluid pt-24">
              <section className="newsletter1 mb-15 wow animate__animated animate__fadeIn d-none d-md-block d-lg-block" style={{ border: '1p solid green', borderRadius: '20px', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px' }}>
                <div className="">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="position-relative newsletter-inner1 page_speed_703129441">
                        <div className="newsletter-content1">
                          <h2 className="mb-20">
                            {" "}
                            Discover the Soul of Kerala:
                            <br></br>Experience Authentic Crafts,  <br /> Handlooms & Ayurveda. Immerse   <br /> yourself  in the rich <br /> heritage of Kerala{" "}
                          </h2>
                          <p className="mb-45">
                            Subscribe for our blogs...
                          </p>
                          <form
                            className="newsletter-form1 "
                            method="post"
                          >
                            <input
                              type="hidden"
                              name="_token"
                              value="bVwKUehCmCMBBOuQToUzdDhTTdTBfg78RlsalVJQ"
                              autocomplete="off"
                            />
                            <div className="form-subscribe1 d-flex">
                              <input
                                type="email"
                                name="email"
                                value={subEmail}
                                onChange={(e) =>
                                  setSubEmail(e.target.value)
                                }
                                placeholder="Your email address"
                              />
                              <button className="btn" type="button" onClick={handleSubscribe}>
                                Subscribe
                              </button>
                            </div>
                          </form>
                        </div>
                        {/* <img
                      src="/storage/general/newsletter-image.png"
                      alt="newsletter"
                    /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="newsletter2 mb-15 wow animate__animated animate__fadeIn d-block d-md-none d-lg-none" style={{ border: '1p solid green', borderRadius: '20px', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px' }}>
                <div className="">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="position-relative newsletter-inner2 page_speed_703129441">
                        <div className="newsletter-content1">
                          <h6 className="mb-20">
                            {" "}
                            Discover the Soul of Kerala:
                            <br></br>Experience Authentic Crafts,  <br /> Handlooms & Ayurveda. Immerse   <br /> yourself  in the rich <br /> heritage of Kerala{" "}
                          </h6>
                          <p className="mb-45">
                            Subscribe for our blogs...
                          </p>
                          <form
                            className="newsletter-form1 "
                            method="post"
                          >
                            <input
                              type="hidden"
                              name="_token"
                              value="bVwKUehCmCMBBOuQToUzdDhTTdTBfg78RlsalVJQ"
                              autocomplete="off"
                            />
                            <div className="form-subscribe1 d-flex">
                              <input
                                type="email"
                                name="email"
                                value={subEmail}
                                onChange={(e) =>
                                  setSubEmail(e.target.value)
                                }
                                placeholder="Your email address"
                              />
                              <button className="btn" type="button" onClick={handleSubscribe}>
                                Subscribe
                              </button>
                            </div>
                          </form>
                        </div>
                        {/* <img
                      src="/storage/general/newsletter-image.png"
                      alt="newsletter"
                    /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <ScrollToTop />

          </div>
        </div>
      </main>
      <div
        className="modal fade custom-modal"
        id="quick-view-modal"
        tabindex="-1"
        aria-labelledby="quick-view-modal-label"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div class="modal-body">
              <div className="half-circle-spinner loading-spinner">
                <div className="circle circle-1"></div>
                <div className="circle circle-2"></div>
              </div>
              <div className="quick-view-content"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Homepage;
