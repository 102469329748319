import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { backend, mediaurl } from "../../../path";
import DashboardMain from "../main";
import DashboardSidebarMain from "../dashsidebar";
import HeaderDashMain from "../../layout/header/dash header";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Pagination from "../../components/pagination";
function AdminUser() {
  // const [formData, setFormData] = useState([]);
  const [formData, setFormData] = useState({
    category_name: '',
    description: '', // Assuming description is a string
    logo: '', // Assuming logo is a string
  });
  const [data, setData] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [view, setView] = useState(0);
  const [logo, setLogo] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [dummyData, setDummyData] = useState({});
  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );
  const fetchData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getalladmin?page_number=" + pageNumber + "&page_size=" + pageSize, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [pageNumber,pageSize]);


  const handleStatusOff = (id) => {
    // formData.status = 1;

    dummyData.user_id = id;
    dummyData.status = 1;

    fetch(backend + "updateadminstatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(dummyData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          fetchData()
          // setView(1);
          // Additional success handling code here
          toast.success("Updated", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleStatus = (id) => {

    dummyData.user_id = id;
    dummyData.status = 0;

    fetch(backend + "updateadminstatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(dummyData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          fetchData()
          // setView(1);
          // Additional success handling code here
          toast.success("Updated", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleView = (id) => {
    const edit = data.find((item) => item.category_id === id);
    setView(1);
    setFormData(edit);
  };

  const handlefileuploadEdit = (e) => {
    let formdata = new FormData();
    formdata.append("document", e.target.files[0]);

    fetch(backend + "fileupload", {
      method: "POST",
      body: formdata,
    })
      .then((response) => response.json())
      .then((data) => {
        setFormData({
          ...formData,
          logo: data,
        });
        setLogo = data;
      })
      .catch((error) => {
        console.error("Error during file upload:", error);
      });
  };

  const handleSubmit = (id) => {
    formData.status = 0;
    formData.role = 1;
    formData.type = 0;

    fetch(backend + "registration", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {

          // Additional success handling code here
          toast.success("Admin User Created", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
        setView(0);
      })
      .catch((error) => {
        toast.error("Something Went Wrong", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handlePageClick = (pageNumber) => {
    setPageNumber(pageNumber);
  };
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-2 d-none d-lg-flex">
            <DashboardSidebarMain />
          </div>
          {view === 0 ?(
        <div className="col-lg-10">
            <div className="login_wrap widget-taber-content background-white">
              <div className="padding_eight_all bg-white">
                <div className="padding_eight_all bg-white">
                  <div className="d-flex justify-content-between mb-20 mt-20">
                    <h3 className="">Admin User</h3>
                    <div className="d-flex justify-content-end gap-3">
                      <a href="/admin-users-add">
                        <button
                          type="button"
                          className="btn btn-fill-out btn-block hover-up"
                          //   onClick={handleSubmit}
                        >
                          Add New
                        </button>
                      </a>
                      {/* <div>
                        <Icon
                          icon="fa6-solid:file-csv"
                          color="green"
                          className="hover-up"
                          fontSize={46}
                          onClick={() =>
                            document.getElementById("fileInput").click()
                          }
                          style={{ cursor: "pointer" }}
                        />

                        <input
                          type="file"
                          id="fileInput"
                          accept=".csv"
                          onChange={handlefileupload}
                          style={{ display: "none" }}
                        />
                      </div> */}
                    </div>
                  </div>
                  <br></br>
                </div>

                <div className="table-responsive">
                  <table className="table table-secondary table-hover">
                    <thead className="table-dark">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Admin Name</th>
                        <th scope="col">Role</th>
                        <th scope="col">Email</th>                        
                        <th scope="col">Created At</th>
                        <th scope="col">Status</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{item.admin_name}</td>
                          <td>{item.role}</td>
                          <td>{item.email}</td>
                          <td>{item.created_at}</td>
                          <td>
                            {item.status === 0 ? "Available" : "Not Available"}
                          </td>
                          <td>
                              {item.status === 0 ? (
                                <Icon
                                  icon="carbon:view-filled"
                                  color="green"
                                  className="cursor-pointer ml-2"
                                  fontSize={20}
                                  onClick={() => handleStatusOff(item.user_id)}
                                />
                              ) : item.status === 1 ? (
                                <Icon
                                  icon="carbon:view-off-filled"
                                  color="#b83e0c"
                                  className="cursor-pointer ml-2"
                                  fontSize={20}
                                  onClick={() => handleStatus(item.user_id)}
                                />
                              ) : (
                                ""
                              )}{" "}
                            </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* <div className="form-group">
                <button
                  type="button"
                  className="btn btn-fill-out btn-block hover-up"
                  onClick={handleSubmit}
                >
                  Register
                </button>
              </div> */}
              <br></br>
              <Pagination
        currentPage={pageNumber}
        pageSize={pageSize}
        totalItems={data.length}
        onPageChange={handlePageClick}
      />
            </div>
          </div>
          ):(
            <div className="col-lg-10">
            <div className="login_wrap widget-taber-content background-white">
              <div className="padding_eight_all bg-white">
                <div className="padding_eight_all bg-white">
                  <div className="d-flex justify-content-between mb-20 mt-20">
                    <h3 className="">Edit Admin User</h3>
                    {/* <a href=""> */}
                      <button
                        type="button"
                        className="btn btn-fill-out btn-block hover-up"
                        onClick={()=>setView(0)}
                      >
                        Back
                      </button>
                    {/* </a> */}
                  </div>
                  <br></br>
                </div>
                {/* <form
                // onSubmit={handleSubmit}
                > */}
                  <div className="form__content">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Admin Name</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="admin_name"
                            id="txt-name"
                            type="text"
                            required
                            value={formData.admin_name || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                admin_name: e.target.value,
                              })
                            }
                            placeholder="Admin Name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">

                        <div className="form-group">
                          <label htmlFor="name">Email</label>
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="email"
                            id="txt-name"
                            type="text"
                            required
                            value={formData.email || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                email: e.target.value,
                              })
                            }
                            placeholder="Email"
                          />
                        </div>
                        </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-fill-out btn-block hover-up"
                      onClick={()=>handleSubmit()}
                    >
                      Submit
                    </button>
                  </div>
                  <br></br>
                {/* </form> */}
              </div>
            </div>
          </div>
          )}
          
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
export default AdminUser;
