import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { backend, mediaurl, vendorurl } from "../../../path";
import DashboardMain from "../main";
import DashboardSidebarMain from "../dashsidebar";
import HeaderDashMain from "../../layout/header/dash header";
import LoaderMain from "../../layout/header/loader_sub";
function DashboardVendorMain() {
  const [formData, setFormData] = useState([]);
  const [dummyData, setDummyData] = useState({});
  const [galary, setGalary] = useState("");
  const [count, setCountry] = useState([]);

  const [data, setData] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [view, setView] = useState(0);
  const [subdata, setSubData] = useState([]);

  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );
  const type = authenticated.type;

  const handlefileupload = (e, type) => {
    let formdata = new FormData();

    formdata.append("document", e.target.files[0]);

    fetch(backend + "fileuploadvendor", {
      method: "POST",
      body: formdata,
    }).then((response) => {
      response.json().then((data) => {
        if (type == 0) {
          setFormData({
            ...formData,
            registration_certificate: data,
          });
        } else if (type == 1) {
          setFormData({
            ...formData,
            gst_certificate: data,
          });
        } else if (type == 2) {
          setFormData({
            ...formData,
            logo: data,
          });
        } else if (type == 3) {
          setFormData({
            ...formData,
            export_license: data,
          });
        } else if (type == 4) {
          setFormData({
            ...formData,
            pan: data,
          });
        } else if (type == 5) {
          setFormData({
            ...formData,
            aadhaar: data,
          });
        }
      });
    });
  };

  const handlemultiplefileupload = (event, state) => {
    let formdata = new FormData();

    var $files = event.target.files;

    // console.log($files)

    Array.from(event.target.files).forEach((element, index) => {
      formdata.append(`documents_${index}`, element);
    });
    formdata.append("length", $files.length);

    // formdata.append('documents',event.target.files)

    fetch(backend + "multiplefileupload", {
      method: "POST",
      body: formdata,
    }).then((response) => {
      response.json().then((data) => {
        setGalary(JSON.stringify(data));
      });
    });
  };

  // const [formData, setformData] = useState(null);
  const fetchCatData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getcategory", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        console.log(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchCountryData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getcountry", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setCountry(data);
        console.log(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchSubData = () => {
    setLoading(true);

    fetch(backend + "getsubcategory", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setSubData(data);
        console.log(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleView = (id) => {
    const edit = data.find((item) => item.id === id);
    setView(1);
    setFormData(edit);
  };

  const handleBack = () => {
    setView(0);
    fetchData();
  };
  const handleEdit = () => {
    setView(2);
  };
  const handleEditBack = () => {
    setView(1);
  };

  const fetchData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getvendor", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        console.log(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchCatData();
    fetchSubData();
    fetchCountryData();
    fetchData();
  }, []);

  const handleIconClickLogo = () => {
    const imageUrl = vendorurl + formData.logo;
    window.open(imageUrl, "_blank");
  };
  const handleIconClickGST = () => {
    const imageUrl = mediaurl + formData.gst_certificate;
    window.open(imageUrl, "_blank");
  };
  const handleIconClickExport = () => {
    const imageUrl = mediaurl + formData.export_license;
    window.open(imageUrl, "_blank");
  };
  const handleIconClickOther = () => {
    const imageUrl = mediaurl + formData.other_license;
    window.open(imageUrl, "_blank");
  };
  const handleIconClickAadhar = () => {
    const imageUrl = mediaurl + formData.aadhaar;
    window.open(imageUrl, "_blank");
  };
  const handleIconClickPan = () => {
    const imageUrl = mediaurl + formData.pan;
    window.open(imageUrl, "_blank");
  };

  const handleSubmit = (id) => {

    fetch(backend + "editVendor", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            console.error("Something Went Wrongs");
            toast.error("Something Went Wrongs", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Registration failed");
            toast.error("Registration failed", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          console.log("success");
          setView(1);
          fetchData();
          // Additional success handling code here
          toast.success("Successfully Updated", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleStatus = (id) => {
    // formData.status = 1;

    dummyData.user_id = id;
    dummyData.id = id;
    dummyData.status = 2;
    dummyData.type = type;

    fetch(backend + "updatevendorstatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dummyData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            console.error("Email Already Exists");
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Something Went Wrong");
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          console.log("success");
          fetchData();
          setView(0);
          // Additional success handling code here
          toast.success("Updated", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleStatusOff = (id) => {
    // formData.status = 1;

    dummyData.user_id = id;
    dummyData.id = id;
    dummyData.status = 1;
    dummyData.type = type;

    fetch(backend + "updatevendorstatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dummyData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
        }

        response.json().then(() => {
          console.log("success");
          setView(0);
          fetchData();
          // Additional success handling code here
          toast.success("Updated", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Something Went Wrong", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handlefileuploadmany = async (e) => {
    try {
      console.log("File selected:", e.target.files[0]);
      let formdata = new FormData();
      formdata.append("csvFile", e.target.files[0]);

      const response = await fetch(backend + "csvhandlevendor", {
        method: "POST",
        body: formdata,
        "Content-Type": "multipart/form-data",
      });

      if (!response.ok) {
        throw new Error(`File upload failed with status ${response.status}`);
      }

      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Error during file upload:", error);
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-2 d-none d-lg-flex">
            <DashboardSidebarMain />
          </div>
          {view === 0 && (
            <div className="col-lg-10">
              <div className="login_wrap widget-taber-content background-white">
                <div className="padding_eight_all bg-white">
                  <div className="padding_eight_all bg-white">
                    <div className="d-flex justify-content-between mb-20 mt-20">
                      <h3 className="">Vendor</h3>
                      <div className="d-flex justify-content-end gap-3">
                        <a href="/dash-vendor-add">
                          <button
                            type="button"
                            className="btn btn-fill-out btn-block hover-up"
                            //   onClick={handleSubmit}
                          >
                            Add New
                          </button>
                        </a>
                        <div>
                          {/* Icon component */}
                          <Icon
                            icon="fa6-solid:file-csv"
                            color="green"
                            className="hover-up"
                            fontSize={46}
                            onClick={() =>
                              document.getElementById("fileInput").click()
                            }
                            style={{ cursor: "pointer" }}
                          />

                          {/* File input */}
                          <input
                            type="file"
                            id="fileInput"
                            accept=".csv"
                            onChange={handlefileuploadmany}
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>
                    </div>
                    <br></br>
                  </div>

                  <div className="table-responsive">
                    <table className="table table-secondary table-hover">
                      <thead className="table-dark">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Vender Code</th>
                          <th scope="col">Company Name</th>
                          <th scope="col">Vendor Name</th>
                          <th scope="col">Email Id</th>
                          <th scope="col">Phone No</th>
                          <th scope="col">Status</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ?
                        <LoaderMain/>
                        :
                        ( data.map((item, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{item.vendor_code}</td>
                            <td>{item.company_name}</td>
                            <td>{item.vendor_name}</td>
                            <td>{item.email}</td>
                            <td>{item.contact}</td>
                            <td>
                              {item.vendor_status === 0
                                ? "pending"
                                : item.vendor_status === 1
                                ? "Avaliable"
                                : "Not Available"}
                            </td>
                            <td>
                              {item.vendor_status === 1 ? (
                                <Icon
                                  icon="carbon:view-filled"
                                  color="green"
                                  className="cursor-pointer ml-2"
                                  fontSize={20}
                                  onClick={() => handleStatus(item.user_id)}
                                />
                              ) : item.vendor_status === 2 ? (
                                <Icon
                                  icon="carbon:view-off-filled"
                                  color="#b83e0c"
                                  className="cursor-pointer ml-2"
                                  fontSize={20}
                                  onClick={() => handleStatusOff(item.user_id)}
                                />
                              ) : (
                                ""
                              )}{" "}
                              <Icon
                                icon="icon-park-solid:more-two"
                                color="#1a42cc"
                                className="cursor-pointer ml-2"
                                fontSize={20}
                                onClick={() => handleView(item.id)}
                              />
                            </td>
                          </tr>
                        )))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <br></br>
              </div>
            </div>
          )}
          {view === 1 && (
            <div className="col-lg-10">
              <div className="login_wrap widget-taber-content background-white">
                <div className="padding_eight_all bg-white">
                  <div className="padding_eight_all bg-white">
                    <div className="d-flex justify-content-between mb-20 mt-20">
                      <h3 className="">Vendor - {formData.vendor_name} </h3>
                      <div>
                        <button
                          type="button"
                          className="btn btn-fill-out btn-block hover-up me-2 btn-secondary"
                          onClick={handleBack}
                        >
                          Back
                        </button>
                        {formData.vendor_status === 0 ? (
                          <>
                            <button
                              type="button"
                              className="btn btn-fill-out btn-block hover-up me-2"
                              onClick={() => handleStatusOff(formData.user_id)}
                            >
                              Accept
                            </button>
                            <button
                              type="button"
                              className="btn btn-fill-out btn-block hover-up btn-danger me-2"
                            >
                              Reject
                            </button>
                          </>
                        ) : formData.vendor_status === 1 ? (
                          <>
                            <button
                              type="button"
                              className="btn btn-fill-out btn-block hover-up me-2"
                              onClick={handleEdit}
                            >
                              Edit
                            </button>
                            <button
                              type="button"
                              className="btn btn-fill-out btn-block hover-up btn-warning"
                              //   onClick={handleSubmit}
                            >
                              Delete
                            </button>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <br></br>
                    <div className="row">
                      <div className="col-lg-3 border border-right border-light p-4 rounded-3 ">
                        <div
                          className="mx-auto rounded-circle p-5 mb-3 fw-bolder"
                          style={{
                            backgroundColor: "black",
                            borderRadius: "50%",
                            border: "2px solid red",
                            width: "150px",
                            height: "150px",
                            fontSize: "55px",
                            color: "#fff",
                          }}
                        >
                          {formData.vendor_name.charAt(0)}
                        </div>
                        <div className="d-flex justify-content-between detail-page">
                          <label className="">Company Name</label>
                          <label>{formData.company_name}</label>
                        </div>
                        <div className="d-flex justify-content-between detail-page">
                          <label className="">Vender Code</label>
                          <label>{formData.vendor_code}</label>
                        </div>
                        <div className="d-flex justify-content-between detail-page">
                          <label className="">Email Id</label>

                          <label>{formData.email}</label>
                        </div>
                        <div className="d-flex justify-content-between detail-page">
                          <label className="">Phone No</label>
                          <label>{formData.contact}</label>
                        </div>
                        <br></br>
                        <br></br>
                        <br></br>
                        <div className="d-flex justify-content-between detail-page">
                          <label className="">Created At</label>
                          <label>{formData.created_at}</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Company Address
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                name="company_address"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.company_address}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">Country</label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                name="company_address"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.country}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">City</label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.city}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">Pincode</label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.pincode}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Street Name
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.street_name}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Description
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.description}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">GST No</label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.gst_number}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">Category</label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.category_name}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Sub Category
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.sub_category_name}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Alternate Contact No
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.alternate_contact}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Alternate Email
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.alternate_email}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label htmlFor="company_address">Logo</label>
                                <br></br>
                                <button
                                  type="button"
                                  className="btn btn-fill-out btn-block hover-up me-2 btn-secondary"
                                  onClick={handleIconClickLogo}
                                >
                                  Logo
                                </button>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label htmlFor="company_address">
                                  GST Certificate
                                </label>
                                <br></br>
                                <button
                                  type="button"
                                  className="btn btn-fill-out btn-block hover-up me-2 btn-secondary"
                                  onClick={handleIconClickGST}
                                >
                                  View
                                </button>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label htmlFor="company_address">Aadhar</label>
                                <br></br>
                                <button
                                  type="button"
                                  className="btn btn-fill-out btn-block hover-up me-2 btn-secondary"
                                  onClick={handleIconClickAadhar}
                                >
                                  View
                                </button>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label htmlFor="company_address">Pan</label>
                                <br></br>
                                <button
                                  type="button"
                                  className="btn btn-fill-out btn-block hover-up me-2 btn-secondary"
                                  onClick={handleIconClickPan}
                                >
                                  View
                                </button>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label htmlFor="company_address">
                                  Export License
                                </label>
                                <br></br>
                                <button
                                  type="button"
                                  className="btn btn-fill-out btn-block hover-up me-2 btn-secondary"
                                  onClick={handleIconClickExport}
                                >
                                  View
                                </button>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label htmlFor="company_address">
                                  Other Licences
                                </label>
                                <br></br>
                                <button
                                  type="button"
                                  className="btn btn-fill-out btn-block hover-up me-2 btn-secondary"
                                  onClick={handleIconClickOther}
                                >
                                  View
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <br></br>
              </div>
            </div>
          )}
          {view === 2 && (
            <div className="col-lg-10">
              <div className="login_wrap widget-taber-content background-white">
                <div className="padding_eight_all bg-white">
                  <div className="padding_eight_all bg-white">
                    <div className="d-flex justify-content-between mb-20 mt-20">
                      <h3 className="">
                        Vendor - {formData.vendor_name} : EDIT PAGE
                      </h3>
                      <div>
                        <button
                          type="button"
                          className="btn btn-fill-out btn-block hover-up me-2 btn-secondary"
                          onClick={handleEditBack}
                        >
                          Back
                        </button>
                        {/* {formData.vendor_status === 0 ? (
                          <>
                            <button
                              type="button"
                              className="btn btn-fill-out btn-block hover-up me-2"
                              //   onClick={handleSubmit}
                            >
                              Accept
                            </button>
                            <button
                              type="button"
                              className="btn btn-fill-out btn-block hover-up btn-danger me-2"
                              //   onClick={handleSubmit}
                            >
                              Reject
                            </button>
                          </>
                        ) : formData.vendor_status === 1 ? (
                          <>
                            <a href="/dash-vendor-add">
                              <button
                                type="button"
                                className="btn btn-fill-out btn-block hover-up"
                                //   onClick={handleSubmit}
                              >
                                Edit
                              </button>
                            </a>
                            <button
                              type="button"
                              className="btn btn-fill-out btn-block hover-up btn-warning"
                              //   onClick={handleSubmit}
                            >
                              Delete
                            </button>
                          </>
                        ) : (
                          ""
                        )} */}
                      </div>
                    </div>

                    <br></br>
                    <div className="row">
                      <div className="col-lg-3 border border-right border-light p-4 rounded-3 ">
                        <div
                          className="mx-auto rounded-circle p-5 mb-3 fw-bolder"
                          style={{
                            backgroundColor: "black",
                            borderRadius: "50%",
                            border: "2px solid red",
                            width: "150px",
                            height: "150px",
                            fontSize: "55px",
                            color: "#fff",
                          }}
                        >
                          {formData.vendor_name.charAt(0)}
                        </div>
                        <div className="d-flex justify-content-between detail-page">
                          <label className="">Company Name</label>
                          <label>{formData.company_name}</label>
                        </div>
                        <div className="d-flex justify-content-between detail-page">
                          <label className="">Vender Code</label>
                          <label>{formData.vendor_code}</label>
                        </div>
                        <div className="d-flex justify-content-between detail-page">
                          <label className="">Email Id</label>

                          <label>{formData.email}</label>
                        </div>
                        <div className="d-flex justify-content-between detail-page">
                          <label className="">Phone No</label>
                          <label>{formData.contact}</label>
                        </div>
                        <br></br>
                        <br></br>
                        <br></br>
                        <div className="d-flex justify-content-between detail-page">
                          <label className="">Created At</label>
                          <label>{formData.created_at}</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Company Address
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                name="company_address"
                                id="txt-name"
                                type="text"
                                value={formData.company_address || ""}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    company_address: e.target.value,
                                  });
                                }}
                              ></input>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">Country</label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>

                              <select
                                className="form-control"
                                name="category_name"
                                id="txt-name"
                                required
                                value={formData.country || ""}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    country: e.target.value,
                                  })
                                }
                              >
                                <option>- select -</option>
                                {count.map((val) => {
                                  return (
                                    <option value={val.country_id}>
                                      {val.countryname}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">City</label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.city}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    city: e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">Pincode</label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.pincode}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    pincode: e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Street Name
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.street_name}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    street_name: e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Description
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.description}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    description: e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">GST No</label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.gst_number}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    gst_number: e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">Category</label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <select
                                className="form-control"
                                name="category_name"
                                id="txt-name"
                                required
                                value={formData.category_id || ""}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    category_id: e.target.value,
                                  })
                                }
                              >
                                <option>- select -</option>
                                {data.map((val) => {
                                  return (
                                    <option value={val.category_id}>
                                      {val.category_name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Sub Category
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <select
                                className="form-control"
                                name="sub_category_id"
                                value={formData.sub_category_id || ""}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    sub_category_id: e.target.value,
                                  })
                                }
                              >
                                <option>- select -</option>
                                {subdata.map((val) => {
                                  return (
                                    <option value={val.sub_category_id}>
                                      {val.sub_category_name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Alternate Contact No
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.alternate_contact}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    alternate_contact: e.target.value,
                                  })
                                }
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Alternate Email
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.alternate_email}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    alternate_email: e.target.value,
                                  })
                                }
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label htmlFor="logo">Logo</label>
                                <input
                                  className="form-control"
                                  type="file"
                                  name="logo"
                                  onChange={(event) => {
                                    handlefileupload(event, 2);
                                  }}
                                  placeholder="Upload Your Logo"
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label htmlFor="export_license">
                                  Export License
                                </label>
                                <input
                                  className="form-control"
                                  type="file"
                                  name="export_license"
                                  // value={formData.export_license || ""}
                                  onChange={(event) => {
                                    handlefileupload(event, 3);
                                  }}
                                  placeholder="Export License"
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label htmlFor="other_license">
                                  Other License
                                </label>
                                <input
                                  className="form-control"
                                  type="file"
                                  name="other_license"
                                  // value={formData.other_license || ""}
                                  onChange={(event) => {
                                    handlemultiplefileupload(event);
                                  }}
                                  placeholder="Other License"
                                />
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="form-group">
                                <label htmlFor="pan">PAN Card</label>
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  className="form-control"
                                  type="file"
                                  name="PAN"
                                  required
                                  // value={formData.pan || ""}
                                  onChange={(event) => {
                                    handlefileupload(event, 4);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label htmlFor="pan">Aadhar Card</label>
                                <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup>
                                <input
                                  className="form-control"
                                  type="file"
                                  name="PAN"
                                  required
                                  // value={formData.pan || ""}
                                  onChange={(event) => {
                                    handlefileupload(event, 5);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <button
                                type="button"
                                className="btn btn-fill-out btn-block hover-up"
                                onClick={() => handleSubmit(formData.user_id)}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <br></br>
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
export default DashboardVendorMain;
