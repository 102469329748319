import React, { useEffect } from "react";
import abouImage from './about.jpeg'
import ScrollToTop from "../../../components/whatsapp connect";
import "./About.css"; 

function AboutUs() {
  useEffect(() => {
    // Scroll to the top of the page whenever a new page is rendered
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="about">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <a href="../index.html" className="breadcrumb-item d-inline-block" title="Home"> Home </a>
            <span></span>
            <div className="breadcrumb-item d-inline-block active"> About Us </div>
          </div>
        </div>
      </div>
      <section className="newsletter wow animate__animated animate__fadeIn my-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="position-relative newsletter-inner page_speed_703129441">
                <div className="newsletter-content">
                  <h2 className="mb-20">Experience Kerala: Enchantment Delivered</h2>
                  <p className="mb-45" style={{ fontSize: "1.1rem" }}>
                    Kerala, fondly known as "God's Own Country," offers an unforgettable experience. From its serene beaches to its vibrant culture, Kerala leaves a lasting impression. But recreating that magic can be difficult.
                  </p>
                  <p className="mb-20" style={{ fontSize: "1.1rem" }}>
                    At Keralaspecial.in, we bridge the gap. We bring the essence of Kerala straight to your doorstep.
                  </p>
                  <h3>Discover the Finest Kerala has to Offer:</h3>
                  <br />
                  <ul className="mb-20" style={{ fontSize: "1.1rem" }}>
                    <li>Unearth Exquisite Products: Our passionate team, including artists, academics, and industry experts, handpicks the finest Kerala has to offer – from traditional handicrafts to authentic Ayurvedic products.</li>
                    <br/>
                    <li>Support Local Artisans: Every purchase empowers Kerala's talented artisans and weavers, preserving their heritage.</li>
                    <br/>
                    <li>Experience Unmatched Quality: We maintain rigorous quality standards, ensuring you receive only the best Kerala has to offer.</li>
                  </ul>
                </div>
              </div>
            </div>
           
            <div className="col-lg-6"> 
              <div className="position-relative newsletter-inner page_speed_703129441">
                <div className="newsletter-content">
                  <h2 className="mb-20">THE GRAND LAUNCH EVENT</h2>
                  <p className="mb-10" style={{ fontSize: "1.1rem" }}>
                    Keralaspecial.in was inaugurated in grand style by the esteemed Shri Kanayi Kunhiraman, renowned sculptor and ardent admirer of Kerala's cultural heritage. During the inauguration ceremony, Shri Kanayi expressed his heartfelt aspiration for Keralaspecial.in to evolve into a comprehensive online hub showcasing the rich tapestry of Kerala's arts and crafts.
                  </p>
                  <p className="mb-10" style={{ fontSize: "1.1rem" }}>
                  <img src={abouImage} style={{ marginLeft:'30%', maxWidth: "69%",position: "relative", right:'60px'}}  alt="Grand Launch Event" className="img-fluid mb-4" />
                  <b>Witnessed by Kerala's Stalwarts:</b> Kerala's leading figures graced the launch of Keralaspecial.in. The esteemed sculptor and cultural advocate, <strong>Shri Kanayi Kunhiraman</strong>, led the dignitaries. Joining him were  <strong>Dr. P. K. Madhavan Nair</strong>, former Kerala Public Service Commission member, and <strong>Shri P. K. Venugopal</strong>, former Trivandrum Development Authority Chairman. Our visionary directors, <strong>Dr. Vijayakumaran Nair</strong>, <strong>Dr. Mohan Das</strong>, and <strong>Mr. Shiju Lukose</strong>, were also present. Their leadership is key to Keralaspecial.in's journey.</p>
                  
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ScrollToTop/>
    </div>
  );
}

export default AboutUs;
