import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { backend, mediaurl } from "../../../path";
import DashboardMain from "../main";
import DashboardSidebarMain from "../dashsidebar";
import HeaderDashMain from "../../layout/header/dash header";
import Pagination from "../../components/pagination";

function DashboardTopSellingMain() {
  const [formData, setFormData] = useState([]);
  const [data, setData] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [dummyData, setDummyData] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );
  const fetchData = () => {
    
    setLoading(true);

    fetch(backend + "gettopselling", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [pageNumber,pageSize]);

  const handlefileupload = async (e) => {
    try {
      let formdata = new FormData();
      formdata.append("csvFile", e.target.files[0]);

      const response = await fetch(backend + "csvhandleorders", {
        method: "POST",
        body: formdata,
        "Content-Type": "multipart/form-data",
      });

      if (!response.ok) {
        throw new Error(`File upload failed with status ${response.status}`);
      }

      const data = await response.json();
    } catch (error) {
      console.error("Error during file upload:", error);
    }
  };

  const handleStatus = (id) => {
   

    dummyData.top_selling_id = id;
    dummyData.type = 'Top Selling';

    fetch(backend + "deletespecialproducts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(dummyData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          
          if (response.status === 409) {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          fetchData();
          
          toast.success("Product Deleted", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handlePageClick = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-2 d-none d-lg-flex">
            <DashboardSidebarMain />
          </div>
          <div className="col-lg-10">
            <div className="login_wrap widget-taber-content background-white">
              <div className="padding_eight_all bg-white">
                <div className="d-flex justify-content-between mb-20 mt-20">
                  <h3 className="">Top Selling Products</h3>
                  <div className="d-flex justify-content-end gap-3">


                  </div>
                </div>
                <br></br>
              </div>

              <div className="table-responsive">
                <table className="table table-secondary table-hover">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Product Name</th>
                      <th scope="col">Category Name</th>
                      <th scope="col">Sub-Category Name</th>
                      <th scope="col">Product Code</th>
                      <th scope="col">Status</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{item.product_name}</td>
                        <td>{item.category_name}</td>
                        <td>{item.sub_category_name}</td>
                        <td>{item.product_code}</td>
                        <td>
                          {item.status === 0
                            ? "out of stock"
                            : item.status === 1
                            ? "In Stock"
                            : ""}
                        </td>
                        <td>
                            <Icon
                              icon="typcn:delete"
                              color="#b83e0c"
                              className="cursor-pointer ml-2"
                              fontSize={40}
                              onClick={() => handleStatus(item.top_selling_id)}
                            />
                        </td></tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default DashboardTopSellingMain;