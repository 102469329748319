import React, { useEffect, useState } from "react";
import { backend, blogurl, producturl } from "../../../../path";
import LoaderMain from "../../../layout/header/loader_sub";

function ErrorPage() {



  return (
    <div className="contact">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <a href="/" className="breadcrumb-item d-inline-block" title="Home"> Home </a>
            <span></span>
            <div className="breadcrumb-item d-inline-block active"> 404 Not Found </div>
          </div>
        </div>
      </div>

      <section class="py-3 py-md-5 min-vh-100 d-flex justify-content-center align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="text-center">
                <h2 class="d-flex justify-content-center align-items-center gap-2 mb-4">
                  <span class="display-1 fw-bold">4</span>
                  <i class="bi bi-exclamation-circle-fill text-danger display-4">
                  <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><path fill="red" d="M16.707 2.293A.996.996 0 0 0 16 2H8a.996.996 0 0 0-.707.293l-5 5A.996.996 0 0 0 2 8v8c0 .266.105.52.293.707l5 5A.996.996 0 0 0 8 22h8c.266 0 .52-.105.707-.293l5-5A.996.996 0 0 0 22 16V8a.996.996 0 0 0-.293-.707zM13 17h-2v-2h2zm0-4h-2V7h2z"></path></svg>
                  </i>
                  <span class="display-1 fw-bold bsb-flip-h">4</span>
                </h2>
                <h3 class="h2 mb-2">Oops! You're lost.</h3>
                <p class="mb-5">The page you are looking for was not found.</p>
                <a class="btn bsb-btn-5xl btn-dark rounded-pill px-5 fs-6 m-0 my-3" href="/" role="button">Continue Shopping</a>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
}

export default ErrorPage;
