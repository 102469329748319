import { Carousel } from "react-bootstrap";
import ExampleCarouselImage from "./carouselimages";
import { useEffect, useState } from "react";
import { backend } from "../../../../../path";

const slides = [
  {
    imageUrl: "storage/general/CELEBRATE (1).png",
    // text: "Wide Range of Ayurvedic Medicines",
    // description: "From kerala, the cradle of Ayurveda",
  },
 
  {
    imageUrl: "storage/general/CELEBRATE (1).png",
    // text: "The Perfection of Handicrafts",
    // description: "From kerala, the cradle of Ayurveda",
  },
  {
    imageUrl: "storage/general/CELEBRATE (1).png",
    // text: "Wide Range of Ayurvedic Medicines",
    // description: "From kerala, the cradle of Ayurveda",
  },
  {
    imageUrl: "storage/general/CELEBRATE (1).png",
    // text: "Wide Range of Ayurvedic Medicines",
    // description: "From kerala, the cradle of Ayurveda",
  }
    
];

function UncontrolledExample() {  

  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {

    fetch(backend + "getcarousel", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
      })
      .finally(() => {
      });
  };

  return (
    <Carousel>
      {data.map((slide, index) => (
        <Carousel.Item key={index}>
          <ExampleCarouselImage imageUrl={slide?.carousel} text={slide?.text} />
          <Carousel.Caption>
            <h3 className="text-white">{slide.text}</h3>
            <p className="text-white">{slide.description}</p>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default UncontrolledExample;
