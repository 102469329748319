import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { backend } from "../../../../path";
import DashboardSidebarMain from "../../dashsidebar";
import { useNavigate } from "react-router-dom";
import HeaderDashMain from "../../../layout/header/dash header";
import Editor from "../../layouts/editor";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


function DashboardCategoryList() {
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const [logo, setLogo] = useState();

  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );
  const type = authenticated.type;
  const user_id = authenticated.user_id;

  const handlefileupload = (e) => {
    console.log("File selected:", e.target.files[0]);
    let formdata = new FormData();
    formdata.append("document", e.target.files[0]);

    fetch(backend + "fileuploadcategory", {
      method: "POST",
      body: formdata,
    })
      .then((response) => response.json())
      .then((data) => {
        setFormData({
          ...formData,
          logo: data,
        });
        setLogo = data;
      })
      .catch((error) => {
        console.error("Error during file upload:", error);
      });
  };

  const handleSubmit = () => {
    formData.status = 0;

    fetch(backend + "addcategory", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          console.log("success");

          // Additional success handling code here
          toast.success("Category Added", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
        navigate("/dash-category");
      })
      .catch((error) => {
        toast.error("Something Went Wrong", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleEditorSave = (editorContent) => {
    setFormData({
      ...formData,
      description: editorContent.content,
    });
  };
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-2 d-none d-lg-flex">
            <DashboardSidebarMain />
          </div>
          <div className="col-lg-10">
            <div className="login_wrap widget-taber-content background-white">
              <div className="padding_eight_all bg-white">
                <div className="padding_eight_all bg-white">
                  <div className="d-flex justify-content-between mb-20 mt-20">
                    <h3 className="">Add Category</h3>
                    <a href="/dash-category">
                      <button
                        type="button"
                        className="btn btn-fill-out btn-block hover-up"
                      //   onClick={handleSubmit}
                      >
                        Back
                      </button>
                    </a>
                  </div>
                  <br></br>
                </div>
                <form
                // onSubmit={handleSubmit}
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="bVwKUehCmCMBBOuQToUzdDhTTdTBfg78RlsalVJQ"
                    autocomplete="off"
                  />
                  <div className="form__content">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Category Name</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="category_name"
                            id="txt-name"
                            type="text"
                            required
                            value={formData.category_name || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                category_name: e.target.value,
                              })
                            }
                            placeholder="Category Name"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="logo">Logo</label>
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="logo"
                            id="txt-name"
                            type="file"
                            required
                            onChange={(event) => {
                              handlefileupload(event);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label htmlFor="name">Description </label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          {/* <input
                            className="form-control"
                            name="category_name"
                            id="txt-name"
                            type="text"
                            required
                            value={formData.description || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                description: e.target.value,
                              })
                            }
                            placeholder="Description"
                          />         */}
                          {/* <Editor required onSave={handleEditorSave} /> */}

                          <CKEditor
                            editor={ClassicEditor}
                            data=""
                            onInit={editor => {
                              // You can store the "editor" and use when it is needed.
                              // console.log('Editor is ready to use!', editor);
                            }}

                            onChange={(event, editor) => {
                              const data = editor.getData();
                              console.log({ event, editor, data });
                              setFormData({
                                ...formData,
                                description: data,
                              })
                            }}
                            onBlur={(event, editor) => {
                              console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor, data) => {
                              console.log('Focus.', editor);
                            }}
                          />

                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Seo Title</label>{" "}
                          <input
                            className="form-control"
                            name="seo"
                            id="txt-name"
                            type="text"
                            value={formData.seo || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                seo: e.target.value,
                              })
                            }
                            placeholder="Seo Title"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Seo Meta keywords</label>{" "}
                          <input
                            className="form-control"
                            name="meta_keyword"
                            id="txt-name"
                            type="text"
                            value={formData.meta_keyword || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                meta_keyword: e.target.value,
                              })
                            }
                            placeholder="Seo Meta keywords"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Seo Meta Description</label>{" "}
                          <input
                            className="form-control"
                            name="meta_description"
                            id="txt-name"
                            type="text"
                            value={formData.meta_description || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                meta_description: e.target.value,
                              })
                            }
                            placeholder="Seo Meta Description"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-fill-out btn-block hover-up"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                  <br></br>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
export default DashboardCategoryList;
