import React, { useEffect, useState } from 'react';
import ScrollToTop from '../../../components/whatsapp connect';
import { backend } from "../../../../path";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useNavigate } from 'react-router-dom';
function PasswordResetUser() {

  const [email, setEmail] = useState('');
  const [otp, setOTP] = useState('');
  const [password, setPassword] = useState('');
  const [newpassword, setNewPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [otpError, setOTPError] = useState('');
  const [activeState, setActiveState] = useState(0);
  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );

  const navigate = useNavigate()

  const handleCurrentChange = (event) => {
    setPassword(event.target.value);
  };
  const handleNewChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleSubmit = () => {

    if (!password.trim()) {
      toast.error("Old Password Cannot be Null", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });      return;
    }
    if (!newpassword.trim()) {
      toast.error("New Password Required", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });      return;
    }
    fetch(backend + "updateuserpassword", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify({ password: password, newpassword:newpassword })
    })
      .then((response) => {
        response.json().then((data) => {
        });
        if (response.ok) {
          setActiveState(1)
        }
        if (!response.ok) {
          console.error(
            "Failed to Login:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 401) {
            toast.error("Incorrect Password, Try Again", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("Invalid Email", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }
      })
      .catch((error) => {
        console.error("Error during login:", error);
        toast.error("Invalid Email", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ToastContainer />
      <main className="main" id="main-section">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <div className="breadcrumb-item d-inline-block">
                <a href="/" title="Home">
                  Home
                </a>
              </div>
              <span></span>
              <div className="breadcrumb-item d-inline-block active">
                <div itemprop="item"> Reset Password </div>
              </div>
            </div>
          </div>
        </div>
        <section className="pt-100 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 m-auto">
                <div className="login_wrap widget-taber-content p-30 background-white border-radius-10">
                  <div className="padding_eight_all bg-white">
                    <div className="heading_s1 mb-20">
                      <h3 className="mb-30">Reset Password</h3>
                    </div>
                    <form className="form--auth form--login" >
                      <div className="form__content">
                        <div className="form-group">
                          <label htmlFor="txt-email" className="required">
                            Current Password
                          </label>
                          <input
                            className="form-control"
                            name="email"
                            id="txt-email"
                            type="text"
                            value={password}
                            onChange={handleCurrentChange}
                          />
                        </div>
                      </div>
                      <div className="form__content">
                        <div className="form-group">
                          <label htmlFor="txt-email" className="required">
                            New Password
                          </label>
                          <input
                            className="form-control"
                            name="email"
                            id="txt-email"
                            type="text"
                            value={newpassword}
                            onChange={handleNewChange}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <button type="button"
                        onClick={handleSubmit}
                        className="btn btn-fill-out btn-block hover-up">
                          Reset Password
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <ScrollToTop />
          </div>
        </section>
      </main>
    </>
  );
}

export default PasswordResetUser;
