import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { backend } from "../../../../path";
import './contact.css'

function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_no: "",
    message: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const handleSubmit = () => {
    const requiredFields = ["name", "email", "phone_no", "message"];
    const emptyFields = requiredFields.filter(field => !formData[field]);

    if (emptyFields.length > 0) {
      toast.error("Please fill in all required fields", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return; // Prevent further execution
    }

    fetch(backend + "contactus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(formData)
    })
      .then(response => {
        if (!response.ok) {
          console.error("Failed to submit form:", response.status, response.statusText);
          toast.error("Failed to submit form. Please try again later", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          return;
        }
        // Reset form after successful submission
        setFormData({
          name: "",
          email: "",
          phone_no: "",
          message: ""
        });
        toast.success("Form submitted successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      })
      .catch(error => {
        console.error("Error submitting form:", error);
        toast.error("Failed to submit form. Please try again later", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      });
  };

  
  return (
    <div className="contact">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <a href="/" className="breadcrumb-item d-inline-block" title="Home"> Home </a>
            <span></span>
            <div className="breadcrumb-item d-inline-block active"> Contact Us </div>
          </div>
        </div>
      </div>
      <section className="newsletter mb-15 wow animate__animated animate__fadeIn my-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="position-relative newsletter-inner page_speed_703129441">
                <div className="newsletter-content">
                  <h2>Get in Touch with Us</h2>
                  <br />
                  <p className="mb-20" style={{ fontSize: "1.1rem" }}>
                    For any inquiries or assistance, please feel free to reach out to us using the following contact details:
                  </p>
                  <br/>
                  <ul className="mb-20" style={{ fontSize: "1.1rem" }}>
                    <li><b>Address:</b> 38, Thycaud Elankom Nagar, Thycaud, Trivandrum 695014</li>
                    <li><b>Phone:</b> (91)-7356629493, 8891196910</li>
                    <li><b>Email:</b> <a href="mailto:info@keralaspecial.in">info@keralaspecial.in</a></li>
                  </ul>
                  <p className="mb-10" style={{ fontSize: "1.1rem" }}>We look forward to hearing from you!</p>
                  <p className="mb-45" style={{ fontSize: "1.1rem" }}>
                    At Keralaspecial.in, we are passionate about sharing the beauty and heritage of Kerala with the world.    <br/>
                    <br/>
                    We strive to provide an exceptional online experience, reflecting the warmth and hospitality of our home state.
                  </p>
               
                  <p className="mb-20" style={{ fontSize: "1.1rem" }}>
                    If you have any questions, comments, or suggestions, please don't hesitate to get in touch.
                    Our dedicated team is here to help!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="position-relative newsletter-inner page_speed_703129441">
                <div className="newsletter-content">
                <h2>Contact with Us</h2>
                  <br />
                  {/* Contact form */}
                  <form >
                    <div className="form-group">
                      <label htmlFor="name">Name</label>
                      <input type="text" style={{backgroundColor:"#ffff"}} className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input type="email" style={{backgroundColor:"#ffff"}} className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                      <label htmlFor="phone_no">Phone Number</label>
                      <input type="text"  style={{backgroundColor:"#ffff"}} className="form-control" id="phone_no" name="phone_no" value={formData.phone_no} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                      <label htmlFor="message">Message</label>
                      <textarea type='text' style={{backgroundColor:"#ffff"}} className="form-control" id="message" name="message" rows="3" value={formData.message} onChange={handleChange} required></textarea>
                    </div>
                    <button type="button" onClick={handleSubmit} className="btn btn-primary">Submit</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </section>
    </div>
  );
}

export default ContactUs;
