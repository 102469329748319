import React, { useEffect, useState } from "react";
import { backend, blogurl, categoryurl, producturl } from "../../../../path";
import LoaderMain from "../../../layout/header/loader_sub";
import { Link } from "react-router-dom";

function BlogUser(props) {

  const [data, setData] = useState([]);
  const [catData, setCatData] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState();

  const fetchData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getblog", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchCatData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getcategorywithsubcategory", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setCatData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    fetchCatData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    // <div className="contact">
    //   <div className="page-header breadcrumb-wrap">
    //     <div className="container">
    //       <div className="breadcrumb">
    //         <a href="/" className="breadcrumb-item d-inline-block" title="Home"> Home </a>
    //         <span></span>
    //         <div className="breadcrumb-item d-inline-block active"> Blog </div>
    //       </div>
    //     </div>
    //   </div>
    //   {loading &&
    //     <LoaderMain />
    //   }
    //   <section className="banners py-5">
    //     <div className="container">
    //       <div className="col-lg-12 col-md-12">
    //         <a className="btn btn-lg mb-2 w-100"
    //           style={{
    //             boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset', fontSize: '18px',
    //             backdropFilter: 'inherit',
    //             backgroundBlendMode: 'color-burn',
    //             backgroundColor: '#ADD899',
    //             fontWeight: 'bolder',
    //             color: '#fff',
    //           }}
    //         >
    //           KeralaSpecial Blogs
    //         </a>
    //       </div>
    //       <div className="row justify-content-center my-3">
    //         {data?.map((blog) => (
    //           <div key={blog.blog_id} className="col-lg-4 col-md-6">
    //             <div
    //               className="banner-img wow animate__animated animate__fadeInUp "
    //               data-wow-delay="0.2"
    //               style={{
    //                 boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
    //               }}
    //             >
    //               {/* <h4>
    //                       {blog?.title}
    //                     </h4> */}
    //               <a className="btn btn-lg p-3 w-100 py-3"

    //                 style={{
    //                   fontSize: '16px', backgroundColor: '#A67B5B',
    //                   boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
    //                 }}
    //               >
    //                 <div className="py-3"> {blog?.title}</div>

    //                 <a href={`/blog/${blog?.blog_id}`} target="_blank">

    //                   <picture>
    //                     <source
    //                       srcset={blogurl + blog?.thumbnail}
    //                       media="(min-width: 1200px)"
    //                     />
    //                     <source
    //                       srcset={blogurl + blog?.thumbnail}
    //                       media="(min-width: 768px)"
    //                     />
    //                     <source
    //                       srcset={blogurl + blog?.thumbnail}
    //                       media="(max-width: 767px)"
    //                     />
    //                     <img
    //                       src={blogurl + blog?.thumbnail}
    //                       alt=""
    //                       className="page_speed_2025002599"
    //                       style={{ width: '350px', height: '223px' }}
    //                     />
    //                   </picture>
    //                 </a>
    //                 <div className="banner-button">
    //                   <a href={`/blog/${blog?.blog_id}`} className="btn btn-xs"
    //                     style={{ backgroundColor: '#A67B5B' }}

    //                   >
    //                     Read More <i className="fi-rs-arrow-small-right"></i>
    //                   </a>
    //                 </div>
    //               </a>
    //             </div>
    //           </div>
    //         ))}

    //       </div>
    //     </div>
    //   </section>

    // </div>

    <main class="main" id="main-section">
      <div class="page-header mt-30 mb-75">
        <div class="container">
          <div class="archive-header page_speed_1245969219">
            <div class="row align-items-center">
              <div class="col-xl-3">
                <h1 class="mb-15">Blog</h1>
                <div class="breadcrumb">
                  <div class="breadcrumb-item d-inline-block">
                    <Link to={"/"} title="Home">Home </Link>
                  </div>
                  <span></span>
                  <div class="breadcrumb-item d-inline-block active">
                    <div itemprop="item">Blog </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-content mb-50">
        <div class="container">
          <div class="row">
            <div class="col-lg-9">
              <div class="loop-grid pr-30">
                <div class="row gap-3">
                  {data?.map((blog) => (
                    <article class="col-xl-4 col-lg-6 col-md-6 text-center hover-up mb-30 animated" key={blog.blog_id} 
                    style={{padding:'15px', borderRadius:'10px', boxShadow: 'box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;'}}
                    >
                      <div class="post-thumb">
                        <Link to={`/blog/${blog?.blog_id}`}>
                          <img class="border-radius-15" src={blogurl + blog?.thumbnail} alt="4 Expert Tips On How To Choose The Right Men’s Wallet" style={{ height: '203px' }} />
                        </Link>
                      </div>
                      <div class="entry-content-2">
                        <p class="mb-10 font-sm h6">
                          <Link class="entry-meta text-muted" to={`/blog/${blog?.blog_id}`}>KeralaSpecial Blogs</Link>
                        </p>
                        <h4 class="post-title mb-15">
                          <Link to={`/blog/${blog?.blog_id}`}>{blog?.title}</Link>
                        </h4>
                        {/* <div class="entry-meta font-xs color-grey mt-10 pb-10">
                                    <div>
                                        <span class="post-on mr-10">15 Sep 2024</span>
                                        <span class="hit-count has-dot mr-10">2,207 Views</span>
                                    </div>
                                </div> */}
                      </div>
                    </article>
                  ))}
                </div>
              </div>
            </div>
            <div class="col-lg-3 primary-sidebar sticky-sidebar">
              <div class="widget-area">
                <div class="sidebar-widget-2">
                </div>
                <div class="sidebar-widget widget-category-2 mb-30">
                  <h5 class="section-title style-1 mb-30">Categories</h5>
                  <ul>
                    {catData.map((item, i) => (
                      <li key={i}
                      >
                        <Link to={`/category/${item.category_slug}`}>
                          <img data-bb-lazy="true" loading="lazy" src={categoryurl + item.logo} alt={item.category_name}></img>
                          {item.category_name}
                        </Link>
                        <span class="count">{item.subcategory.length}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div class="sidebar-widget widget-tags mb-50 pb-10">
                  <h5 class="section-title style-1 mb-30">Popular Tags</h5>
                  <ul class="tags-list">
                    <li class="hover-up">
                      <a onClick={() => props?.setSearch("Kannadi")}>
                        <i class="fi-rs-cross mr-10"></i>
                        Kannadi
                      </a>
                    </li>
                    <li class="hover-up">
                      <a onClick={() => props?.setSearch("Mundu")}>
                        <i class="fi-rs-cross mr-10"></i>
                        Mundu
                      </a>
                    </li>
                    <li class="hover-up">
                      <a onClick={() => props?.setSearch("Amadapetti")}>
                        <i class="fi-rs-cross mr-10"></i>
                        Amadapetti
                      </a>
                    </li>
                    <li class="hover-up">
                      <a onClick={() => props?.setSearch("Balaramapuram")}>
                        <i class="fi-rs-cross mr-10"></i>
                        Balaramapuram
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading &&
          <LoaderMain />
        }
      </div>
    </main>

  );
}

export default BlogUser;
