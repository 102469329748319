import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { backend } from "../../../../path";
import DashboardSidebarMain from "../../dashsidebar";

function VendorRegUser() {
  const [formData, setFormData] = useState([]);
  const [photo, setPhoto] = useState("");
  const [certificate, setCertificate] = useState("");
  const [galary, setGalary] = useState("");
  const [count, setCountry] = useState([]);


  const [data, setData] = useState([]);
  const [subdata, setSubData] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState();

  const fetchData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getcategory", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        console.log(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchCountryData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getcountry", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setCountry(data);
        console.log(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchSubData = () => {
    setLoading(true);

    fetch(backend + "getsubcategory", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setSubData(data);
        console.log(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    fetchSubData();
    fetchCountryData();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handlefileuploadPan = (e, type) => {
    let formdata = new FormData();

    formdata.append("document", e.target.files[0]);

    fetch(backend + "fileuploadvendor", {
      method: "POST",
      body: formdata,
    }).then((response) => {
      response.json().then((data) => {
        setFormData({
          ...formData,
          pan: data,
        });
      });
    });
  };
  const handlefileuploadGst = (e, type) => {
    let formdata = new FormData();

    formdata.append("document", e.target.files[0]);

    fetch(backend + "fileuploadvendor", {
      method: "POST",
      body: formdata,
    }).then((response) => {
      response.json().then((data) => {
        setFormData({
          ...formData,
          gst_certificate: data,
        });
      });
    });
  };
  const handlefileuploadExp = (e, type) => {
    let formdata = new FormData();

    formdata.append("document", e.target.files[0]);

    fetch(backend + "fileuploadvendor", {
      method: "POST",
      body: formdata,
    }).then((response) => {
      response.json().then((data) => {
        setFormData({
          ...formData,
          export_license: data,
        });
      });
    });
  };
  const handlefileuploadLogo = (e, type) => {
    let formdata = new FormData();

    formdata.append("document", e.target.files[0]);

    fetch(backend + "fileuploadvendor", {
      method: "POST",
      body: formdata,
    }).then((response) => {
      response.json().then((data) => {
        setFormData({
          ...formData,
          logo: data,
        });
      });
    });
  };
  const handlefileuploadFssai = (e, type) => {
    let formdata = new FormData();

    formdata.append("document", e.target.files[0]);

    fetch(backend + "fileuploadvendor", {
      method: "POST",
      body: formdata,
    }).then((response) => {
      response.json().then((data) => {
        setFormData({
          ...formData,
          fssai: data,
        });
      });
    });
  };

  const handlemultiplefileupload = (event, state) => {
    let formdata = new FormData();

    var $files = event.target.files;

    // console.log($files)

    Array.from(event.target.files).forEach((element, index) => {
      formdata.append(`documents_${index}`, element);
    });
    formdata.append("length", $files.length);

    // formdata.append('documents',event.target.files)

    fetch(backend + "multiplefileupload", {
      method: "POST",
      body: formdata,
    }).then((response) => {
      response.json().then((data) => {
        setGalary(JSON.stringify(data));
      });
    });
  };

  const handleSubmit = () => {
    formData.status = 1;
    formData.type = 2;

    fetch(backend + "registration", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            console.error("Email Already Exists");
            toast.error("Email Already Exists", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Registration failed");
            toast.error("Registration failed", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          console.log("success");
          // Additional success handling code here
          toast.success("Successfully Registered", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <>
            <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <div className="breadcrumb-item d-inline-block">
                <a href="index.html" title="Home">
                  {" "}
                  Home{" "}
                </a>
              </div>
              <span></span>
              <div className="breadcrumb-item d-inline-block active">
                <div itemprop="item">Vendor Register</div>
              </div>
            </div>
          </div>
        </div>
      <div className="container">
        <div className="row">
        <div className="col-lg-1"></div>
          <div className="col-lg-10">
            <div className="login_wrap widget-taber-content background-white">
              <div className="padding_eight_all bg-white">
                <div className="padding_eight_all bg-white">
                  <div className="mb-20 mt-40">
                  <h3 className="mb-20">Vendor Registration</h3>
                  <p>Please fill in the information below</p>                   
                  </div>
                  <br></br>
                </div>
                <form
                // onSubmit={handleSubmit}
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="bVwKUehCmCMBBOuQToUzdDhTTdTBfg78RlsalVJQ"
                    autocomplete="off"
                  />
                  <div className="form__content">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Vendor Name</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="name"
                            id="txt-name"
                            type="text"
                            required
                            value={formData.vendor_name || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                vendor_name: e.target.value,
                              })
                            }
                            placeholder="Vendor Name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="company_address">Company Name</label>
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="company_address"
                            id="txt-name"
                            type="text"
                            required
                            value={formData.company_name || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                company_name: e.target.value,
                              })
                            }
                            placeholder="Company Name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="company_address">
                            Company Address
                          </label>
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="company_address"
                            id="txt-name"
                            type="text"
                            required
                            value={formData.company_address || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                company_address: e.target.value,
                              })
                            }
                            placeholder="Company Address"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="email">Email</label>
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="email"
                            id="txt-email"
                            type="email"
                            required
                            value={formData.email || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                email: e.target.value,
                              })
                            }
                            placeholder="Primary email address"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="street_name">Street Name</label>
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            type="text"
                            name="street_name"
                            required
                            value={formData.street_name || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                street_name: e.target.value,
                              })
                            }
                            placeholder="Street Name"
                          />
                        </div>
                      </div>
                      
                      <div className="col-lg-6">
                        <label htmlFor="country">Country</label>
                        <sup style={{ color: "red", fontSize: "13px" }}>*</sup>
                        <div className="form-group">
                          <select
                            className="form-control"
                            name="category_name"
                            id="txt-name"
                            required
                            value={formData.country || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                country: e.target.value,
                              })
                            }
                          >
                            <option>- select -</option>
                            {count.map((val) => {
                              return (
                                <option value={val.country_id}>
                                  {val.countryname}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="state">State</label>
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            type="text"
                            name="state"
                            required
                            value={formData.state || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                state: e.target.value,
                              })
                            }
                            placeholder="State"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="city">City</label>
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            type="text"
                            name="city"
                            required
                            value={formData.city || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                city: e.target.value,
                              })
                            }
                            placeholder="City"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="pincode">Pin Code</label>
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            type="text"
                            name="pincode"
                            required
                            value={formData.pincode || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                pincode: e.target.value,
                              })
                            }
                            placeholder="Pincode"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="street_name">
                            Primary Contact Number
                          </label>
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            type="text"
                            name="phone"
                            minLength={6}
                            maxLength={12}
                            required
                            value={formData.contact || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                contact: e.target.value.replace(/[^0-9+]/g, ""),
                              })
                            }
                            placeholder="Primary contact number"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="gst_number">GST Number</label>
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            type="text"
                            name="gst_number"
                            required
                            value={formData.gst_number || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                gst_number: e.target.value,
                              })
                            }
                            placeholder="GST number"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="street_name">Description</label>
                          <input
                            className="form-control"
                            type="text"
                            name="description"
                            value={formData.description || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                description: e.target.value,
                              })
                            }
                            placeholder="Description"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="category">Firm Type</label>
                          <select
                            className="form-control"
                            name="category_name"
                            id="txt-name"
                            required
                            value={formData.firm_type || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                firm_type: e.target.value,
                              })
                            }
                          >
                          <option value="">Select Nature of the Firm</option>
                          <option value="Proprietorship">Proprietorship</option>
                          <option value="Partnership">Partnership</option>
                          <option value="LLP">LLP</option>
                          <option value="Private Limited">Private Limited</option>
                          <option value="Public Limited">Public Limited</option>
                          </select>
                        </div>
                      </div>
                      {/* <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="category">Category</label>
                          <select
                            className="form-control"
                            name="category_name"
                            id="txt-name"
                            required
                            value={formData.category_id || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                category_id: e.target.value,
                              })
                            }
                          >
                            <option>- select -</option>
                            {data.map((val) => {
                              return (
                                <option value={val.category_id}>
                                  {val.category_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div> */}
                      {/* <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="sub_category">Sub Category</label>
                          <select
                            className="form-control"
                            name="sub_category_id"
                            value={formData.sub_category_id || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                sub_category_id: e.target.value,
                              })
                            }
                          >
                            <option>- select -</option>
                            {subdata.map((val) => {
                              return (
                                <option value={val.sub_category_id}>
                                  {val.sub_category_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div> */}
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="alternate">
                            Alternate Contact Number
                          </label>
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            type="text"
                            name="alternate_contact"
                            required
                            minLength={6}
                            maxLength={12}
                            value={formData.alternate_contact || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                alternate_contact: e.target.value.replace(
                                  /[^0-9+]/g,
                                  ""
                                ),
                              })
                            }
                            placeholder="Alternate Contact Number"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="alternate_email">
                            Alternate Email
                          </label>
                          <input
                            className="form-control"
                            type="email"
                            name="alternate_email"
                            value={formData.alternate_email || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                alternate_email: e.target.value,
                              })
                            }
                            placeholder="Alternate Email"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="gst_certificate">
                            GST Certificate
                          </label>
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            type="file"
                            id="gst_certificate"
                            name="gst_certificate"
                            required
                            // value={formData.gst_certificate || ""}
                            onChange={(event) => {
                              handlefileuploadGst(event, 1);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="logo">Logo</label>
                          <input
                            className="form-control"
                            type="file"
                            name="logo"
                            onChange={(event) => {
                              handlefileuploadLogo(event, 2);
                            }}
                            placeholder="Upload Your Logo"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="export_license">Export License</label>
                          <input
                            className="form-control"
                            type="file"
                            name="export_license"
                            // value={formData.export_license || ""}
                            onChange={(event) => {
                              handlefileuploadExp(event, 3);
                            }}
                            placeholder="Export License"
                          />
                        </div>
                      </div>
                      {/* <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="other_license">Other License</label>
                          <input
                            className="form-control"
                            type="file"
                            name="other_license"
                            // value={formData.other_license || ""}
                            onChange={(event) => {
                              handlemultiplefileupload(event);
                            }}
                            placeholder="Other License"
                          />
                        </div>
                      </div> */}

                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="pan">PAN Card</label>
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            type="file"
                            name="PAN"
                            required
                            // value={formData.pan || ""}
                            onChange={(event) => {
                              handlefileuploadPan(event, 4);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="pan">Aadhar Number</label>
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            type="text"
                            name="aadhar_number"
                            value={formData.aadhar_number || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                aadhar_number: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="pan">Bank Account No</label>
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            type="text"
                            name="bank_account_no"
                            value={formData.bank_account_no || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                bank_account_no: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="pan">Bank Branch Name</label>
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            type="text"
                            name="bank_branch_name"
                            value={formData.bank_branch_name || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                bank_branch_name: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="pan">IFSC Code</label>
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            type="text"
                            name="ifsc_code"
                            value={formData.ifsc_code || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                ifsc_code: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-fill-out btn-block hover-up"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                    <div style={{ textAlign: 'center', marginTop: '20px', fontWeight: 'bold' }}>
                      Call us on <a href="tel:+917356629493">+91-7356629493</a> if you require any assistance.
                    </div>
                  </div>
                  <br></br>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-1"></div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
export default VendorRegUser;
