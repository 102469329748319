import React, { useState } from 'react';
import ScrollToTop from '../../../components/whatsapp connect';
import { backend } from "../../../../path";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useNavigate } from 'react-router-dom';
function OldUserReset() {

  const [email, setEmail] = useState('');
  const [otp, setOTP] = useState('');
  const [password, setPassword] = useState('');
  const [newpassword, setNewPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [otpError, setOTPError] = useState('');
  const [activeState, setActiveState] = useState(0);
  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );

  const navigate = useNavigate()

  const handleCurrentChange = (event) => {
    setPassword(event.target.value);
  };
  const handleNewChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleSubmit = () => {

    if (!password.trim()) {
      toast.error("Old Password Cannot be Null", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }); return;
    }
    if (!newpassword.trim()) {
      toast.error("New Password Required", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }); return;
    }
    fetch(backend + "updateuserpassword", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify({ password: password, newpassword: newpassword })
    })
      .then((response) => {
        response.json().then((data) => {
        });
        if (response.ok) {
          setActiveState(1)
        }
        if (!response.ok) {
          console.error(
            "Failed to Login:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 401) {
            toast.error("Incorrect Password, Try Again", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("Invalid Email", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }
      })
      .catch((error) => {
        console.error("Error during login:", error);
        toast.error("Invalid Email", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <>
      <ToastContainer />
      <main className="main" id="main-section">
      <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <div className="breadcrumb-item d-inline-block">
                <a href="/login" title="Home">
                  {" "}
                  Login{" "}
                </a>
              </div>
              <span></span>
              <div className="breadcrumb-item d-inline-block active">
                <div itemprop="item"> Security Reminder </div>
              </div>
            </div>
          </div>
        </div>
        <section className="pt-100 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 m-auto">
                <div className="login_wrap widget-taber-content background-white border-radius-10">
                  <div className="padding_eight_all bg-white">
                    <div className="heading_s1 mb-20">
                      <a className="btn btn-lg mb-2 w-100"
                        style={{
                          fontSize: '24px',
                          backdropFilter: 'inherit',
                          backgroundBlendMode: 'color-burn',
                          backgroundColor: '#ADD899',
                          fontWeight: 'bolder',
                          color: '#fff',
                          padding: '20px',
                          boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'
                        }}
                      >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="200px" height="200px" viewBox="0 0 48 48"><path fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" d="M22.2 4.86L6.69 11.25V27C6.69 35.44 24 43.5 24 43.5S41.31 35.44 41.31 27V11.25L25.8 4.86a4.68 4.68 0 0 0-3.6 0"></path><path fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" d="M30.45 20.7H24v-8.92L17.69 25h6.45v8.92z"></path></svg>
<br></br>
                        Thank you for visiting KeralaSpecial. You are a valued customer, and we appreciate your patronage.

                        <br></br>
                        For your security, we recommend updating your password as it has been a while since you last changed it.                        <div className='d-flex justify-content-center mb-20 mt-20'>
                        </div>
                        <div className='mt-20 mb-20 d-block d-md-flex justify-content-center gap-4'>
                          <a href='/forget-password'>
                            <button className="btn" type=""
                              style={{
                                boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'
                              }}
                            >
                              Reset Password Now
                            </button></a>
                        </div>


                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ScrollToTop />
          </div>
        </section>
      </main>
    </>
  );
}

export default OldUserReset;
