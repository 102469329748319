import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { backend } from "../../../../../path";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Loader from "../../../../layout/header/loader";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../../../components/whatsapp connect";
import './vendor.css'

function VendorRegistration() {
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();

  const handleSubmit = () => {
    const requiredFields = [
      "vendor_name",
      "contact_person_name",
      "contact_person_phone",
      "contact_person_whatsapp",
      "firm_type",
      "gst_registration",
      "gst_registration_no",
      "bank_account_no",
      "bank_branch_name",
    ];
    const emptyFields = requiredFields.filter((field) => !formData[field]);

    if (emptyFields.length > 0) {
      toast.error("Please fill in all required fields", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    const type = 1;
    formData.type = type;

    fetch(backend + "registration", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          if (response.status === 409) {
            console.error("Email Already Exists");
            toast.error("Email Already Exists", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Registration failed");
            toast.error("Registration failed", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          navigate("/login");
          toast.success("Successfully Registered", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <>
      <Loader />
      <main className="main" id="main-section">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <div className="breadcrumb-item d-inline-block">
                <a href="index.html" title="Home">
                  {" "}
                  Home{" "}
                </a>
              </div>
              <span></span>
              <div className="breadcrumb-item d-inline-block active">
                <div itemprop="item">Vendor Register</div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-content pt-150 pb-150">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
                <div className="row">
                  <h3 className="mb-20">Vendor Registration</h3>
                  <p>Please fill in the information below</p>
                  <br />
                  <br />
                  <form className="vendor" onSubmit={handleSubmit}>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="vendor_name">Vendor Name</label>
                        <input
                          type="text"
                          id="vendor_name"
                          name="vendor_name"
                          value={formData.vendor_name || ""}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              vendor_name: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="contact_person_name">Contact Person Name</label>
                        <input
                          type="text"
                          id="contact_person_name"
                          name="contact_person_name"
                          value={formData.contact_person_name || ""}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              contact_person_name: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="contact_person_phone">Contact Person Phone</label>
                        <input
                          type="text"
                          id="contact_person_phone"
                          name="contact_person_phone"
                          value={formData.contact_person_phone || ""}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              contact_person_phone: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="contact_person_whatsapp">Contact Person WhatsApp</label>
                        <input
                          type="text"
                          id="contact_person_whatsapp"
                          name="contact_person_whatsapp"
                          value={formData.contact_person_whatsapp || ""}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              contact_person_whatsapp: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="firm_type">Firm Type</label>
                        <select className="option"
                          id="firm_type"
                          name="firm_type"
                          value={formData.firm_type || ""}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              firm_type: e.target.value,
                            })
                          }
                          required
                        >
                          <option value="">Select Nature of the Firm</option>
                          <option value="Proprietorship">Proprietorship</option>
                          <option value="Partnership">Partnership</option>
                          <option value="LLP">LLP</option>
                          <option value="Private Limited">Private Limited</option>
                          <option value="Public Limited">Public Limited</option>
                        </select>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="pan_card">PAN Card</label>
                        <input
                          type="file"
                          id="pan_card"
                          name="pan_card"
                          value={formData.pan_card || ""}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              pan_card: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="aadhar_card">Aadhar Card</label>
                        <input
                          type="file"
                          id="aadhar_card"
                          name="aadhar_card"
                          value={formData.aadhar_card || ""}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              aadhar_card: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="fssai_license">FSSAI License</label>
                        <input
                          type="file"
                          id="fssai_license"
                          name="fssai_license"
                          value={formData.fssai_license || ""}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              fssai_license: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="gst_registration">GST Registration</label>
                        <input
                          type="file"
                          id="gst_registration"
                          name="gst_registration"
                          value={formData.gst_registration || ""}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              gst_registration: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="gst_registration_no">GST Registration No</label>
                        <input
                          type="text"
                          id="gst_registration_no"
                          name="gst_registration_no"
                          value={formData.gst_registration_no || ""}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              gst_registration_no: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="bank_account_no">Bank Account No</label>
                        <input
                          type="text"
                          id="bank_account_no"
                          name="bank_account_no"
                          value={formData.bank_account_no || ""}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              bank_account_no: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="bank_branch_name">Bank Branch Name</label>
                        <input
                          type="text"
                          id="bank_branch_name"
                          name="bank_branch_name"
                          value={formData.bank_branch_name || ""}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              bank_branch_name: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </div>



                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="ifsc_code">IFSC Code</label>
                        <input
                          type="text"
                          id="ifsc_code"
                          name="ifsc_code"
                          value={formData.ifsc_code || ""}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              ifsc_code: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                    <button type="submit">Submit</button>
                    <div style={{ textAlign: 'center', marginTop: '20px', fontWeight: 'bold' }}>
                      Call us on <a href="tel:+917356629493">+91-7356629493</a> if you require any assistance.
                    </div>
                  </form>
                </div>
              </div>
              <ScrollToTop />
            </div>
          </div>
        </div>
      </main>

      <ToastContainer />
    </>
  );
}

export default VendorRegistration;