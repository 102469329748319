import { useEffect, useState } from "react";
import { backend, billurl, producturl } from "../../../../path";
import useRazorpay from "react-razorpay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "../../../components/whatsapp connect";
import LoaderMain from "../../../layout/header/loader_sub";
import { useNavigate } from "react-router-dom";

function MyOrdersUser() {
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [data, setData] = useState([]);
  const [Razorpay] = useRazorpay();
  const [formData, setFormData] = useState({});
  const [newAdd,] = useState({});
  const [count, setCountry] = useState([]);
  // const [open, setOpen] = useState(false);
  // const [delivery, setDelivery] = useState(false);
  // const [newDelivery, setNewDelivery] = useState(false);
  const [copy, setCopy] = useState(false);
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const [authenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const fetchData = () => {
    setLoading(true);

    fetch(backend + `getmyorders`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authenticated?.access_token}`
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchCountryData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getcountry", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setCountry(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchAddress = () => {
    setLoading(true);

    fetch(backend + "getaddress", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authenticated?.access_token}`
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // Modify the object to include is_primary: 0
        // const updatedData = {
        //   ...data,
        //   is_primary: 0
        // };
        // Set the form data with the updated object
        setFormData(data[0]);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const calculateTotalMRP = () => {
    let total = 0;
    data.forEach((item) => {
      total += item?.total;
    });
    return total;
  };

  useEffect(() => {
    fetchData();
    fetchCountryData();
    fetchAddress();
  }, []);

  // const handleQuantityChange = async (id, qty) => {
  //   console.log("Handling quantity change for ID:", id);
  //   console.log("New quantity:", qty);

  // };


  const handleCancelClick = (orderId) => {
    const userConfirmed = window.confirm("Are you sure you want to cancel this order ? 🙁");
    if (userConfirmed) {
      handleCancelOrder(orderId);
    }
  };

  const handleCancelOrder = async (id) => {
    const formData = {
      order_id: id,
    };
    try {
      const response = await fetch(backend + `cancelorder`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        fetchData();
        toast.success("Order Cancelled 🥹", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (response.status === 409) {
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //payment


  const handlePayment = async (id, total, details) => {

    const options = {
      key: "rzp_live_hgw86WhyVk5w1z", // Enter the Key ID generated from the Dashboard
      // amount: total, 
      amount: 1 * 100,
      currency: "INR",
      name: "Kerala Specials",
      description: "Order Payment",
      image: "https://example.com/your_logo",
      handler: function (response) {
        // alert(response.razorpay_payment_id);
        handleSuccessOrder(id, response.razorpay_payment_id)
      },
      prefill: {
        name: `${details.first_name} ${details.last_name}`,
        email: `${details.email}`,
        contact: `${details.contact}`,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });

    rzp1.on('payment.success', function (response) {
    })
    rzp1.open()
  };






  const handleSubmit = () => {
    // List of required fields and their corresponding display names
    const requiredFields = [
      { key: "first_name", displayName: "First Name" },
      { key: "house_name", displayName: "House Name" },
      { key: "street_name", displayName: "Street Name" },
      { key: "country", displayName: "Country" },
      { key: "state", displayName: "State" },
      { key: "city", displayName: "City" },
      { key: "pincode", displayName: "Pincode" },
      { key: "contact", displayName: "Contact" },
    ];

    // Check if any required field is missing or blank
    const invalidField = requiredFields.find((field) => {
      const value = formData[field.key];
      return typeof value !== "string" || value.trim() === ""; // Check if value is not a string or is empty or consists of only whitespace
    });

    if (invalidField) {
      toast.error(`${invalidField.displayName} cannot be blank`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Stop further execution
    }

    fetch(backend + "addaddress", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );
          if (response.status === 409) {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {

          handleOrder()
          // Additional success handling code here
          toast.success("Primary Address Updated", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
        // navigate("/admin-users");
      })
      .catch((error) => {
        toast.error("Something Went Wrong", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleSubmitNewAddress = () => {
    // List of required fields and their corresponding display names
    const requiredFields = [
      { key: "first_name", displayName: "First Name" },
      { key: "house_name", displayName: "House Name" },
      { key: "street_name", displayName: "Street Name" },
      { key: "country", displayName: "Country" },
      { key: "state", displayName: "State" },
      { key: "city", displayName: "City" },
      { key: "pincode", displayName: "Pincode" },
      { key: "contact", displayName: "Contact" },
    ];

    // Check if any required field is missing or blank
    const invalidField = requiredFields.find((field) => {
      const value = newAdd[field.key];
      return typeof value !== "string" || value.trim() === ""; // Check if value is not a string or is empty or consists of only whitespace
    });


    if (invalidField) {
      toast.error(`${invalidField.displayName} cannot be blank`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Stop further execution
    }

    fetch(backend + "addaddress", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(newAdd),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );
          if (response.status === 409) {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then((data) => {
          handleOrderAddress(data?.delivery_address_id)
          // Additional success handling code here
          toast.success("Address Added", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
        // navigate("/admin-users");
      })
      .catch((error) => {
        toast.error("Something Went Wrong", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleOrder = () => {

    let payload = {}
    payload.delivery_id = formData.delivery_address_id;
    payload.cart_id = data.map(item => item.cart_id);


    fetch(backend + "createorder", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );
          if (response.status === 409) {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then((data) => {

          handlePayment(data.order_no, data.grandTotalPrice, data.user_details)
          // Additional success handling code here
          // toast.success("Order Generated", {
          //   position: "top-right",
          //   autoClose: 3000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
        });
        // navigate("/admin-users");
      })
      .catch((error) => {
        toast.error("Something Went Wrong", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleOrderAddress = (id) => {

    let payload = {}
    payload.delivery_id = id;
    payload.cart_id = data.map(item => item.cart_id);


    fetch(backend + "createorder", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );
          if (response.status === 409) {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then((data) => {

          handlePayment(data.order_no, data.grandTotalPrice, data.user_details)
          // Additional success handling code here
          // toast.success("Order Generated", {
          //   position: "top-right",
          //   autoClose: 3000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
        });
        // navigate("/admin-users");
      })
      .catch((error) => {
        toast.error("Something Went Wrong", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleSuccessOrder = (order_id, pay_id) => {

    let payload = {}
    payload.razorpay_payment_id = pay_id
    payload.order_no = order_id
    payload.cart_id = data.map(item => item.cart_id);

    fetch(backend + "successfullpayment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );
          if (response.status === 409) {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then((data) => {
          toast.success("Order Placed Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
        // navigate("/admin-users");
      })
      .catch((error) => {
        toast.error("Something Went Wrong", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleProductRoute = (productId) => {
    navigate(`/product/${productId}`);
    // window.location.reload();
  };

  return (
    <>
      <main className="main " id="main-section">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <div className="breadcrumb-item d-inline-block">
                <a href="/" title="Home">
                  {" "}
                  Home{" "}
                </a>
              </div>
              <span></span>
              <div className="breadcrumb-item d-inline-block active">
                <div itemprop="item"> My Orders </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="mb-80 mt-50 section--shopping-cart">
            <div className="row">
              <div className="col-lg-12">
                <div className="d-flex justify-content-between">
                  <a className="btn btn-lg mb-2 w-100"
                    style={{
                      fontSize: '24px',
                      backdropFilter: 'inherit',
                      backgroundBlendMode: 'color-burn',
                      backgroundColor: '#ADD899',
                      fontWeight: 'bolder',
                      color: '#fff',
                      boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'
                    }}
                  >
                    My Orders</a>
                  {/* <h1 className="heading-2 mb-10">My Orders</h1> */}
                </div>
              </div>
            </div>
            <div className="d-none d-md-block d-lg-block">
              <div className="row product-grid mx-1">
                {loading && (
                  <div>
                    {/* Loading...  */}
                    <LoaderMain />
                  </div>
                )}
                {!loading && (
                  data.length > 0 ? (
                    data.map((item, i) => (
                      <>
                        <div
                          className="d-flex justify-content-between product-cart-wrap mb-30 wow animate__animated animate__fadeIn "
                          data-wow-delay="0.1s"
                          style={{ paddingTop: '20px' }}
                        >
                          <div>

                            <div className="product-img-action-wrap">
                              <div className="product-img product-img-zoom">
                                <a
                                  onClick={() => handleProductRoute(item.product_slug)}
                                >
                                  <img
                                    className="default-img"
                                    style={{ height: "200px", width: "100%", objectFit: 'contain', objectPosition: 'center' }}
                                    // src={mediaurl + item.images}
                                    src={producturl + item?.thumbnail}
                                  // alt="Nestle Original Coffee-Mate Coffee Creamer"
                                  />
                                  <img
                                    className="hover-img"
                                    style={{ height: "200px", width: "100%", objectFit: 'contain', objectPosition: 'center' }}
                                    src={producturl + item?.thumbnail}
                                  // alt="Nestle Original Coffee-Mate Coffee Creamer"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div>
                            {" "}
                            <div className="product-content-wrap pt-4">
                              <div className="product-category">
                                <a
                                  onClick={() => handleProductRoute(item.product_slug)}
                                >
                                  {item?.category_name}
                                </a>
                              </div>
                              <h2 className="text-truncate">
                                <a
                                  href={`/product/${item.product_slug}`}
                                >
                                  {item?.product_name}
                                </a>
                              </h2>
                              <div className="product-rate-cover">
                                <div className=" d-inline-block">
                                </div>
                                {/* <span className="font-small ml-5 text-muted">
                                  ({item?.rating ?? "Not Yet Rated"})
                                </span> */}
                              </div>
                              <div className="text-truncate">
                                <span className="font-small text-muted">
                                  Sold By{" "}
                                  <b style={{ fontWeight: '700' }}>
                                    <a
                                      onClick={() => handleProductRoute(item.product_slug)}
                                    >
                                      {item?.manufacturer_name ?? "KeralaSpecial"}
                                    </a></b>
                                </span>
                              </div>
                              <div className="product-card-bottom">
                                <div className="product-price">
                                  <span> {item?.house_name}, {item?.street_name}, {item?.city}, {item?.pincode} </span>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between">
                                <div className="product-card-bottom">
                                  <div className="product-price">
                                    <span className="d-flex">
                                      {item?.status &&
                                        <div className="text-secondary mt-5 mx-3">
                                          <button
                                            type="button"
                                            className="btn btn-fill-out btn-block hover-up"
                                            style={{ color: '#FF9A00' }}
                                            disabled
                                          >
                                            {item?.status === 1 ? 'PROCESSING' : item?.status === 2 ? 'DISPATCHED FOR DELIVERY' : item?.status === 3 ? 'DELIVERED' : 'CANCELLED'}
                                          </button>
                                        </div>
                                      }



                                    </span>
                                  </div>
                                </div>
                                <div className="pt-5 mt-5">
                                  {item?.tracking_id && (
                                    <div className="text-secondary mt-5">
                                      <div style={{ fontWeight: '500' }}>Tracking ID : {item.tracking_id}

                                      </div>

                                      <button
                                        type="button"
                                        className="btn btn-fill-out btn-block hover-up"
                                        onClick={() => {
                                          navigator.clipboard.writeText(item.tracking_id)
                                            .then(() => {
                                              console.log('Tracking ID copied to clipboard');
                                              // Optionally, you can provide feedback to the user
                                              // alert('Tracking ID copied to clipboard: ' + item.tracking_id);
                                            })
                                            .catch(err => {
                                              console.error('Failed to copy tracking ID: ', err);
                                              // Optionally, you can provide feedback to the user
                                              // alert('Failed to copy tracking ID. Please try again.');
                                            });
                                          setCopy(true);
                                        }}
                                      >
                                        {copy ? 'Copied' : 'Copy'} <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 32 32"><path fill="#fff" d="M9.17 6H7.5A1.5 1.5 0 0 0 6 7.5v19A1.5 1.5 0 0 0 7.5 28H12a1 1 0 1 1 0 2H7.5A3.5 3.5 0 0 1 4 26.5v-19A3.5 3.5 0 0 1 7.5 4h1.67A3 3 0 0 1 12 2h6c1.306 0 2.418.835 2.83 2h1.67A3.5 3.5 0 0 1 26 7.5a1 1 0 1 1-2 0A1.5 1.5 0 0 0 22.5 6h-1.67A3 3 0 0 1 18 8h-6a3 3 0 0 1-2.83-2M11 5a1 1 0 0 0 1 1h6a1 1 0 1 0 0-2h-6a1 1 0 0 0-1 1m3 8.5a3.5 3.5 0 0 1 3.5-3.5h8a3.5 3.5 0 0 1 3.5 3.5v13a3.5 3.5 0 0 1-3.5 3.5h-8a3.5 3.5 0 0 1-3.5-3.5z"></path></svg>
                                      </button>
                                      <a href={item?.url} target="_blank" rel="noopener noreferrer">
                                        <button
                                          type="button"
                                          className="btn btn-fill-out btn-block hover-up btn-secondary mx-2"
                                        >
                                          Track
                                        </button>
                                      </a>
                                    </div>
                                  )}

                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="pt-4 mx-3">
                            <div className="align-middle">
                              <h6 className="heading-2 border-bottom pb-1">
                                TOTAL AMOUNT
                              </h6>
                              <h3 className="heading-4 product-price text-info">
                                ₹ {item?.original_amount}
                              </h3>{" "}

                            </div>
                            <div className="align-middle">

                              <h6 className="heading-4 product-price text-info">
                                QUANTITY : {item?.quantity}
                              </h6>

                            </div>
                            {item?.return === 0 && item?.status != 4 &&
                              <button
                                type="button"
                                className="btn btn-fill-out btn-block hover-up my-4"
                                style={{
                                  backgroundColor: isHovered ? 'red' : '#fff',
                                  color: isHovered ? '#fff' : 'red',
                                  border: '1px solid',
                                  borderColor: isHovered ? '#fff' : 'red',

                                }}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                onClick={() => handleCancelClick(item?.order_id)}                              >
                                CANCEL ORDER
                              </button>
                            }
                            {item?.bill_file &&
                              <div className="text-secondary mt-5">
                                <a href={billurl + item?.bill_file}>

                                  <button
                                    type="button"
                                    className="btn btn-fill-out btn-block hover-up btn-warning"
                                  >
                                    Bill
                                  </button>
                                </a>

                              </div>
                            }
                          </div>
                        </div>
                      </>
                    ))

                  ) : (
                    <form>
                      <div className="row">
                        <div className="col-lg-12">
                          <p className="text-center">You haven't done any orders yet!</p>
                        </div>
                      </div>
                    </form>
                  ))}


              </div>
            </div>
            <div className="d-block d-md-none d-lg-none">
              <div className="row product-grid mx-1">
                {loading && (
                  <div>
                    {/* Loading...  */}
                    <LoaderMain />
                  </div>
                )}
                {!loading && (
                  data.length > 0 ? (
                    data.map((item, i) => (
                      <>
                        <div
                          className="d-flex justify-content-between product-cart-wrap mb-30 wow animate__animated animate__fadeIn "
                          data-wow-delay="0.1s"
                        >
                          <div>
                          </div>
                          <div>
                            {" "}
                            <div className="product-content-wrap pt-4">
                              <div className="product-img-action-wrap">
                                <div className="product-img product-img-zoom d-flex justify-content-between"
                                  style={{ backgroundColor: '#3bb77e', color: '#fff', borderRadius: '5px', border: 'none', paddingRight: '5px', alignContent: "center", alignItems: 'center' }}
                                >
                                  <div>
                                    <a
                                      onClick={() => handleProductRoute(item.product_slug)}
                                    >
                                      <img
                                        className="default-img"
                                        style={{ height: "100px", width: "100%", objectFit: 'contain', objectPosition: 'center' }}
                                        // src={mediaurl + item.images}
                                        src={producturl + item?.thumbnail}
                                      // alt="Nestle Original Coffee-Mate Coffee Creamer"
                                      />
                                      <img
                                        className="hover-img"
                                        style={{ height: "100px", width: "100%", objectFit: 'contain', objectPosition: 'center' }}
                                        src={producturl + item?.thumbnail}
                                      // alt="Nestle Original Coffee-Mate Coffee Creamer"
                                      />
                                    </a></div>
                                  <div>

                                    <h2 className="text-truncate">
                                      <a
                                        style={{ color: '#fff' }}
                                        href={`/product/${item.product_slug}`}
                                      >
                                        {item?.product_name}
                                      </a>
                                    </h2>
                                    <div className="text-truncate">
                                      <span className="font-small text-muted">
                                        <b style={{ fontWeight: '700', color: '#000' }}>
                                          <a
                                            onClick={() => handleProductRoute(item.product_slug)}
                                          >
                                            {item?.manufacturer_name ?? "KeralaSpecial"}
                                          </a></b>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="product-rate-cover">
                                <div className=" d-inline-block">
                                </div>
                                {/* <span className="font-small ml-5 text-muted">
                                  ({item?.rating ?? "Not Yet Rated"})
                                </span> */}
                              </div>
                              <div className="">
                                <div className="product-price" style={{ fontSize: '13px' }}>
                                  <span style={{ fontSize: '10px' }}> {item?.house_name}, {item?.street_name}, {item?.city}, {item?.pincode} </span>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between">
                                <div className="product-card-bottom">
                                  <div className="product-price">
                                    <span className="d-flex">
                                      {item?.status &&
                                        <div className="text-secondary">
                                          <button
                                            type="button"
                                            className="hover-up"
                                            style={{ backgroundColor: '#fff', color: '#FF8343', borderRadius: '5px', border: '1px solid #FF8343', height: '36px', width: '100px', fontSize: '12px' }}
                                            disabled
                                          >
                                            {item?.status === 1 ? 'PROCESSING' : item?.status === 2 ? 'DISPATCHED FOR DELIVERY' : item?.status === 3 ? 'DELIVERED' : 'CANCELLED'}
                                          </button>
                                        </div>
                                      }
                                      {item?.tracking_id && (
                                        <>
                                          <div className="text-secondary mx-2">
                                            <button
                                              type="button"
                                              className="hover-up"
                                              style={{ backgroundColor: '#3bb77e', color: '#fff', borderRadius: '5px', border: 'none', height: '36px', width: '120px', fontSize: '12px' }}
                                              onClick={() => {
                                                navigator.clipboard.writeText(item.tracking_id)
                                                  .then(() => {
                                                    console.log('Tracking ID copied to clipboard');
                                                    // Optionally, you can provide feedback to the user
                                                    // alert('Tracking ID copied to clipboard: ' + item.tracking_id);
                                                  })
                                                  .catch(err => {
                                                    console.error('Failed to copy tracking ID: ', err);
                                                    // Optionally, you can provide feedback to the user
                                                    // alert('Failed to copy tracking ID. Please try again.');
                                                  });
                                                setCopy(true);
                                              }}                                          >
                                              {copy ? 'Copied' : 'Copy Tracking id'} <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 32 32"><path fill="#fff" d="M9.17 6H7.5A1.5 1.5 0 0 0 6 7.5v19A1.5 1.5 0 0 0 7.5 28H12a1 1 0 1 1 0 2H7.5A3.5 3.5 0 0 1 4 26.5v-19A3.5 3.5 0 0 1 7.5 4h1.67A3 3 0 0 1 12 2h6c1.306 0 2.418.835 2.83 2h1.67A3.5 3.5 0 0 1 26 7.5a1 1 0 1 1-2 0A1.5 1.5 0 0 0 22.5 6h-1.67A3 3 0 0 1 18 8h-6a3 3 0 0 1-2.83-2M11 5a1 1 0 0 0 1 1h6a1 1 0 1 0 0-2h-6a1 1 0 0 0-1 1m3 8.5a3.5 3.5 0 0 1 3.5-3.5h8a3.5 3.5 0 0 1 3.5 3.5v13a3.5 3.5 0 0 1-3.5 3.5h-8a3.5 3.5 0 0 1-3.5-3.5z"></path></svg>
                                            </button>
                                          </div>
                                          <div className="text-secondary">
                                            <a href={item?.url} target="_blank" rel="noopener noreferrer">

                                              <button
                                                type="button"
                                                className="hover-up"
                                                style={{ backgroundColor: '#478CCF', color: '#fff', borderRadius: '5px', border: 'none', height: '36px', width: '50px', fontSize: '12px' }}

                                              >Track</button></a>
                                          </div>
                                        </>
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className="pt-5 mt-5">


                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="pt-4 mx-3">
                            <div className="align-middle">
                              <h6 className="heading-2 border-bottom pb-1">
                                TOTAL AMOUNT
                              </h6>
                              <h3 className="heading-4 product-price text-info">
                                ₹ {item?.original_amount}
                              </h3>{" "}

                            </div>
                            <div className="align-middle">

                              <h6 className="heading-4 product-price text-info">
                                QUANTITY : {item?.quantity}
                              </h6>

                            </div>
                            {item?.return === 0 && item?.status != 4 &&
                              <button
                                type="button"
                                className="btn btn-fill-out btn-block hover-up my-4"
                                style={{
                                  backgroundColor: isHovered ? 'red' : '#fff',
                                  color: isHovered ? '#fff' : 'red',
                                  border: '1px solid',
                                  borderColor: isHovered ? '#fff' : 'red',

                                }}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                onClick={() => handleCancelClick(item?.order_id)}                              >
                                CANCEL ORDER
                              </button>
                            }
                                      {item?.bill_file &&
                                        <div className="text-secondary mt-5">
                                          <a href={billurl + item?.bill_file}>

                                            <button
                                              type="button"
                                              className="btn btn-fill-out btn-block hover-up btn-warning"
                                            >
                                              Bill
                                            </button>
                                          </a>

                                        </div>
                                      }
                          </div> */}
                        </div>
                      </>
                    ))

                  ) : (
                    <form>
                      <div className="row">
                        <div className="col-lg-12">
                          <p className="text-center">You haven't done any orders yet!</p>
                        </div>
                      </div>
                    </form>
                  ))}


              </div>
            </div>

          </div>
        </div>
        <ToastContainer />
        <ScrollToTop />

      </main>
    </>
  );
}
export default MyOrdersUser;
