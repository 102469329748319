import React from "react";

const Avatar = ({ firstName, color }) => {
  // Extracting the first letter of the first name
  const firstLetter = firstName ? firstName.charAt(0).toUpperCase() : "";

  // Inline CSS for the avatar's background color
  const avatarStyle = {
    backgroundColor: color ? color : "#FFBF00", // Default color if not provided
    width: "50px",
    height: "50px",
    borderRadius: "50%", // Make it round
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff", // White color for the text
    fontWeight: "bold",
    fontSize: "24px",
  };

  return (
    <div style={avatarStyle}>
      {firstLetter}
    </div>
  );
};

export default Avatar;
