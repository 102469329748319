import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { backend, producturl } from "../../../path";
import DashboardSidebarMain from "../dashsidebar";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Pagination from "../../components/pagination";


function DashboardProductMain() {
  const [formData, setFormData] = useState([]);
  const [data, setData] = useState([]);
  const [dataCat, setDataCat] = useState([]);
  const [subData, setSubData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [demo, setDemo] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [view, setView] = useState(0);
  const [dummyData, setDummyData] = useState({});
  const [galary, setGalary] = useState("");
  const [count, setCountry] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchInput, setSearchInput] = useState(""); 

  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );
  const type = authenticated.type;
  useEffect(() => {
    fetchDemo();
    fetchCatData();
    fetchSubData();
    fetchGroupData();
  }, []);
  useEffect(() => {
    fetchData();
  }, [pageNumber, pageSize, searchInput]);


  const searchParams = new URLSearchParams({
    page_number: pageNumber,
    page_size: pageSize,
    search: searchInput 
  });

  const fetchData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getproducts?" + searchParams, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const fetchCatData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getcategory", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setDataCat(data);
        console.log(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchSubData = () => {
    setLoading(true);

    fetch(backend + "getsubcategory", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setSubData(data);
        console.log(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchDemo = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getcategorywithsubcategory", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setDemo(data);
        console.log(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchGroupData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getallgroups", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authenticated?.access_token}`
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setGroupData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlefileuploadmany = async (e) => {
    console.log("hai");
    try {
      console.log("File selected:", e.target.files[0]);
      let formdata = new FormData();
      formdata.append("csvFile", e.target.files[0]);

      const response = await fetch(backend + "multiplefileupload", {
        method: "POST",
        body: formdata,
        "Content-Type": "multipart/form-data",
      });

      if (!response.ok) {
        throw new Error(`File upload failed with status ${response.status}`);
      }
      response.json().then((data) => {
        setGalary(JSON.stringify(data));
      });
    } catch (error) {
      console.error("Error during file upload:", error);
    }
  };

  const handleIconClickThumbnail = () => {
    const imageUrl = producturl + formData.thumbnail;
    window.open(imageUrl, "_blank");
  };
  const handleIconClickThumbnail2 = () => {
    const imageUrl = producturl + formData.thumbnail2;
    window.open(imageUrl, "_blank");
  };
  const handleIconClickImages = () => {
    const imageUrl = producturl + formData.images;
    window.open(imageUrl, "_blank");
  };

  const handleSubmit = (id) => {

    fetch(backend + "editproduct", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            console.error("Something Went Wrong");
            toast.error("Something Went Wrongs", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Update failed");
            toast.error("Registration failed", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          console.log("success");
          setView(1);
          // Additional success handling code here
          toast.success("Successfully Updated", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleDeals = (id) => {

    fetch(backend + "addspecialproducts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify({
        product_id:id,
        type: 'Deal of the day'
        }),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            console.error("Something Went Wrong");
            toast.error("Something Went Wrongs", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Update failed");
            toast.error("Error Adding", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          console.log("success");
          setView(1);
          // Additional success handling code here
          toast.success("Product Added to Today's Deal", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handlePopular = (id) => {

    fetch(backend + "addspecialproducts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify({
        product_id:id,
        type: 'Popular Products'
        }),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            console.error("Something Went Wrong");
            toast.error("Something Went Wrongs", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Update failed");
            toast.error("Error", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          console.log("success");
          setView(1);
          // Additional success handling code here
          toast.success("Product Added to Popular Products", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleTopRated = (id) => {

    fetch(backend + "addspecialproducts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify({
        product_id:id,
        type: 'Top Rated'
        }),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            console.error("Something Went Wrong");
            toast.error("Something Went Wrongs", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Update failed");
            toast.error("Error Adding", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          console.log("success");
          setView(1);
          // Additional success handling code here
          toast.success("Product Added to Top Rated Products", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleRecentlyAdded = (id) => {

    fetch(backend + "addspecialproducts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify({
        product_id:id,
        type: 'Recently Added'
        }),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            console.error("Something Went Wrong");
            toast.error("Something Went Wrongs", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Update failed");
            toast.error("Error", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          console.log("success");
          setView(1);
          // Additional success handling code here
          toast.success("Product Added to Recently Added Products", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleTrending = (id) => {

    fetch(backend + "addspecialproducts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify({
        product_id:id,
        type: 'Trending Products'
        }),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            console.error("Something Went Wrong");
            toast.error("Something Went Wrongs", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Update failed");
            toast.error("Error Adding", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          console.log("success");
          setView(1);
          // Additional success handling code here
          toast.success("Product Added to Trending Products", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleTopSelling = (id) => {

    fetch(backend + "addspecialproducts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify({
        product_id:id,
        type: 'Top Selling'
        }),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            console.error("Something Went Wrong");
            toast.error("Something Went Wrongs", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Update failed");
            toast.error("Error", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          console.log("success");
          setView(1);
          // Additional success handling code here
          toast.success("Product Added to Top Selling Products", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleKeralaGem = (id) => {

    fetch(backend + "addspecialproducts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify({
        product_id:id,
        type: 'Group 1'
        }),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            console.error("Something Went Wrong");
            toast.error("Something Went Wrongs", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Update failed");
            toast.error("Error", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          console.log("success");
          setView(1);
          // Additional success handling code here
          toast.success("Product Added to Kerala Gems", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleTodaysFeatured = (id) => {

    fetch(backend + "addspecialproducts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify({
        product_id:id,
        type: 'Group 2'
        }),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            console.error("Something Went Wrong");
            toast.error("Something Went Wrongs", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Update failed");
            toast.error("Error", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          console.log("success");
          setView(1);
          // Additional success handling code here
          toast.success("Product Added to Featured Finds", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleAyurvedicDelight = (id) => {

    fetch(backend + "addspecialproducts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify({
        product_id:id,
        type: 'Group 3'
        }),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            console.error("Something Went Wrong");
            toast.error("Something Went Wrongs", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Update failed");
            toast.error("Error", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          console.log("success");
          setView(1);
          // Additional success handling code here
          toast.success("Product Added to Ayurvedic Delights", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleStatus = (id) => {
    // formData.status = 1;

    dummyData.user_id = id;
    dummyData.status = 2;

    fetch(backend + "updateproductstatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(dummyData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            console.error("Something Went Wrong");
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Something Went Wrong");
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          console.log("success");
          setView(1);
          // Additional success handling code here
          toast.success("Updated", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleStatusOff = (id) => {
    // formData.status = 1;

    dummyData.user_id = id;
    dummyData.id = id;
    dummyData.status = 1;

    fetch(backend + "updateproductstatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(dummyData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            console.error("Something Went Wrong");
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Something Went Wrong");
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          console.log("success");
          setView(1);
          // Additional success handling code here
          toast.success("Updated", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleDelete = (id) => {

    dummyData.product_id = id;

    fetch(backend + "deleteproducts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(dummyData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            console.error("Something Went Wrong");
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Something Went Wrong");
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          console.log("success");
          setView(0);
          // Additional success handling code here
          toast.success("Product Deleted", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handlefileupload = (e, type) => {
    let formdata = new FormData();

    formdata.append("document", e.target.files[0]);

    fetch(backend + "fileuploadthumbnail1", {
      method: "POST",
      body: formdata,
    }).then((response) => {
      response.json().then((data) => {
        setFormData({
          ...formData,
          thumbnail: data,
        });
        toast.success("Thumbnail 1 Edited", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        console.error("Error during file upload:", error);
      });
    });
  };

  const handlefileuploadThumbnail2 = (e) => {
    console.log("File selected:", e.target.files[0]);
    let formdata = new FormData();
    formdata.append("document", e.target.files[0]);

    fetch(backend + "fileuploadthumbnail2", {
      method: "POST",
      body: formdata,
    })
      .then((response) => response.json())
      .then((data) => {
        setFormData({
          ...formData,
          thumbnail2: data,
        });
        toast.success("Thumbnail 2 Edited", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        console.error("Error during file upload:", error);
      });
  };
  const handlemultiplefileupload = (event, state) => {
    let formdata = new FormData();

    var $files = event.target.files;

    // console.log($files)

    Array.from(event.target.files).forEach((element, index) => {
      formdata.append(`documents_${index}`, element);
    });
    formdata.append("length", $files.length);

    // formdata.append('documents',event.target.files)

    fetch(backend + "multiplefileupload", {
      method: "POST",
      body: formdata,
    }).then((response) => {
      response.json().then((data) => {
        setFormData({
          ...formData,
          images: JSON.stringify(data),
        });
        toast.success("Images Edited", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      });
    });
  };

  const handlePageClick = (pageNumber) => {
    setPageNumber(pageNumber);
  };
  // const [formData, setformData] = useState(null);
  // const fetchCatData = () => {
  //   // Assuming setData, setError, and setLoading are state functions from useState
  //   setLoading(true);

  //   fetch(backend + "getcategory", {
  //     method: "GET",
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setData(data);
  //       console.log(data);
  //     })
  //     .catch((error) => {
  //       setError(error);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };
  // const fetchCountryData = () => {
  //   // Assuming setData, setError, and setLoading are state functions from useState
  //   setLoading(true);

  //   fetch(backend + "getcountry", {
  //     method: "GET",
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setCountry(data);
  //       console.log(data);
  //     })
  //     .catch((error) => {
  //       setError(error);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  // const fetchSubData = () => {
  //   setLoading(true);

  //   fetch(backend + "getsubcategory", {
  //     method: "GET",
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setSubData(data);
  //       console.log(data);
  //     })
  //     .catch((error) => {
  //       setError(error);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  // useEffect(() => {
  //   fetchCatData();
  //   fetchSubData();
  //   fetchCountryData();
  // }, []);
  const handleView = (id) => {
    const edit = data?.data?.find((item) => item.product_id === id);
    setView(1);
    setFormData(edit);
  };
console.log(formData)
  const handleBack = () => {
    setView(0);
    fetchData();
  };
  const handleEdit = () => {
    setView(2);
  };
  const handleEditBack = () => {
    setView(1);
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-2 d-none d-lg-flex">
            <DashboardSidebarMain />
          </div>
          {view === 0 && (
            <div className="col-lg-10">
              <div className="login_wrap widget-taber-content background-white">
                <div className="padding_eight_all bg-white">
                  <div className="padding_eight_all bg-white">
                    <div className="d-flex justify-content-between mt-20">
                      <h3 className="">Product</h3>
                      <div className="d-flex justify-content-end gap-3">
                        <a href="/dash-product-add">
                          <button
                            type="button"
                            className="btn btn-fill-out btn-block hover-up"
                            //   onClick={handleSubmit}
                          >
                            Add New
                          </button>
                        </a>
                        <div>
                          {/* Icon component */}
                          <Icon
                            icon="fa6-solid:file-csv"
                            color="green"
                            className="hover-up"
                            fontSize={46}
                            onClick={() =>
                              document.getElementById("fileInput").click()
                            }
                            style={{ cursor: "pointer" }}
                          />

                          {/* File input */}
                          <input
                            type="file"
                            id="fileInput"
                            accept=".csv"
                            onChange={handlefileuploadmany}
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>
                    </div>
                    <br></br>
                    <div className="search-style-2 mb-20 w-100">
                    <form className="form--quick-search">
                      <div className="form-group--icon position-relative">
                      </div>
                      <input
                        type="text"
                        value={searchInput}
                        className="input-search-product p-2"
                        placeholder="Search for products..."
                        onChange={handleSearchInputChange}
                      />
                      <div className="panel--search-result"></div>
                    </form>
                  </div>
                  </div>

                  <div className="table-responsive">
                    <table className="table table-secondary table-hover">
                      <thead className="table-dark">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Product Code</th>
                          <th scope="col">Product Name</th>
                          <th scope="col">Vendor Name</th>
                          <th scope="col">Stock</th>
                          <th scope="col">Status</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.data?.length > 0 ? (
                          data?.data?.map((item, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{item?.product_code}</td>
                            <td>{item?.product_name}</td>
                            <td>{item?.vendor_name}</td>
                            <td>{item?.stock === 0 ? 'Out of Stock' : 'In Stock'}</td>
                            <td>
                              {item?.status === 0
                                ? "pending"
                                : item?.status === 1
                                ? "Avaliable"
                                : "Not Available"}
                            </td>
                            <td>
                              {item?.status === 1 ? (
                                <Icon
                                  icon="carbon:view-filled"
                                  color="green"
                                  className="cursor-pointer ml-2"
                                  fontSize={20}
                                  onClick={() => handleStatus(item?.user_id)}
                                />
                              ) : item?.status === 2 ? (
                                <Icon
                                  icon="carbon:view-off-filled"
                                  color="#b83e0c"
                                  className="cursor-pointer ml-2"
                                  fontSize={20}
                                  onClick={() => handleStatusOff(item?.user_id)}
                                />
                              ) : (
                                ""
                              )}{" "}
                              <Icon
                                icon="icon-park-solid:more-two"
                                color="#1a42cc"
                                className="cursor-pointer ml-2"
                                fontSize={20}
                                onClick={() => handleView(item?.product_id)}
                              />
                            </td>
                          </tr>
                        ))
                        ):(
                          <tr>
                            <th colSpan={7}>No Data Found</th>
                          </tr>
                        )
                        }
                      </tbody>
                    </table>
                  </div>
                </div>

                <br></br>
                <Pagination
        currentPage={pageNumber}
        pageSize={pageSize}
        totalItems={data?.total}
        onPageChange={handlePageClick}
      />
              </div>

            </div>
          )}
          {view === 1 && (
            <div className="col-lg-10">
              <div className="login_wrap widget-taber-content background-white">
                <div className="padding_eight_all bg-white">
                  <div className="padding_eight_all bg-white">
                    <div className="d-flex justify-content-between mb-20 mt-20">
                      <h3 className="">{formData.product_name} </h3>
                      <div>
                        <button
                          type="button"
                          className="btn btn-fill-out btn-block hover-up me-2 btn-secondary"
                          onClick={handleBack}
                        >
                          Back
                        </button>
                        {formData.status === 0 ? (
                          <>
                            <button
                              type="button"
                              className="btn btn-fill-out btn-block hover-up me-2"
                              onClick={() => handleStatusOff(formData.user_id)}
                            >
                              Accept
                            </button>
                            <button
                              type="button"
                              className="btn btn-fill-out btn-block hover-up btn-danger me-2"
                            >
                              Reject
                            </button>
                          </>
                        ) : formData.status === 1 ? (
                          <>
                            <button
                              type="button"
                              className="btn btn-fill-out btn-block hover-up me-2"
                              onClick={handleEdit}
                            >
                              Edit
                            </button>
                            <button
                              type="button"
                              className="btn btn-fill-out btn-block hover-up btn-warning"
                                onClick={()=>handleDelete(formData?.product_id)}
                            >
                              Delete
                            </button>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <br></br>
                    <div className="row">
                      <div className="col-lg-3 border border-right border-light p-4 rounded-3 ">
                        <div
                          className="mx-auto rounded-circle p-5 mb-3 fw-bolder"
                          style={{
                            backgroundImage: `url(${producturl}${formData?.thumbnail})`,
                            borderRadius: "50%",
                            border: "2px solid red",
                            width: "150px",
                            height: "150px",
                            fontSize: "55px",
                            color: "#fff",
                          }}
                        >
                          {/* {formData.vendor_name.charAt(0)} */}
                        </div>
                        <div className="d-flex justify-content-between detail-page">
                          <label className="">Product Code</label>
                          <label>{formData.product_code}</label>
                        </div>
                        <div className="d-flex justify-content-between detail-page">
                          <label className="">Product Name</label>
                          <label>{formData.product_name}</label>
                        </div>
                        <div className="d-flex justify-content-between detail-page">
                          <label className="">Vendor Name</label>
                          <label>{formData.vendor_name}</label>
                        </div>
                        <div className="d-flex justify-content-between detail-page">
                          <label className="">Stock</label>

                          <label>{formData.stock === 1 ? 'In Stock' : 'Out of Stock'}</label>
                        </div>
                        <div className="d-flex justify-content-between detail-page">
                          <label className="">Category Name</label>
                          <label>{formData.category_name}</label>
                        </div>
                        <div className="d-flex justify-content-between detail-page">
                          <label className="">Sub Category Name</label>
                          <label>{formData.sub_category_name}</label>
                        </div>
                        <br></br>
                        <br></br>
                        <br></br>
                        <div className="d-flex justify-content-between detail-page">
                          <label className="">Created At</label>
                          <label>{formData.created_at}</label>
                        </div>
                        <div className="d-flex justify-content-between detail-page my-3">
                        <button
                          type="button"
                          className="btn btn-fill-out btn-block hover-up me-2 btn-secondary"
                          onClick={()=>handleDeals(formData.product_id)}
                        >
                          Add To Deals of the Day
                        </button>
                        </div>
                        <div className="d-flex justify-content-between detail-page my-3">
                        <button
                          type="button"
                          className="btn btn-fill-out btn-block hover-up me-2 btn-secondary"
                          onClick={()=>handlePopular(formData.product_id)}
                        >
                          Add To Popular Products
                        </button>
                        </div>
                        <div className="d-flex justify-content-between detail-page my-3">
                        <button
                          type="button"
                          className="btn btn-fill-out btn-block hover-up me-2 btn-secondary"
                          onClick={()=>handleTopRated(formData.product_id)}
                        >
                          Add To Top Rated
                        </button>
                        </div>
                        <div className="d-flex justify-content-between detail-page my-3">
                        <button
                          type="button"
                          className="btn btn-fill-out btn-block hover-up me-2 btn-secondary"
                          onClick={()=>handleRecentlyAdded(formData.product_id)}
                        >
                          Add To Recently Added
                        </button>
                        </div>
                        <div className="d-flex justify-content-between detail-page my-3">
                        <button
                          type="button"
                          className="btn btn-fill-out btn-block hover-up me-2 btn-secondary"
                          onClick={()=>handleTopSelling(formData.product_id)}
                        >
                          Add To Top Selling
                        </button>
                        </div>
                        <div className="d-flex justify-content-between detail-page my-3">
                        <button
                          type="button"
                          className="btn btn-fill-out btn-block hover-up me-2 btn-secondary"
                          onClick={()=>handleTrending(formData.product_id)}
                        >
                          Add To Trending Products
                        </button>
                        </div>
                        <div className="d-flex justify-content-between detail-page my-3">
                        <button
                          type="button"
                          className="btn btn-fill-out btn-block hover-up me-2 btn-secondary"
                          onClick={()=>handleKeralaGem(formData.product_id)}
                        >
                          Add To Kerala Gems
                        </button>
                        </div>
                        <div className="d-flex justify-content-between detail-page my-3">
                        <button
                          type="button"
                          className="btn btn-fill-out btn-block hover-up me-2 btn-secondary"
                          onClick={()=>handleTodaysFeatured(formData.product_id)}
                        >
                          Add To Featured Finds
                        </button>
                        </div>
                        <div className="d-flex justify-content-between detail-page my-3">
                        <button
                          type="button"
                          className="btn btn-fill-out btn-block hover-up me-2 btn-secondary"
                          onClick={()=>handleAyurvedicDelight(formData.product_id)}
                        >
                          Add To Ayurvedic Delight
                        </button>
                        </div>
                      </div>

                      <div className="col-lg-9">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Description
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                name="company_address"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.description}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">MRP</label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                name="company_address"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.mrp}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Sale Price
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.sale_price}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Seller Price{" "}
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.seller_price}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Benefits{" "}
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.benefits}
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Highlights
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.highlights}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                FAQ's
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.faq}
                                placeholder="FAQ's"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">Brand</label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.brand}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">Tax</label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.tax}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">Material & Color</label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.material}
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">Ingredients</label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.ingredients}
                                placeholder="Ingredients"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">Dimension</label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.dimensions}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                SKU Number
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.sku_number}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                UPC Number
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.upc_number}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                EAN Number
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.ean_no}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                GTIN Number
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.gtin_no}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                MPN Number
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.mpn}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Model Number
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.model_number}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Manufacturer Name
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.manufacturer_name}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Manufacturer Warranty Information
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={
                                  formData.manufacturer_warranty_information
                                }
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Export Status
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.export}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Export Countries
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.export_countries}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">Video</label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.video}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">Keywords</label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.keywords}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Minimum Order Quandity
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.minimum_order_quantity}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Maximum Order Quandity
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.maximum_order_quantity}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Availability Date
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.availability_date}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Shipping Weight
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.shipping_weight}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Inventory Status
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.inventory_status}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Shipping Information
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.shipping_information}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Shipping Charge
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.shipping_charge}
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Return Policy
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.return_policy}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Handling Time
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.handling_time}
                                // placeholder="Handling Time"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                              Usages
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={
                                  formData.usages
                                }
                                // placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                              Expected Results
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={
                                  formData.exp_result
                                }
                                // placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                              Safety and Precautions
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={
                                  formData.safety
                                }
                                // placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Product Certificate Information
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={
                                  formData.product_certification_information
                                }
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                HSN Number
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                disabled
                                value={formData.hsn_number}
                                // placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Seo Title</label>{" "}
                          <input
                            className="form-control"
                            name="seo"
                            id="txt-name"
                            type="text"
                            value={formData.seo || ""}
                            disabled
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                seo: e.target.value,
                              })
                            }
                            placeholder="Seo Title"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Seo Meta keywords</label>{" "}
                          <input
                            className="form-control"
                            name="meta_keyword"
                            id="txt-name"
                            type="text"
                            value={formData.meta_keyword || ""}
                            disabled
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                meta_keyword: e.target.value,
                              })
                            }
                            placeholder="Seo Meta keywords"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Seo Meta Description</label>{" "}
                          <input
                            className="form-control"
                            name="meta_description"
                            id="txt-name"
                            type="text"
                            value={formData.meta_description || ""}
                            disabled
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                meta_description: e.target.value,
                              })
                            }
                            placeholder="Seo Meta Description"
                          />
                        </div>
                      </div>
                          <div className="row">
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label htmlFor="company_address">
                                  Thumbnail 1 
                                </label>
                                <br></br>
                                <button
                                  type="button"
                                  className="btn btn-fill-out btn-block hover-up me-2 btn-secondary"
                                  onClick={handleIconClickThumbnail}
                                >
                                  View
                                </button>
                              </div>{formData?.thumbnail ? formData?.thumbnail : 'not found'}
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label htmlFor="company_address">
                                  Thumbnail 2
                                </label>
                                <br></br>
                                <button
                                  type="button"
                                  className="btn btn-fill-out btn-block hover-up me-2 btn-secondary"
                                  onClick={handleIconClickThumbnail2}
                                >
                                  View
                                </button>
                              </div>
                              {formData?.thumbnail2 ? formData?.thumbnail2 : 'not found'}
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label htmlFor="company_address">Images</label>
                                <br></br>
                                <button
                                  type="button"
                                  className="btn btn-fill-out btn-block hover-up me-2 btn-secondary"
                                  onClick={handleIconClickImages}
                                >
                                  View
                                </button>
                              </div>
                              {formData.images ? formData.images : 'not found'}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <br></br>
              </div>
            </div>
          )}
          {view === 2 && (
            <div className="col-lg-10">
              <div className="login_wrap widget-taber-content background-white">
                <div className="padding_eight_all bg-white">
                  <div className="padding_eight_all bg-white">
                    <div className="d-flex justify-content-between mb-20 mt-20">
                      <h3 className="">
                        {formData.product_name} : EDIT PAGE
                      </h3>
                      <div>
                        <button
                          type="button"
                          className="btn btn-fill-out btn-block hover-up me-2 btn-secondary"
                          onClick={handleEditBack}
                        >
                          Back
                        </button>
                        {/* {formData.status === 0 ? (
                          <>
                            <button
                              type="button"
                              className="btn btn-fill-out btn-block hover-up me-2"
                              //   onClick={handleSubmit}
                            >
                              Accept
                            </button>
                            <button
                              type="button"
                              className="btn btn-fill-out btn-block hover-up btn-danger me-2"
                              //   onClick={handleSubmit}
                            >
                              Reject
                            </button>
                          </>
                        ) : formData.status === 1 ? (
                          <>
                            <a href="/dash-vendor-add">
                              <button
                                type="button"
                                className="btn btn-fill-out btn-block hover-up"
                                //   onClick={handleSubmit}
                              >
                                Edit
                              </button>
                            </a>
                            <button
                              type="button"
                              className="btn btn-fill-out btn-block hover-up btn-warning"
                              //   onClick={handleSubmit}
                            >
                              Delete
                            </button>
                          </>
                        ) : (
                          ""
                        )} */}
                      </div>
                    </div>

                    <br></br>
                    <div className="row">
                      <div className="col-lg-3 border border-right border-light p-4 rounded-3 ">
                        <div
                          className="mx-auto rounded-circle mb-3 fw-bolder"
                          style={{
                            backgroundImage: `url(${producturl}${formData?.thumbnail})`,
                            borderRadius: "50%",
                            border: "2px solid red",
                            width: "150px",
                            height: "150px",
                            fontSize: "55px",
                            color: "#fff",
                          }}
                        >
                          {/* {formData.vendor_name.charAt(0)} */}
                          
                        </div>
                        <div className="d-flex justify-content-between detail-page">
                          <label className="">Product Code</label>
                          <label>{formData.product_code}</label>
                        </div>
                        <div className="d-flex justify-content-between detail-page">
                          <label className="">Product Name</label>
                          <label>{formData.product_name}</label>
                        </div>
                        <div className="d-flex justify-content-between detail-page">
                          <label className="">Vendor Name</label>
                          <label>{formData.vendor_name}</label>
                        </div>
                        <div className="d-flex justify-content-between detail-page">
                          <label className="">Stock</label>

                          <label>{formData.stock === 1 ? 'In Stock' : 'Out f Stock'}</label>
                        </div>
                        <div className="d-flex justify-content-between detail-page">
                          <label className="">Category Name</label>
                          <label>{formData.category_name}</label>
                        </div>
                        <div className="d-flex justify-content-between detail-page">
                          <label className="">Sub Category Name</label>
                          <label>{formData.sub_category_name}</label>
                        </div>
                        <br></br>
                        <br></br>
                        <br></br>
                        <div className="d-flex justify-content-between detail-page">
                          <label className="">Created At</label>
                          <label>{formData.created_at}</label>
                        </div>
                        <div className="d-flex justify-content-between detail-page">
                          <label className="">Updated At</label>
                          <label>{formData.updated_at}</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="row">
                        <div className="col-lg-12">
                        <div className="form-group">
                          <label htmlFor="name">Description</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <CKEditor
                            editor={ClassicEditor}
                            data={formData.description || ""}
                            onInit={editor => {
                              // You can store the "editor" and use when it is needed.
                              // console.log('Editor is ready to use!', editor);
                            }}

                            onChange={(event, editor) => {
                              const data = editor.getData();
                              console.log({ event, editor, data });
                              setFormData({
                                ...formData,
                                description: data,
                              })
                            }}
                            onBlur={(event, editor) => {
                              console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor, data) => {
                              console.log('Focus.', editor);
                            }}/>
                        </div>
                      </div>
                      <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="product_name">Product Name</label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                name="product_name"
                                id="txt-name"
                                type="text"
                                value={formData.product_name || ""}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    product_name: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Category Name</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <select 
                            className="form-control"
                            name="category_name"
                            id="txt-name"
                            // required
                            value={formData.category_id || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                category_id: e.target.value,
                              })
                            }
                          >
                            <option>- select -</option>
                            {dataCat.map((val) => {
                              return (
                                <option value={val.category_id}>
                                  {val.category_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Sub Category Name</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <select
                            className="form-control"
                            name="sub_category_id"
                            value={formData.sub_category_id || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                sub_category_id: e.target.value,
                              })
                            }
                          >
                            <option>- select -</option>
                            {subData.map((val) => {
                              return (
                                <option value={val.sub_category_id}>
                                  {val.sub_category_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">MRP</label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                name="company_address"
                                id="txt-name"
                                type="text"
                                value={formData.mrp || ""}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    mrp: e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Sale Price
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.sale_price || ""}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    sale_price: e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Seller Price{" "}
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.seller_price || ""}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    seller_price: e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Stock</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <select 
                            className="form-control"
                            name="category_name"
                            id="txt-name"
                            // required
                            value={formData.stock || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                stock: e.target.value,
                              })
                            }
                          >
                            <option>- select -</option>
                                <option value={0}>
                                  Out of Stock
                                </option>
                                <option value={1}>
                                  In Stock
                                </option>
                          </select>
                        </div>
                      </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Benefits{" "}
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.benefits || ""}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    benefits: e.target.value,
                                  });
                                }}
                                placeholder="Benefits"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">Brand</label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.brand || ""}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    brand: e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-12">
                        <div className="form-group">
                          <label htmlFor="name">Highlights</label>{" "}
                          <CKEditor
                            editor={ClassicEditor}
                            data={formData.highlights || ""}
                            onInit={editor => {
                              // You can store the "editor" and use when it is needed.
                              // console.log('Editor is ready to use!', editor);
                            }}

                            onChange={(event, editor) => {
                              const data = editor.getData();
                              console.log({ event, editor, data });
                              setFormData({
                                ...formData,
                                highlights: data,
                              })
                            }}
                            onBlur={(event, editor) => {
                              console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor, data) => {
                              console.log('Focus.', editor);
                            }}/>
                          {/* <input
                            className="form-control"
                            name="highlights"
                            id="txt-name"
                            type="text"
                            value={formData.highlights || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                highlights: e.target.value,
                              })
                            }
                            placeholder="Highlights"
                          /> */}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label htmlFor="name">FAQ's</label>{" "}
                          <CKEditor
                            editor={ClassicEditor}
                            data={formData.faq || ""}
                            onInit={editor => {
                              // You can store the "editor" and use when it is needed.
                              // console.log('Editor is ready to use!', editor);
                            }}

                            onChange={(event, editor) => {
                              const data = editor.getData();
                              console.log({ event, editor, data });
                              setFormData({
                                ...formData,
                                faq: data,
                              })
                            }}
                            onBlur={(event, editor) => {
                              console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor, data) => {
                              console.log('Focus.', editor);
                            }}/>
                          {/* <input
                            className="form-control"
                            name="highlights"
                            id="txt-name"
                            type="text"
                            value={formData.highlights || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                highlights: e.target.value,
                              })
                            }
                            placeholder="Highlights"
                          /> */}
                        </div>
                      </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">Tax</label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.tax || ""}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    tax: e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Material and Color</label>{" "}
                          <input
                            className="form-control"
                            name="material"
                            id="txt-name"
                            type="text"
                            value={formData.material || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                material: e.target.value,
                              })
                            }
                            placeholder="Material and Color"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                      <div className="form-group">
                          <label htmlFor="name">Ingredients</label>{" "}
                          <input
                            className="form-control"
                            name="ingredients"
                            id="txt-name"
                            type="text"
                            value={formData.ingredients || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                ingredients: e.target.value,
                              })
                            }
                            placeholder="Ingredients"
                          />
                        </div>
                      </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">Dimension</label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.dimensions || ""}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    dimensions: e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                SKU Number
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.sku_number || ""}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    sku_number: e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                UPC Number
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.upc_number || ""}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    upc_number: e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                EAN Number
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.ean_no || ""}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    ean_no: e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                GTIN Number
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.gtin_no || ""}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    gtin_no: e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                MPN Number
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.mpn || ""}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    mpn: e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Model Number
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.model_number || ""}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    model_number: e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Manufacturer Name
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.manufacturer_name || ""}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    manufacturer_name: e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Manufacturer Warranty Information
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={
                                  formData.manufacturer_warranty_information ||
                                  ""
                                }
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    manufacturer_warranty_information:
                                      e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Export Status
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.export || ""}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    export: e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Export Countries
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.export_countries || ""}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    export_countries: e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">Video</label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.video || ""}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    video: e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">Keywords</label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.keywords || ""}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    keywords: e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Minimum Order Quandity
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.minimum_order_quantity || ""}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    minimum_order_quantity: e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Maximum Order Quandity
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.maximum_order_quantity || ""}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    maximum_order_quantity: e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Availability Date
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.availability_date || ""}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    availability_date: e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Shipping Weight
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.shipping_weight || ""}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    shipping_weight: e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Inventory Status
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.inventory_status || ""}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    inventory_status: e.tanrget.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Shipping Information
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.shipping_information || ""}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    shipping_information: e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Shipping Charge
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.shipping_charge || ""}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    shipping_charge: e.target.value.replace(/[^0-9]/g, ""),
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Return Policy
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.return_policy || ""}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    return_policy: e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Handling Time
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.handling_time || ""}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    handling_time: e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">
                            Usages
                          </label>{" "}
                          <input
                            className="form-control"
                            name="product_certification_information"
                            id="txt-name"
                            type="text"
                            value={
                              formData.usages || ""
                            }
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                usages:
                                  e.target.value,
                              })
                            }
                            placeholder="Usages"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Expected Results</label>{" "}
                          <input
                            className="form-control"
                            name="exp_result"
                            id="txt-name"
                            type="text"
                            value={formData.exp_result || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                exp_result: e.target.value,
                              })
                            }
                            placeholder="Expected Results"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Safety and Precautions</label>{" "}
                          <input
                            className="form-control"
                            name="safety"
                            id="txt-name"
                            type="text"
                            value={formData.safety || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                safety: e.target.value,
                              })
                            }
                            placeholder="Safety and Precautions"
                          />
                        </div>
                      </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                Product Certificate Information
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={
                                  formData.product_certification_information ||
                                  ""
                                }
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    product_certification_information:
                                      e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="company_address">
                                HSN Number
                              </label>
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>
                              <input
                                className="form-control"
                                id="txt-name"
                                type="text"
                                value={formData.hsn_number || ""}
                                onChange={(e) => {
                                  // Assuming you are using some kind of state to manage the form data
                                  setFormData({
                                    ...formData,
                                    hsn_number: e.target.value,
                                  });
                                }}
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Seo Title</label>{" "}
                          <input
                            className="form-control"
                            name="seo"
                            id="txt-name"
                            type="text"
                            value={formData.seo || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                seo: e.target.value,
                              })
                            }
                            placeholder="Seo Title"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Seo Meta keywords</label>{" "}
                          <input
                            className="form-control"
                            name="meta_keyword"
                            id="txt-name"
                            type="text"
                            value={formData.meta_keyword || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                meta_keyword: e.target.value,
                              })
                            }
                            placeholder="Seo Meta keywords"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Seo Meta Description</label>{" "}
                          <input
                            className="form-control"
                            name="meta_description"
                            id="txt-name"
                            type="text"
                            value={formData.meta_description || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                meta_description: e.target.value,
                              })
                            }
                            placeholder="Seo Meta Description"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Select Group Name</label>{" "}
                          <select
                            className="form-control"
                            name="group_id"
                            value={formData.group_id || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                group_id: e.target.value,
                              })
                            }
                          >
                            <option>- select -</option>
                            {groupData.map((val) => {
                              return (
                                <option value={val.group_id}>
                                  {val.group_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Similar Product Name</label>{" "}
                          <input
                            className="form-control"
                            name="similar_product_name"
                            id="txt-name"
                            type="text"
                            value={formData.similar_product_name || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                similar_product_name: e.target.value,
                              })
                            }
                            placeholder="Similar Product Name"
                          />
                        </div>
                      </div>
                          <div className="row">
                          <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Thumbnail</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="thumbnail"
                            id="txt-name"
                            type="file"
                            // required
                            // value={formData.thumbnail || ""}
                            onChange={(event) => {
                              handlefileupload(event);
                            }}
                            placeholder="Thumbnail"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Thumbnail 2</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="thumbnail 2"
                            id="txt-name"
                            type="file"
                            // required
                            // value={formData.thumbnail || ""}
                            onChange={(event) => {
                              handlefileuploadThumbnail2(event);
                            }}
                            placeholder="Thumbnail 2"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Images</label>{" "}
                          <input
                            className="form-control"
                            name="images"
                            id="txt-name"
                            type="file"
                            multiple
                            onChange={(event) => {
                              handlemultiplefileupload(event);
                            }}
                            placeholder="Images"
                          />
                        </div>
                      </div>
                            <div className="form-group">
                              <button
                                type="button"
                                className="btn btn-fill-out btn-block hover-up"
                                onClick={() => handleSubmit(formData.product_id)}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <br></br>
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
export default DashboardProductMain;
