import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { backend } from "../../../../path";
import DashboardSidebarMain from "../../dashsidebar";
import { useNavigate } from "react-router-dom";
import HeaderDashMain from "../../../layout/header/dash header";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function DashboardProductList() {
  const [formData, setFormData] = useState([]);
  const [galary, setGalary] = useState("");
  const [data, setData] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [count, setCountry] = useState([]);
  const [subdata, setSubData] = useState([]);
  const [groupData, setGroupData] = useState([]);

  const navigate = useNavigate();

  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );

  // fetch vendor

  const fetchVendor = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getvendor", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setVendor(data);
        console.log(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchGroupData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getallgroups", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authenticated?.access_token}`
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setGroupData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchGroupData();
    fetchVendor();
  }, []);

  const handlefileupload = (e) => {
    let formdata = new FormData();
    formdata.append("document", e.target.files[0]);

    fetch(backend + "fileuploadthumbnail1", {
      method: "POST",
      body: formdata,
    })
      .then((response) => response.json())
      .then((data) => {
        setFormData({
          ...formData,
          thumbnail: data,
        });
        toast.success("Thumbnail 1 Added", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        console.error("Error during file upload:", error);
      });
  };
  const handlefileuploadThumbnail2 = (e) => {
    let formdata = new FormData();
    formdata.append("document", e.target.files[0]);

    fetch(backend + "fileuploadthumbnail2", {
      method: "POST",
      body: formdata,
    })
      .then((response) => response.json())
      .then((data) => {
        setFormData({
          ...formData,
          thumbnail2: data,
        });
        toast.success("Thumbnail 2 Added", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        console.error("Error during file upload:", error);
      });
  };

  const handleSubmit = (id) => {
    formData.status = 1;
    formData.product_id = id;
    formData.images = galary;
  
    fetch(backend + "addproduct", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );
  
          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
  
          return;
        }
  
        response.json().then(() => {
          toast.success("Product Added Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => {
              setTimeout(() => {
                navigate("/dash-product");
              }, 1000); // Adjust the delay time as needed
            }
          });
        });
      })
      .catch((error) => {
        toast.error("Something Went Wrong", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  
  const handlemultiplefileupload = (event, state) => {
    let formdata = new FormData();

    var $files = event.target.files;

    // console.log($files)

    Array.from(event.target.files).forEach((element, index) => {
      formdata.append(`documents_${index}`, element);
    });
    formdata.append("length", $files.length);

    // formdata.append('documents',event.target.files)

    fetch(backend + "multiplefileupload", {
      method: "POST",
      body: formdata,
    }).then((response) => {
      response.json().then((data) => {
        setGalary(JSON.stringify(data));
        toast.success("Images Added", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      });

    });
  };

  const fetchCatData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getcategory", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        console.log(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchCountryData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getcountry", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setCountry(data);
        console.log(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchSubData = () => {
    setLoading(true);

    fetch(backend + "getsubcategory", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setSubData(data);
        console.log(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCatData();
    fetchSubData();
    fetchCountryData();
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-2 d-none d-lg-flex">
            <DashboardSidebarMain />
          </div>
          <div className="col-lg-10">
            <div className="login_wrap widget-taber-content background-white">
              <div className="padding_eight_all bg-white">
                <div className="padding_eight_all bg-white">
                  <div className="d-flex justify-content-between mb-20 mt-20">
                    <h3 className="">Add Product</h3>
                    <a href="/dash-product">
                      <button
                        type="button"
                        className="btn btn-fill-out btn-block hover-up"
                      >
                        Back
                      </button>
                    </a>
                  </div>
                  <br></br>
                </div>
                <form>
                  <div className="form__content">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Product Name</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="product_name"
                            id="txt-name"
                            type="text"
                            // required
                            value={formData.product_name || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                product_name: e.target.value,
                              })
                            }
                            placeholder="Product Name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Category Name</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <select 
                            className="form-control"
                            name="category_name"
                            id="txt-name"
                            // required
                            value={formData.category_id || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                category_id: e.target.value,
                              })
                            }
                          >
                            <option>- select -</option>
                            {data.map((val) => {
                              return (
                                <option value={val.category_id}>
                                  {val.category_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Sub Category Name</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <select
                            className="form-control"
                            name="sub_category_id"
                            value={formData.sub_category_id || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                sub_category_id: e.target.value,
                              })
                            }
                          >
                            <option>- select -</option>
                            {subdata.map((val) => {
                              return (
                                <option value={val.sub_category_id}>
                                  {val.sub_category_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Vendor Name</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <select
                            className="form-control"
                            name="vendor"
                            value={formData.vendor || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                vendor: e.target.value,
                              })
                            }
                          >
                            <option>- select -</option>
                            {vendor.map((val) => {
                              return (
                                <option value={val.user_id}>
                                  {val.vendor_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">MRP</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="mrp"
                            id="txt-name"
                            type="text"
                            // required
                            value={formData.mrp || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                mrp: e.target.value.replace(/[^0-9]/g, ""),
                              })
                            }
                            placeholder="MRP"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Sale Price</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="sale_price"
                            id="txt-name"
                            type="text"
                            // required
                            value={formData.sale_price || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                sale_price: e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                ),
                              })
                            }
                            placeholder="Sale Price"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="company_address">Seller Price </label>
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            id="txt-name"
                            type="text"
                            value={formData.seller_price || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                seller_price: e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                ),
                              })
                            }
                            placeholder="Seller Price"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="company_address">Benefits </label>
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            id="txt-name"
                            type="text"
                            value={formData.benefits || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                benefits: e.target.value,
                              })
                            }
                            placeholder="Benefits"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label htmlFor="name">Description</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <CKEditor
                            editor={ClassicEditor}
                            data={formData.description || ""}
                            onInit={editor => {
                              // You can store the "editor" and use when it is needed.
                              // console.log('Editor is ready to use!', editor);
                            }}

                            onChange={(event, editor) => {
                              const data = editor.getData();
                              console.log({ event, editor, data });
                              setFormData({
                                ...formData,
                                description: data,
                              })
                            }}
                            onBlur={(event, editor) => {
                              console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor, data) => {
                              console.log('Focus.', editor);
                            }}/>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label htmlFor="name">Highlights</label>{" "}
                          <CKEditor
                            editor={ClassicEditor}
                            data={formData.highlights || ""}
                            onInit={editor => {
                              // You can store the "editor" and use when it is needed.
                              // console.log('Editor is ready to use!', editor);
                            }}

                            onChange={(event, editor) => {
                              const data = editor.getData();
                              console.log({ event, editor, data });
                              setFormData({
                                ...formData,
                                highlights: data,
                              })
                            }}
                            onBlur={(event, editor) => {
                              console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor, data) => {
                              console.log('Focus.', editor);
                            }}/>
                          {/* <input
                            className="form-control"
                            name="highlights"
                            id="txt-name"
                            type="text"
                            value={formData.highlights || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                highlights: e.target.value,
                              })
                            }
                            placeholder="Highlights"
                          /> */}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label htmlFor="name">FAQ's</label>{" "}
                          <CKEditor
                            editor={ClassicEditor}
                            data={formData.faq || ""}
                            onInit={editor => {
                              // You can store the "editor" and use when it is needed.
                              // console.log('Editor is ready to use!', editor);
                            }}

                            onChange={(event, editor) => {
                              const data = editor.getData();
                              console.log({ event, editor, data });
                              setFormData({
                                ...formData,
                                faq: data,
                              })
                            }}
                            onBlur={(event, editor) => {
                              console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor, data) => {
                              console.log('Focus.', editor);
                            }}/>
                          {/* <input
                            className="form-control"
                            name="highlights"
                            id="txt-name"
                            type="text"
                            value={formData.highlights || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                highlights: e.target.value,
                              })
                            }
                            placeholder="Highlights"
                          /> */}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Thumbnail</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="thumbnail"
                            id="txt-name"
                            type="file"
                            // required
                            // value={formData.thumbnail || ""}
                            onChange={(event) => {
                              handlefileupload(event);
                            }}
                            placeholder="Thumbnail"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Thumbnail 2</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="thumbnail 2"
                            id="txt-name"
                            type="file"
                            // required
                            // value={formData.thumbnail || ""}
                            onChange={(event) => {
                              handlefileuploadThumbnail2(event);
                            }}
                            placeholder="Thumbnail 2"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Images</label>{" "}
                          <input
                            className="form-control"
                            name="images"
                            id="txt-name"
                            type="file"
                            multiple
                            onChange={(event) => {
                              handlemultiplefileupload(event);
                            }}
                            placeholder="Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Stock</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <select 
                            className="form-control"
                            name="category_name"
                            id="txt-name"
                            // required
                            value={formData.stock || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                stock: e.target.value,
                              })
                            }
                          >
                            <option>- select -</option>
                                <option value={0}>
                                  Out of Stock
                                </option>
                                <option value={1}>
                                  In Stock
                                </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Brand</label>{" "}
                          <input
                            className="form-control"
                            name="brand"
                            id="txt-name"
                            type="text"
                            value={formData.brand || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                brand: e.target.value,
                              })
                            }
                            placeholder="Brand"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                      <div className="form-group">
                          <label htmlFor="name">Tax</label>{" "}
                          <input
                            className="form-control"
                            name="tax"
                            id="txt-name"
                            type="text"
                            value={formData.tax || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                tax: e.target.value,
                              })
                            }
                            placeholder="Tax"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Material and Color</label>{" "}
                          <input
                            className="form-control"
                            name="material"
                            id="txt-name"
                            type="text"
                            value={formData.material || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                material: e.target.value,
                              })
                            }
                            placeholder="Material and Color"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                      <div className="form-group">
                          <label htmlFor="name">Ingredients</label>{" "}
                          <input
                            className="form-control"
                            name="ingredients"
                            id="txt-name"
                            type="text"
                            value={formData.ingredients || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                ingredients: e.target.value,
                              })
                            }
                            placeholder="Ingredients"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Dimension</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="dimensions"
                            id="txt-name"
                            type="text"
                            // required
                            value={formData.dimensions || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                dimensions: e.target.value,
                              })
                            }
                            placeholder="Dimension (l*b*h)"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">SKU Number</label>{" "}
                          <input
                            className="form-control"
                            name="sku_number"
                            id="txt-name"
                            type="text"
                            // required
                            value={formData.sku_number || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                sku_number: e.target.value,
                              })
                            }
                            placeholder="SKU Number"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">UPC Number</label>{" "}
                          <input
                            className="form-control"
                            name="upc_number"
                            id="txt-name"
                            type="text"
                            value={formData.upc_number || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                upc_number: e.target.value,
                              })
                            }
                            placeholder="UPC Number"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">EAN Number</label>{" "}
                          <input
                            className="form-control"
                            name="ean_no"
                            id="txt-name"
                            type="text"
                            value={formData.ean_no || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                ean_no: e.target.value,
                              })
                            }
                            placeholder="EAN Number"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">GTIN Number</label>{" "}
                          <input
                            className="form-control"
                            name="gtin_no"
                            id="txt-name"
                            type="text"
                            value={formData.gtin_no || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                gtin_no: e.target.value,
                              })
                            }
                            placeholder="GTIN Number"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">MPN</label>{" "}
                          <input
                            className="form-control"
                            name="mpn"
                            id="txt-name"
                            type="text"
                            value={formData.mpn || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                mpn: e.target.value,
                              })
                            }
                            placeholder="MPN"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Model Number</label>{" "}
                          <input
                            className="form-control"
                            name="model_number"
                            id="txt-name"
                            type="text"
                            value={formData.model_number || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                model_number: e.target.value,
                              })
                            }
                            placeholder="Model Number"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Manufacturer Name</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="manufacturer_name"
                            id="txt-name"
                            type="text"
                            // required
                            value={formData.manufacturer_name || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                manufacturer_name: e.target.value,
                              })
                            }
                            placeholder="Manufacturer Name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">
                            Manufacturer Warranty Information
                          </label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="manufacturer_warranty_information"
                            id="txt-name"
                            type="text"
                            // required
                            value={
                              formData.manufacturer_warranty_information || ""
                            }
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                manufacturer_warranty_information:
                                  e.target.value,
                              })
                            }
                            placeholder="Manufacturer Warranty Information"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 d-flex justify-content-between">
                        <div className="form-group">
                          <label htmlFor="name">Export Intended ?</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <br></br>
                          Yes
                          <br></br>
                          No
                        </div>
                        <div
                        // className="form-group"
                        >
                          <br></br>

                          <input
                            // className="form-control"
                            name="export"
                            id="txt-name"
                            type="radio"
                            // required
                            checked={formData.export === "1"}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                export: "1",
                              })
                            }
                          />
                          <input
                            className="mt-3"
                            name="export"
                            id="txt-name"
                            type="radio"
                            // required
                            checked={formData.export === "0"}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                export: "0",
                              })
                            }
                          />
                        </div>
                      </div>

                      {formData.export === "1" ? (
                        <div className="form-group">
                          <label htmlFor="name">Export Countries</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="export_countries"
                            id="txt-name"
                            type="text"
                            // required
                            value={formData.export_countries || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                export_countries: e.target.value,
                              })
                            }
                            placeholder="Export Countries"
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Video</label>{" "}
                          <input
                            className="form-control"
                            name="video"
                            id="txt-name"
                            type="text"
                            value={formData.video || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                video: e.target.value,
                              })
                            }
                            placeholder="Video"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Keywords</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="keywords"
                            id="txt-name"
                            type="text"
                            // required
                            value={formData.keywords || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                keywords: e.target.value,
                              })
                            }
                            placeholder="Keywords"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Minimum Order Quandity</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="minimum_order_quantity"
                            id="txt-name"
                            type="text"
                            // required
                            value={formData.minimum_order_quantity || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                minimum_order_quantity: e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                ),
                              })
                            }
                            placeholder="Minimum Order Quandity"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Maximum Order Quandity</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="maximum_order_quantity"
                            id="txt-name"
                            type="text"
                            // required
                            value={formData.maximum_order_quantity || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                maximum_order_quantity: e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                ),
                              })
                            }
                            placeholder="Maximum Order Quandity"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Availability Date</label>{" "}
                          {/* <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup> */}
                          <input
                            className="form-control"
                            name="availability_date"
                            id="txt-name"
                            type="date"
                            // required
                            value={formData.availability_date || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                availability_date: e.target.value,
                              })
                            }
                            placeholder="Availability Date"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Shipping Weight</label>{" "}
                          <input
                            className="form-control"
                            name="shipping_weight"
                            id="txt-name"
                            type="text"
                            value={formData.shipping_weight || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                shipping_weight: e.target.value,
                              })
                            }
                            placeholder="Shipping Weight"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Inventory Status</label>{" "}
                          <input
                            className="form-control"
                            name="inventory_status"
                            id="txt-name"
                            type="text"
                            value={formData.inventory_status || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                inventory_status: e.target.value,
                              })
                            }
                            placeholder="Inventory Status"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Shipping Information</label>{" "}
                          <input
                            className="form-control"
                            name="shipping_information"
                            id="txt-name"
                            type="text"
                            value={formData.shipping_information || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                shipping_information: e.target.value,
                              })
                            }
                            placeholder="Shipping Information"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Shipping Charge</label>{" "}
                          <input
                            className="form-control"
                            name="shipping_charge"
                            id="txt-name"
                            type="text"
                            value={formData.shipping_charge || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                shipping_charge: e.target.value.replace(/[^0-9]/g, ""),
                              })
                            }
                            placeholder="Shipping Charge"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Return Policy</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="return_policy"
                            id="txt-name"
                            type="text"
                            // required
                            value={formData.return_policy || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                return_policy: e.target.value,
                              })
                            }
                            placeholder="Return Policy"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Handling Time</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="handling_time"
                            id="txt-name"
                            type="text"
                            // required
                            value={formData.handling_time || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                handling_time: e.target.value,
                              })
                            }
                            placeholder="Handling Time"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">
                            Usages
                          </label>{" "}
                          <input
                            className="form-control"
                            name="product_certification_information"
                            id="txt-name"
                            type="text"
                            value={
                              formData.usages || ""
                            }
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                usages:
                                  e.target.value,
                              })
                            }
                            placeholder="Usages"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Expected Results</label>{" "}
                          <input
                            className="form-control"
                            name="exp_result"
                            id="txt-name"
                            type="text"
                            value={formData.exp_result || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                exp_result: e.target.value,
                              })
                            }
                            placeholder="Expected Results"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Safety and Precautions</label>{" "}
                          <input
                            className="form-control"
                            name="safety"
                            id="txt-name"
                            type="text"
                            value={formData.safety || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                safety: e.target.value,
                              })
                            }
                            placeholder="Safety and Precautions"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">
                            Product Certificate Information
                          </label>{" "}
                          <input
                            className="form-control"
                            name="product_certification_information"
                            id="txt-name"
                            type="text"
                            value={
                              formData.product_certification_information || ""
                            }
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                product_certification_information:
                                  e.target.value,
                              })
                            }
                            placeholder="Product Certificate Information"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">HSN Number</label>{" "}
                          <input
                            className="form-control"
                            name="hsn_number"
                            id="txt-name"
                            type="text"
                            value={formData.hsn_number || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                hsn_number: e.target.value,
                              })
                            }
                            placeholder="HSN Number"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Seo Title</label>{" "}
                          <input
                            className="form-control"
                            name="seo"
                            id="txt-name"
                            type="text"
                            value={formData.seo || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                seo: e.target.value,
                              })
                            }
                            placeholder="Seo Title"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Seo Meta keywords</label>{" "}
                          <input
                            className="form-control"
                            name="meta_keyword"
                            id="txt-name"
                            type="text"
                            value={formData.meta_keyword || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                meta_keyword: e.target.value,
                              })
                            }
                            placeholder="Seo Meta keywords"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Seo Meta Description</label>{" "}
                          <input
                            className="form-control"
                            name="meta_description"
                            id="txt-name"
                            type="text"
                            value={formData.meta_description || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                meta_description: e.target.value,
                              })
                            }
                            placeholder="Seo Meta Description"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Select Group Name</label>{" "}
                          <select
                            className="form-control"
                            name="group_id"
                            value={formData.group_id || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                group_id: e.target.value,
                              })
                            }
                          >
                            <option>- select -</option>
                            {groupData.map((val) => {
                              return (
                                <option value={val.group_id}>
                                  {val.group_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name">Similar Product Name</label>{" "}
                          <input
                            className="form-control"
                            name="similar_product_name"
                            id="txt-name"
                            type="text"
                            value={formData.similar_product_name || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                similar_product_name: e.target.value,
                              })
                            }
                            placeholder="Similar Product Name"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-fill-out btn-block hover-up"
                  onClick={()=>handleSubmit(formData.product_id)}
                >
                  Submit
                </button>
              </div>
              <br></br>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
export default DashboardProductList;
