import React, { useState, useEffect } from "react";
import "../../../../App.css";

function LoaderMain() {
  return (
    <div className="preloader loading" id="preloader-active">
      <div className="preloader-loading"></div>
    </div>
  );
}

export default LoaderMain;

