import React, { useEffect, useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { backend, producturl } from "../../../../path";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../layout/header/loader";
import { ToastContainer, toast } from "react-toastify";
import LoaderMain from "../../../layout/header/loader_sub";
import ScrollToTop from "../../../components/whatsapp connect";
import { Rating } from "@mui/material";
import { Helmet } from "react-helmet";

function SubCategoryListingMain(props) {
  const navigate = useNavigate();
  const [isFilter, setIsFilter] = useState(false);
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [data, setData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );

  const { id } = useParams();

  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 12;

  const handleAddToWishList = async (id) => {
    if (authenticated === null) {
      window.location.href = "/login";
      return;
    }

    const formData = {
      status: 0,
      product_id: id,
    };

    try {
      const response = await fetch(backend + "addtowishlistuserside", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        toast.success("Item added to wishlist", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.setWishChange(!props.wishChange)
        await new Promise((resolve) => setTimeout(resolve, 3000));
        // navigate("/cart");
      } else if (response.status === 409) {
        toast.error("Conflict occurred. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (response.status === 401) {
        toast.error("Item already exits in the wishlist", {

          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
      }
    } catch (error) {
    }
  };



  useEffect(() => {
    fetchData();
  }, [id, currentPage]);

  const fetchData = () => {
    setLoading(true);
    setError(null);

    const startIndex = (currentPage - 1) * productsPerPage;
    const endIndex = startIndex + productsPerPage;

    fetch(backend + `getproductbysubcategoryiduserside/${id}?min=${priceRange[0]}&max=${priceRange[1]}`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setFullData(data);
        // Assuming data is an array of products
        const products = data.slice(startIndex, endIndex);
        setData(products);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // Scroll to the top of the page whenever a new page is rendered
    window.scrollTo(0, 0);
  }, [fetchData]);

  const handleFilter = () => {
    setIsFilter((prevIsFilter) => !prevIsFilter);
    fetchData();
  };
  const handleProductRoute = (productId) => {
    navigate(`/product/${productId}`);
    // window.location.reload();
  };
  const [priceRange, setPriceRange] = useState([1, 50000]);

  const handlePriceChange = (value) => {
    setPriceRange(value);
  };


  const handleAddCart = async (id) => {
    if (authenticated === null) {
      window.location.href = "/login";
      return;
    }

    const formData = {
      status: 1,
      quantity: 1,
      product_id: id,
    };

    try {
      const response = await fetch(backend + "addtocartuserside", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        toast.success("Item added to cart", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.setCartChange(!props.cartChange)
        await new Promise((resolve) => setTimeout(resolve, 3000));
        // navigate("/cart");
      } else if (response.status === 409) {
        toast.error("Conflict occurred. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (response.status === 401) {
        toast.error("Item already exits in the cart", {

          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
      }
    } catch (error) {
    }
  };
  const decodeHTML = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  return (
    <>
      <ToastContainer />
      <Helmet><title>{data[0]?.sub_category_seo}</title>
        <meta name="description" content={data[0]?.sub_category_meta_description} />
        <meta name="keywords" content={data[0]?.sub_category_meta_keyword} />
      </Helmet>
      <div className="main" id="main-section">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <div className="breadcrumb-item d-inline-block">
                <Link to="/" title="Home">
                  Home
                </Link>
              </div>
              <span></span>
              <div className="breadcrumb-item d-inline-block">
                <Link to="/product-list" title="Products">
                  {data[0]?.category_name}
                </Link>
              </div>
              <span></span>
              <div className="breadcrumb-item d-inline-block active">
                <div itemProp="item">
                  {data[0]?.sub_category_name ? data[0]?.sub_category_name : "Sub Category"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mb-30">
          <div className="row">
            <div className="col-lg-12 m-auto mt-4 d-none d-md-block d-lg-block">
              <div className="d-flex justify-content-start gap-5">
                <Link className="shop-filter-toggle mb-0" onClick={handleFilter} style={{ height: '40px' }}>
                  <span className="fi-rs-filter mr-5"></span>
                  <span className="title">Filters</span>

                  <i className="fi-rs-angle-small-up angle-up"></i>
                  <i className="fi-rs-angle-small-down angle-down"></i>
                </Link>
                <div className="d-flex justify-content-center w-100">

                  {data[0]?.sub_category_description &&
                    <Link className="btn btn-lg mb-2 w-100"
                      style={{
                        backdropFilter: 'inherit',
                        backgroundBlendMode: 'color-burn',
                        backgroundColor: '#F4CE14',
                        fontWeight: 'bolder',
                        color: '#fff',
                        borderRadius: '20px',
                        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'
                      }}
                      dangerouslySetInnerHTML={{
                        __html: decodeHTML(data[0]?.sub_category_description),
                      }}
                    >
                    </Link>
                  }
                </div>
              </div>
              {isFilter === true && (
                <div>
                  <input
                    type="hidden"
                    name="layout"
                    value="product-full-width"
                  />
                  <div className="shop-product-filter-header my-3 page_speed_1723541923">
                    <div className="row">
                      <div className="col-lg-3 col-md-4 mb-lg-0 mb-md-5 mb-sm-5 widget-filter-item product-categories-filter-widget">
                        <h5
                          className="mb-20 widget__title"
                          data-title="Category"
                        >
                          By categories
                        </h5>
                        <div className="custome-checkbox ps-custom-scrollbar">
                          <ul className="ps-list--categories">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="categories"
                                value="1"
                                checked
                                id="category-filter-1"
                              />
                              <label
                                className="form-check-label"
                                for="category-filter-1"
                              >
                                {data[0]?.category_name}
                              </label>
                            </div>
                          </ul>
                        </div>
                      </div>
                      {/* <div className="col-lg-3 col-md-4 mb-lg-0 mb-md-5 mb-sm-5 widget-filter-item">
                        <h5 className="mb-20 widget__title" data-title="Tag">
                          By tags
                        </h5>
                        <div className="custome-checkbox ps-custom-scrollbar">
                          <input
                            className="form-check-input"
                            name="tags"
                            type="checkbox"
                            id="tag-filter-4"
                            value="4"
                          />
                          <label
                            className="form-check-label"
                            for="tag-filter-4"
                          >
                            <span className="d-inline-block">Clothes</span>
                            <span className="d-inline-block">(3)</span>
                          </label>
                          <br></br>
                          <input
                            className="form-check-input"
                            name="tags"
                            type="checkbox"
                            id="tag-filter-5"
                            value="5"
                          />
                          <label
                            className="form-check-label"
                            for="tag-filter-5"
                          >
                            <span className="d-inline-block">Hand bag</span>
                            <span className="d-inline-block">(2)</span>
                          </label>
                          <br></br>
                          <input
                            className="form-check-input"
                            name="tags"
                            type="checkbox"
                            id="tag-filter-1"
                            value="1"
                          />
                          <label
                            className="form-check-label"
                            for="tag-filter-1"
                          >
                            <span className="d-inline-block">Wallet</span>
                            <span className="d-inline-block">(1)</span>
                          </label>
                          <br></br>
                          <input
                            className="form-check-input"
                            name="tags"
                            type="checkbox"
                            id="tag-filter-3"
                            value="3"
                          />
                          <label
                            className="form-check-label"
                            for="tag-filter-3"
                          >
                            <span className="d-inline-block">Shoes</span>
                            <span className="d-inline-block">(1)</span>
                          </label>
                          <br></br>
                        </div>
                      </div> */}
                      <div
                        className="col-lg-3 col-md-4 mb-lg-0 mb-md-5 mb-sm-5 widget-filter-item"
                        data-type="price"
                      >
                        <h5 className="mb-20 widget__title" data-title="Price">
                          By Price
                        </h5>
                        <div className="price-filter range">
                          <div className="price-filter-inner">
                            <Slider
                              range
                              min={1}
                              max={50000}
                              value={priceRange}
                              onChange={handlePriceChange}
                            />
                            <input
                              type="hidden"
                              className="min_price min-range"
                              name="min_price"
                              value={priceRange[0]}
                              data-label="Min price"
                            />
                            <input
                              type="hidden"
                              className="min_price max-range"
                              name="max_price"
                              value={priceRange[1]}
                              data-label="Max price"
                            />
                            <div className="price_slider_amount">
                              <div className="label-input">
                                <span className="d-inline-block">Range:</span>
                                <span className="from d-inline-block">
                                  {priceRange[0]}
                                </span>
                                <span className="to d-inline-block">
                                  {priceRange[1]}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="widget">
                    <button
                      type="submit"
                      className="btn btn-sm btn-default"
                      onClick={handleFilter}
                    >
                      <i className="fi-rs-filter mr-5 ml-0"></i> Filter
                    </button>
                    {/* <Link className="clear_filter dib clear_all_filter mx-4 btn btn-danger btn-sm">
                      <i className="fi-rs-refresh mr-5 ml-0"></i> Clear All
                      Filters
                    </Link> */}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="mt-4">
            <div className="products-listing position-relative">
              <div className="list-content-loading">
                <div className="half-circle-spinner">
                  <div className="circle circle-1"></div>
                  <div className="circle circle-2"></div>
                </div>
              </div>
              <div className="shop-product-filter">
                <div className="total-product">
                  <p>
                    We found{" "}
                    <strong className="text-brand">{fullData?.length}</strong>{" "}
                    items for you!
                  </p>
                </div>
                {/* <div className="sort-by-product-area">
                  <div className="sort-by-cover mr-10 products_sortby">
                    <div className="sort-by-product-wrap">
                      <div className="sort-by">
                        <span>
                          <i className="fi-rs-apps"></i>Show:
                        </span>
                      </div>
                      <div className="sort-by-dropdown-wrap">
                        <span>
                          {" "}
                          12 <i className="fi-rs-angle-small-down"></i>
                        </span>
                      </div>
                    </div>
                    <div
                      className="sort-by-dropdown products_ajaxsortby"
                      data-name="num"
                    >
                      <ul>
                        <li>
                          <Link
                            data-label="12"
                            className=" active "
                            to="milks-and-dairiesb0c4.html?categories%5B0%5D=1&amp;num=12"
                          >
                            12
                          </Link>
                        </li>
                        <li>
                          <Link
                            data-label="24"
                            className=""
                            to="milks-and-dairies6f4e.html?categories%5B0%5D=1&amp;num=24"
                          >
                            24
                          </Link>
                        </li>
                        <li>
                          <Link
                            data-label="36"
                            className=""
                            to="milks-and-dairies3402.html?categories%5B0%5D=1&amp;num=36"
                          >
                            36
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="sort-by-cover products_sortby">
                    <div className="sort-by-product-wrap">
                      <div className="sort-by">
                        <span>
                          <i className="fi-rs-apps-sort"></i>Sort by:
                        </span>
                      </div>
                      <div className="sort-by-dropdown-wrap">
                        <span>
                          <span></span>
                          <i className="fi-rs-angle-small-down"></i>
                        </span>
                      </div>
                    </div>
                    <div
                      className="sort-by-dropdown products_ajaxsortby"
                      data-name="sort-by"
                    >
                      <ul>
                        <li>
                          <Link
                            data-label="Default"
                            className=""
                            to="milks-and-dairies45d2.html?categories%5B0%5D=1&amp;sort-by=default_sorting"
                          >
                            Default
                          </Link>
                        </li>
                        <li>
                          <Link
                            data-label="Oldest"
                            className=""
                            to="milks-and-dairies720f.html?categories%5B0%5D=1&amp;sort-by=date_asc"
                          >
                            Oldest
                          </Link>
                        </li>
                        <li>
                          <Link
                            data-label="Newest"
                            className=""
                            to="milks-and-dairiesa1f0.html?categories%5B0%5D=1&amp;sort-by=date_desc"
                          >
                            Newest
                          </Link>
                        </li>
                        <li>
                          <Link
                            data-label="Price: low to high"
                            className=""
                            to="milks-and-dairiesa1f7.html?categories%5B0%5D=1&amp;sort-by=price_asc"
                          >
                            Price: low to high
                          </Link>
                        </li>
                        <li>
                          <Link
                            data-label="Price: high to low"
                            className=""
                            to="milks-and-dairiesdf60.html?categories%5B0%5D=1&amp;sort-by=price_desc"
                          >
                            Price: high to low
                          </Link>
                        </li>
                        <li>
                          <Link
                            data-label="Name: A-Z"
                            className=""
                            to="milks-and-dairiesd663.html?categories%5B0%5D=1&amp;sort-by=name_asc"
                          >
                            Name: A-Z
                          </Link>
                        </li>
                        <li>
                          <Link
                            data-label="Name: Z-A"
                            className=""
                            to="milks-and-dairiesb8de.html?categories%5B0%5D=1&amp;sort-by=name_desc"
                          >
                            Name: Z-A
                          </Link>
                        </li>
                        <li>
                          <Link
                            data-label="Rating: low to high"
                            className=""
                            to="milks-and-dairiesf590.html?categories%5B0%5D=1&amp;sort-by=rating_asc"
                          >
                            Rating: low to high
                          </Link>
                        </li>
                        <li>
                          <Link
                            data-label="Rating: high to low"
                            className=""
                            to="milks-and-dairies752c.html?categories%5B0%5D=1&amp;sort-by=rating_desc"
                          >
                            Rating: high to low
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
              </div>
              <input type="hidden" name="page" data-value="1" />
              <input type="hidden" value="" />
              <input type="hidden" name="num" value="" />
              <input type="hidden" name="q" value="" />

              <div className="row product-grid">
                {loading && (
                  <div>
                    {/* Loading...  */}
                    <LoaderMain />
                  </div>
                )}

                {!loading && (
                  data.map((item, i) => (
                    <div
                      key={i}
                      className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-12 col-sm-6"
                    >
                      <div
                        className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                        data-wow-delay="0.1s"
                        style={{
                          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
                        }}
                      >
                        <div className="product-img-action-wrap">
                          <div className="product-img product-img-zoom">
                            <Link
                              to={`/product/${item.product_slug}`}
                              onClick={(e) => {
                                e.preventDefault();
                                handleProductRoute(item.product_slug);
                              }}
                            >
                              <img
                                className="default-img"
                                style={{ height: "320px", width: "100%", objectFit: 'contain', objectPosition: 'center' }}
                                src={producturl + item.thumbnail}
                                alt={item.product_name}
                              />
                              <img
                                className="hover-img"
                                style={{ height: "320px", width: "100%", objectFit: 'contain', objectPosition: 'center' }}
                                src={item.thumbnail2 ? producturl + item.thumbnail2 : producturl + item.thumbnail}
                                alt={item.product_name}
                              />
                            </Link>
                          </div>
                          <div className="product-action-1 d-flex">
                            <Link
                              to={`/product/${item.product_slug}`}
                              aria-label="Quick View"
                              onClick={(e) => {
                                e.preventDefault();
                                handleProductRoute(item.product_slug);
                              }}
                              className="action-btn hover-up js-quick-view-button"
                            >
                              <i className="fi-rs-eye"></i>
                            </Link>
                            <div onClick={() => handleAddToWishList(item.product_id)}
                            >
                              <Link
                                to={''}
                                aria-label="Add To Wishlist"
                                className="action-btn hover-up js-add-to-wishlist-button"
                              >
                                <i className="fi-rs-heart"></i>
                              </Link>
                            </div>
                          </div>
                          <div className="product-badges product-badges-position product-badges-mrg"></div>
                        </div>
                        <div className="product-content-wrap">
                          <div className="product-category">
                            <Link
                              to={`/product/${item.product_slug}`}
                              onClick={(e) => {
                                e.preventDefault();
                                handleProductRoute(item.product_slug);
                              }}
                            >
                              {item?.category_name}
                            </Link>
                          </div>
                          <h2 className="text-truncate">
                            <Link
                              to={`/product/${item.product_slug}`}
                              onClick={(e) => {
                                e.preventDefault();
                                handleProductRoute(item.product_slug);
                              }}
                              title={item.product_name}
                            >
                              {item?.product_name}
                            </Link>
                          </h2>
                          <div className="product-rate-cover">
                            <div className="d-inline-block">
                              <Rating name="read-only" value={item?.average_stars} readOnly size="small" />
                            </div>
                            <span className="font-small ml-5 text-muted" style={{ verticalAlign: 'text-bottom' }}>
                              ({item?.average_stars ?? "Not Yet Rated"})
                            </span>
                          </div>
                          <div className="text-truncate">
                            <span className="font-small text-muted">
                              Sold By{" "}
                              <Link
                                to={`/product/${item.product_slug}`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleProductRoute(item.product_slug);
                                }}
                              >
                                {item?.vendor_name ?? "KeralaSpecial"}
                              </Link>
                            </span>
                          </div>
                          <div className="product-card-bottom">
                            <div className="product-price">
                              <span>₹ {item.mrp}</span>
                            </div>
                            <div className="add-cart">
                              <div
                                aria-label="Add To Cart"
                                className="action-btn add-to-cart-button add hover-up"
                                data-id="11"
                                onClick={() => handleAddCart(item.product_id)}
                              // style={{fontWeight:'900',color:'#3bb77e'}}
                              >
                                <div>
                                  <i className="fi-rs-shopping-cart mr-5"></i>
                                  <span className="d-inline-block">Add</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}


                {/* Pagination controls */}
                <div style={{ textAlign: "center" }}>
                  <button
                    type="button"
                    className="btn btn-fill-out btn-secondary btn-block hover-up"
                    onClick={() =>
                      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
                    }
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  <span style={{ margin: "0 10px" }}>Page {currentPage}</span>
                  <button
                    type="button"
                    className="btn btn-fill-out btn-block hover-up"
                    onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
                    disabled={data.length < productsPerPage} // Assuming data.length indicates total number of products fetched
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollToTop />

      </div>
    </>
  );
}
export default SubCategoryListingMain;
