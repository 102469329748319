import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { backend } from "../../../path";
import DashboardSidebarMain from "../dashsidebar";

import Pagination from "../../components/pagination";
function User() {
  // const [formData, setFormData] = useState([]);
  const [formData, setFormData] = useState({
    category_name: '',
    description: '', // Assuming description is a string
    logo: '', // Assuming logo is a string
  });
  const [data, setData] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [view, setView] = useState(0);
  const [logo, setLogo] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [dummyData, setDummyData] = useState({});
  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );
  const fetchData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getuserlog?page_number=" + pageNumber + "&page_size=" + pageSize, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authenticated?.access_token}`
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [pageNumber, pageSize]);



  const handlePageClick = (pageNumber) => {
    setPageNumber(pageNumber);
  };




  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-2 d-none d-lg-flex">
            <DashboardSidebarMain />
          </div>
            <div className="col-lg-10">
              <div className="login_wrap widget-taber-content background-white">
                <div className="padding_eight_all bg-white">
                  <div className="padding_eight_all bg-white">
                    <div className="d-flex justify-content-between mb-20 mt-20">
                      <h3 className="">User Analytics</h3>
                      <div className="d-flex justify-content-end gap-3">
                        {/* <a href="/admin-users-add">
                        <button
                          type="button"
                          className="btn btn-fill-out btn-block hover-up"
                          //   onClick={handleSubmit}
                        >
                          Add New
                        </button>
                      </a> */}
                      </div>
                    </div>
                    <br></br>
                  </div>

                  <div className="table-responsive">
                    <table className="table table-secondary table-hover">
                      <thead className="table-dark">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{item.action}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* <div className="form-group">
                <button
                  type="button"
                  className="btn btn-fill-out btn-block hover-up"
                  onClick={handleSubmit}
                >
                  Register
                </button>
              </div> */}
                <br></br>
                <Pagination
                  currentPage={pageNumber}
                  pageSize={pageSize}
                  totalItems={data.total}
                  onPageChange={handlePageClick}
                />
              </div>
            </div>

        </div>
      </div>
      <ToastContainer />
    </>
  );
}
export default User;
