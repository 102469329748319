import { useEffect, useState } from "react";
import { backend, producturl } from "../../../../path";
import useRazorpay from "react-razorpay";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Button, Collapse } from "react-bootstrap";
import ScrollToTop from "../../../components/whatsapp connect";
import LoaderMain from "../../../layout/header/loader_sub";
import { Rating } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Cart(props) {
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [data, setData] = useState([]);
  const [couponSuccess, setCouponSuccess] = useState('');
  const [Razorpay] = useRazorpay();
  const [activeStep, setActiveStep] = useState(0)
  const [formData, setFormData] = useState({
    country: "102",
  });
  const [address, setAddress] = useState([]);
  const [delAd, setDelAd] = useState(null);
  const [newAdd, setNewAdd] = useState({
    country: "102",
  });  
  const [count, setCountry] = useState([]);
  const [open, setOpen] = useState(false);
  const [delivery, setDelivery] = useState(false);
  const [newDelivery, setNewDelivery] = useState(false);
  const [coupon, setCoupon] = useState(0);
  const [couponId, setCouponId] = useState('');
  const navigate = useNavigate()
  const handleProductRoute = (productId) => {
    navigate(`/product/${productId}`);
    // window.location.reload();
  };
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );
  const fetchData = () => {
    setLoading(true);

    fetch(backend + `getcartbyuseriduserside`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authenticated?.access_token}`
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchCountryData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getcountry", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setCountry(data);
        console.log(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchAddress = () => {
    setLoading(true);

    fetch(backend + "getaddress", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authenticated?.access_token}`
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // Modify the object to include is_primary: 0
        // const updatedData = {
        //   ...data,
        //   is_primary: 0
        // };
        // Set the form data with the updated object
        setAddress(data)
        setFormData(data[0]);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const calculateTotal = () => {
    let total = 0;
    data.forEach((item) => {
      total += item?.total;
    });
    return total;
  };
  const calculateTotalMRP = () => {
    let total = 0;
    data.forEach((item) => {
      total += item?.total;
    });
    return total;
  };
  const calculateTotalSHIPPING = () => {
    let total = 0;
    data.forEach((item) => {
      total += item?.shipping_charge;
    });
    return total;
  };

  const calculateTotalMRPCoupon = () => {
    let total = 0;
    data.forEach((item) => {
      total += item?.total;
    });
    return total - couponSuccess?.amount;
  };

  useEffect(() => {
    fetchData();
    fetchCountryData();
    fetchAddress();
  }, []);

  // const handleQuantityChange = async (id, qty) => {
  //   console.log("Handling quantity change for ID:", id);
  //   console.log("New quantity:", qty);

  // };


  const handleDecrement = async (id, qty) => {
    if (qty === 1) {
      return;
    }
    const formData = {
      quantity: qty - 1,
      cart_id: id,
    };

    try {
      const response = await fetch(backend + "updatecartuserside", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        fetchData();
      } else if (response.status === 409) {
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleIncrement = async (id, qty) => {
    const formData = {
      quantity: qty + 1,
      cart_id: id,
    };

    try {
      const response = await fetch(backend + "updatecartuserside", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        fetchData();
      } else if (response.status === 409) {
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };


  const handleDeleteCart = async (id) => {
    try {
      const response = await fetch(backend + `deletecartbyiduserside/${id}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`
        },
        body: JSON.stringify(),
      });

      if (response.status === 200) {
        fetchData();
        props.setCartChange(!props.cartChange)
      } else if (response.status === 409) {
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //payment


  const handlePayment = async (id, total, details) => {
    const options = {
      key: "rzp_live_VmDGOVv2NGo2Yi",
      amount: total * 100,
      currency: "INR",
      name: "Kerala Special",
      description: "Order Payment",
      image: "https://keralaspecial.in/storage/general/ks-logo32.png",
      handler: function (response) {
        // alert(response.razorpay_payment_id);
        handleSuccessOrder(id, response.razorpay_payment_id)
      },
      prefill: {
        name: `${details?.first_name} ${details?.last_name}`,
        email: details?.email,
        contact: details?.contact,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });

    rzp1.on('payment.success', function (response) {
    })
    rzp1.open()
  };



  const handleSubmit = () => {
    console.log(formData)
    // List of required fields and their corresponding display names
    const requiredFields = [
      { key: "first_name", displayName: "First Name" },
      { key: "house_name", displayName: "House Name / House Number / Apartment" },
      { key: "country", displayName: "Country" },
      { key: "state", displayName: "State" },
      { key: "city", displayName: "City" },
      { key: "pincode", displayName: "Pincode" },
      { key: "contact", displayName: "Contact" },
    ];
    // Check if any required field is missing or blank
    const invalidField = requiredFields.find((field) => {
      const value = formData[field.key];
      return typeof value !== "string" || value.trim() === ""; // Check if value is not a string or is empty or consists of only whitespace
    });

    if (invalidField) {
      toast.error(`${invalidField.displayName} cannot be blank`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Stop further execution
    }

    fetch(backend + "addaddress", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );
          if (response.status === 409) {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
          }

          return;
        }

        response.json().then((data) => {
          handleOrderAddress(data)
          fetchAddress()
          setOpen(false);
          // Additional success handling code here
          toast.success("Primary Address Updated", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
        // navigate("/admin-users");
      })
      .catch((error) => {
      });
  };
  const handleSubmitNewAddress = () => {
    // List of required fields and their corresponding display names
    const requiredFields = [
      { key: "first_name", displayName: "First Name" },
      { key: "house_name", displayName: "House Name / House Number / Apartment" },
      { key: "country", displayName: "Country" },
      { key: "state", displayName: "State" },
      { key: "city", displayName: "City" },
      { key: "pincode", displayName: "Pincode" },
      { key: "contact", displayName: "Contact" },
    ];

    // Check if any required field is missing or blank
    const invalidField = requiredFields.find((field) => {
      const value = newAdd[field.key];
      return typeof value !== "string" || value.trim() === ""; // Check if value is not a string or is empty or consists of only whitespace
    });


    if (invalidField) {
      toast.error(`${invalidField.displayName} cannot be blank`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; 
    }
    newAdd.is_primary = 1;
    fetch(backend + "addaddress", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(newAdd),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );
          if (response.status === 409) {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
          }

          return;
        }

        response.json().then((data) => {
          handleOrderAddress(data)
          fetchAddress()
          setOpen(false)
          setNewDelivery(false)
          // Additional success handling code here
          toast.success("Address Added", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
        // navigate("/admin-users");
      })
      .catch((error) => {
      });
  };

  const handleOrder = () => {

    if (delAd === null) {
      toast.error("Please choose a delivery address", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    let payload = {}
    payload.delivery_id = delAd;
    payload.cart_id = data.map(item => item.cart_id);
    payload.coupon_id = couponSuccess?.coupon_id;

    fetch(backend + "createorder", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );
          if (response.status === 409) {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
          }

          return;
        }

        response.json().then((data) => {

          handlePayment(data.order_no, data.grandTotalPrice, data.user_details)
          // Additional success handling code here
          // toast.success("Order Generated", {
          //   position: "top-right",
          //   autoClose: 3000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
        });
        // navigate("/admin-users");
      })
      .catch((error) => {
      });
  };
  const handleOrderAddress = (item) => {

    let payload = {}
    payload.delivery_id = item.delivery_address_id;
    payload.cart_id = data.map(item => item.cart_id);

    fetch(backend + "createorder", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );
          if (response.status === 409) {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
          }

          return;
        }

        response.json().then((data) => {

          handlePayment(data.order_no, data.grandTotalPrice, data.user_details)
          // Additional success handling code here
          // toast.success("Order Generated", {
          //   position: "top-right",
          //   autoClose: 3000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
        });
        // navigate("/admin-users");
      })
      .catch((error) => {
      });
  };

  const handleSuccessOrder = (order_id, pay_id) => {

    setLoading(true)

    let payload = {}
    payload.razorpay_payment_id = pay_id
    payload.order_no = order_id
    payload.cart_id = data.map(item => item.cart_id);

    fetch(backend + "successfullpayment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );
          if (response.status === 409) {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {

          }

          return;
        }

        response.json().then((data) => {
          setLoading(false)
          toast.success("Order Placed Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
        navigate("/success");
      })
      .catch((error) => {
      });
  };
  const handleCoupon = () => {

    let payload = {}
    payload.amount = calculateTotal();
    payload.code = couponId;

    fetch(backend + "checkcoupon", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );
          if (response.status === 401) {
            setCouponId('')
            toast.error("Invalid Coupon", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (response.status === 402) {
            toast.error("Not Applicable For this Amount", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          else if (response.status === 403) {
            toast.error("Coupon Already Used", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          return;
        }
        response.json().then((data) => {
          setCouponId('')
          setCoupon(2);
          setCouponSuccess(data)
          toast.success("Coupon Applied", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
        // navigate("/admin-users");
      })
      .catch((error) => {

      });
  };

  const handleDeleteAddress = async (id) => {
    try {
      const response = await fetch(backend + `deleteaddress/${id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`
        },
        body: JSON.stringify(),
      });

      if (response.status === 200) {
        fetchAddress();
        toast.success("Address Deleted", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (response.status === 409) {
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <main className="main " id="main-section">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <div className="breadcrumb-item d-inline-block">
                <a href="/" title="Home">
                  {" "}
                  Home{" "}
                </a>
              </div>
              <span></span>
              <div className="breadcrumb-item d-inline-block active">
                <div itemprop="item"> Shopping Cart </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="mb-80 mt-50 section--shopping-cart">
            <div className="row ">
              <div className="col-lg-12 d-none d-md-block">
                <div className="d-flex justify-content-between">

                  <h1 className="heading-2 mb-10">Your Cart</h1>
                  {authenticated && data.length > 0 &&
                    <div>
                      {coupon === 1 ?
                        <div className="d-flex gap-3">
                          <input
                            type="text"
                            value={couponId ?? ''}
                            onChange={(event) =>
                              setCouponId(
                                event.target.value
                              )
                            }
                            className=""
                          />
                          <button
                            type="button"
                            className="btn hover-up btn-light"
                            style={{ backgroundColor: '#FFA62F' }}
                            onClick={handleCoupon}
                          >
                            Apply
                          </button>
                        </div>
                        : coupon === 0 ?
                          <button
                            type="button"
                            className="btn hover-up btn-light"
                            style={{ backgroundColor: 'red' }}
                            onClick={() => setCoupon(1)}
                          >
                            Apply Coupon
                          </button>
                          :
                          <button
                            type="button"
                            className="btn hover-up btn-light"
                            style={{ backgroundColor: '#ACD793' }}
                            disabled
                          >
                            Coupon Applied
                          </button>
                      }
                    </div>
                  }
                </div>
              </div>
              <div className="col-lg-12 d-block d-md-none">
                <div className="d-flex justify-content-start">
                  <h1 className="heading-2 mb-10">Your Cart</h1>
                </div>
              </div>
              <div className="col-lg-12 d-block d-md-none my-3">
                <div className="d-flex justify-content-between">
                  {authenticated && data.length > 0 &&
                    <div>
                      {coupon === 1 ?
                        <div className="d-flex gap-3">
                          <input
                            type="text"
                            value={couponId ?? ''}
                            onChange={(event) =>
                              setCouponId(
                                event.target.value
                              )
                            }
                            className=""
                          />
                          <button
                            type="button"
                            className="btn hover-up btn-light"
                            style={{ backgroundColor: '#FFA62F' }}
                            onClick={handleCoupon}
                          >
                            Apply
                          </button>
                        </div>
                        : coupon === 0 ?
                          <button
                            type="button"
                            className="btn hover-up btn-light"
                            style={{ backgroundColor: 'red' }}
                            onClick={() => setCoupon(1)}
                          >
                            Apply Coupon
                          </button>
                          :
                          <button
                            type="button"
                            className="btn hover-up btn-light"
                            style={{ backgroundColor: '#ACD793' }}
                            disabled
                          >
                            Coupon Applied
                          </button>
                      }
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className="d-none d-md-block d-lg-block">
              <div className="row product-grid mx-1">
                {loading && (
                  <div>
                    {/* Loading...  */}
                    <LoaderMain />
                  </div>
                )}
                {!loading ? (
                  data.length > 0 ? (
                    data.map((item, i) => (
                      <>
                        <div
                          className="d-flex justify-content-between product-cart-wrap mb-30 wow animate__animated animate__fadeIn "
                          data-wow-delay="0.1s"
                          style={{
                            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px', paddingTop: '20px'
                          }}
                        >
                          <div>
                            {" "}
                            <div className="product-img-action-wrap">
                              <div className="product-img product-img-zoom">
                                <a
                                onClick={() => handleProductRoute(item.product_slug)}
                                >
                                  <img
                                    className="default-img"
                                    style={{ height: "200px", width: "100%", objectFit: 'contain', objectPosition: 'center' }}
                                    // src={mediaurl + item.images}
                                    src={producturl + item?.thumbnail}
                                  // alt="Nestle Original Coffee-Mate Coffee Creamer"
                                  />
                                  <img
                                    className="hover-img"
                                    style={{ height: "200px", width: "100%", objectFit: 'contain', objectPosition: 'center' }}
                                    src={producturl + item?.thumbnail2}
                                  // alt="Nestle Original Coffee-Mate Coffee Creamer"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div>
                            {" "}
                            <div className="product-content-wrap pt-4">
                              <div className="product-category">
                                <a
                                onClick={() => handleProductRoute(item.product_slug)}
                                >
                                  {item?.category_name}
                                </a>
                              </div>
                              <h2 className="text-truncate">
                                <a
                                  href={`/product/${item.product_slug}`} onClick={() => handleProductRoute(item.product_slug)}                                // title="Nestle Original Coffee-Mate Coffee Creamer"
                                >
                                  {item?.product_name}
                                </a>
                              </h2>
                              <div className="product-rate-cover">
                                <div className="d-inline-block">
                                  <Rating name="read-only" value={item?.average_stars} readOnly size="small" />
                                </div>
                                <span className="font-small ml-5 text-muted" style={{ verticalAlign: 'top' }}>
                                  ({item?.average_stars ?? "Not Yet Rated"})
                                </span>
                              </div>
                              <div className="text-truncate">
                                <span className="font-small text-muted">
                                  Sold By{" "}
                                  <b style={{ fontWeight: '700' }}>
                                    <a
                                    // onClick={() => handleProductRoute(item.product_slug)}
                                    >
                                      {item?.manufacturer_name ?? "KeralaSpecial"}
                                    </a></b>
                                </span>
                              </div>
                              <div className="product-card-bottom">
                                <div className="product-price">
                                  <span>₹ {item?.mrp} </span>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between">
                                <div className="product-card-bottom">
                                  <div className="product-price">
                                    <span className="d-flex">
                                      {" "}
                                      <div className="text-secondary mt-5">
                                        {" "}
                                        Total number of items : &nbsp;
                                      </div>{" "}
                                      <div className="detail-qty border radius">
                                        <a className="qty-down">
                                          <i className="fi-rs-angle-small-down"
                                            onClick={() =>
                                              handleDecrement(
                                                item?.cart_id,
                                                item?.quantity,
                                              )
                                            }
                                          ></i>
                                        </a>
                                        <input
                                          type="number"
                                          min="1"
                                          value={item?.quantity}
                                          // onChange={(event) =>
                                          //   handleQuantityChange(
                                          //     item.cart_id,
                                          //     event.target.value
                                          //   )
                                          // }
                                          className="qty-val qty-input"
                                        />
                                        <a className="qty-up"
                                          // onClick={handleIncrement}
                                          onClick={() =>
                                            handleIncrement(
                                              item?.cart_id,
                                              item?.quantity,
                                            )
                                          }
                                        >
                                          <i className="fi-rs-angle-small-up"></i>
                                        </a>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div className="pt-5 mt-5">
                                  <Icon
                                    icon="openmoji:delete"
                                    color="black"
                                    className="hover-up"
                                    fontSize={46}
                                    onClick={() =>
                                      handleDeleteCart(item?.cart_id)
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </div></div>
                          </div>
                          <div className="pt-4 mx-3">
                            <div className="align-middle">
                              <h6 className="heading-2 border-bottom pb-1">
                                {" "}
                                PRODUCT + SHIPPING CHARGE
                              </h6>
                              <h3 className="heading-4 product-price text-info">
                                ₹ {item?.total + item?.shipping_charge} ({item?.total}+{item?.shipping_charge})
                              </h3>

                            </div>

                          </div>
                        </div>
                      </>
                    ))

                  ) : (
                    <form>
                      <div className="row">
                        <div className="col-lg-8">
                          <p className="text-center">loading...!</p>
                        </div>
                      </div>
                    </form>
                  )) : (
                  <LoaderMain />
                )}
                {!loading && (
                  data.length > 0 &&
                  <>
                    {!delivery ?
                      <>
                        <div className="d-flex justify-content-end gap-5 ">
                          <div className="d-flex justify-content-between gap-5">
                            <h3 className="heading-4 product-price text-primary">

                              SUBTOTAL              </h3>
                            <h3 className="heading-4 product-price text-primary">
                              {couponSuccess ? <><s style={{ color: '#3bb77e' }}>₹ {calculateTotalMRP() + calculateTotalSHIPPING()}</s> <div>₹ {calculateTotalMRPCoupon() + calculateTotalSHIPPING()}</div> </> : <div> ₹ {calculateTotalMRP() + calculateTotalSHIPPING()}</div>}
                            </h3>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end ">
                          <div className="d-flex justify-content-end ">

                            <h5 className="heading-4 product-price text-secondary">

                              (PRODUCT TOTAL ₹             </h5>
                            <h5 className="heading-4 product-price text-secondary">

                              {couponSuccess ? <><s style={{ color: '#615EFC' }}> {calculateTotalMRP()}</s> <div> {calculateTotalMRPCoupon()})</div> </> : <div>  {calculateTotalMRP()})</div>}
                            </h5>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end ">
                          <div className="d-flex justify-content-start ">

                            <h5 className="heading-4 product-price text-secondary">

                              (SHIPPING CHARGE ₹              </h5>
                            <h5 className="heading-4 product-price text-secondary">
                              {calculateTotalSHIPPING()})
                            </h5>


                          </div>
                        </div>
                        <div className="d-flex justify-content-end gap-5 mt-5">

                          <div className="d-flex justify-content-between gap-5 mt-5">
                            <button
                              type="button"
                              className="btn btn-fill-out btn-block hover-up"
                              // onClick={handlePayment}
                              onClick={() => setDelivery(!delivery)}
                              aria-controls="delivery-full"
                              aria-expanded={delivery}
                            >
                              Buy Now
                            </button>{" "}
                          </div>
                        </div>
                      </>
                      :
                      <div className="d-flex justify-content-end gap-5 mt-5">
                        <div className="d-flex justify-content-between gap-5 mt-5">
                          <button
                            type="button"
                            className="btn btn-fill-out btn-block hover-up"
                            // onClick={handlePayment}
                            onClick={() => setDelivery(!delivery)}
                            aria-controls="delivery-full"
                            aria-expanded={delivery}
                          >
                            Back
                          </button>{" "}
                        </div>
                      </div>
                    }
                  </>
                )}

                <Collapse in={delivery}>
                  <a className="btn btn-lg mb-2 mt-30 w-100"
                    style={{
                      backdropFilter: 'inherit',
                      backgroundBlendMode: 'color-burn',
                      backgroundColor: '#3bb77e',
                      fontWeight: 'bolder',
                      color: '#fff',
                      boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'
                    }}
                  >
                    <div className="mt-3" id="delivery-full">
                      <div className="col-lg-12">
                        <div className="d-flex justify-content-start">
                          <h1 className="heading-2 mb-10" style={{ color: '#fff' }}>Delivery Address</h1>

                        </div>
                      </div>
                      <div className="pt-4">
                        <div className=" p-5 rounded border border-warning"
                          style={{
                            backgroundColor: '#fff',
                            alignContent: 'start'
                          }}
                        >
                          <h6 className="heading-2 border-bottom pb-1 text-info d-flex justify-content-between">
                            {" "}
                            <>{address[0]?.city ?
                             <Button
                             onClick={() => {
                               setNewDelivery(!newDelivery); setOpen(false);
                             }}
                             aria-controls="example-new-text"
                             aria-expanded={newDelivery}
                           >   Add a New Delivery Address
                           </Button>
                           :
                           <Button
                              onClick={() => {
                                setNewDelivery(false); setOpen(!open);
                              }}
                              aria-controls="example-collapse-text"
                              aria-expanded={open}
                            >Edit Primary Address
                            </Button>
                          }
                             
                              {/* <Button
                              onClick={() => {
                                setNewDelivery(false); setOpen(!open);
                              }}
                              aria-controls="example-collapse-text"
                              aria-expanded={open}
                            >Edit Primary Address
                            </Button> */}
                            </>
                          </h6>
                          <div className="" style={{
                            textAlign: 'start', color: '#3bb77e'
                          }}>
                            {address?.map((item, i) => (
                              <div className="my-3 d-flex justify-content-between" key={i}>
                                <div>
                                  <h3 className="heading-4 product-price" style={{ color: '#3bb77e' }}>
                                    <input
                                      className="form-check-input mt-2"
                                      type="radio"
                                      name="delivery_address"
                                      id={`delivery-address-${i}`}
                                      onChange={() => setDelAd(item?.delivery_address_id)}
                                    /> {item?.first_name} {item?.last_name}
                                  </h3>
                                  {item?.house_name && item?.city && item?.pincode ? (
                                    <h5 className="heading-4 product-price" style={{ color: '#3bb77e' }}>
                                      {item?.house_name}, {item?.street_name}, {item?.city}, {item?.state}, {item?.country_name}, {item?.pincode}, PH NO : {item?.contact}
                                    </h5>
                                  ) : (
                                    <>No more details</>
                                  )}
                                </div>
                                <button
                                  type="button"
                                  className="hover-up"
                                  style={{ backgroundColor: '#3bb77e', color: '#fff', borderRadius: '5px', border: 'none', height: '20px', width: '100px' }}
                                  onClick={()=>handleDeleteAddress(item?.delivery_address_id)}
                                >
                                  Delete
                                </button>{" "}
                              </div>
                            ))}

                          </div></div>

                      </div>

                      <Collapse in={open}>
                        <div
                          className="pt-4"
                          data-wow-delay="0.1s"
                          id="example-collapse-text"

                        >
                          <div className="form__content">
                            <div className="row">

                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="form-group">
                                  <label htmlFor="name">First Name</label>{" "}
                                  <sup style={{ color: "red", fontSize: "13px" }}>
                                    *
                                  </sup>
                                  <input
                                    className="form-control"
                                    name="product_name"
                                    id="txt-name"
                                    type="text"
                                    // required
                                    value={formData?.first_name || ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        first_name: e.target.value,
                                      })
                                    }
                                    placeholder="First Name"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="form-group">
                                  <label htmlFor="name">Last Name</label>{" "}
                                  <sup style={{ color: "red", fontSize: "13px" }}>
                                    *
                                  </sup>
                                  <input
                                    className="form-control"
                                    name="last_name"
                                    id="txt-name"
                                    type="text"
                                    // required
                                    value={formData?.last_name || ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        last_name: e.target.value,
                                      })
                                    }
                                    placeholder="Last Name"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-12">
                                <div className="form-group">
                                  <label htmlFor="name">House Name / House Number / Apartment</label>{" "}
                                  <sup style={{ color: "red", fontSize: "13px" }}>
                                    *
                                  </sup>
                                  <input
                                    className="form-control"
                                    name="house_name"
                                    id="txt-name"
                                    type="text"
                                    // required
                                    value={formData?.house_name || ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        house_name: e.target.value,
                                      })
                                    }
                                    placeholder="House Name / House Number / Apartment"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="form-group">
                                  <label htmlFor="name">Street Name</label>{" "}
                                  {/* <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup> */}
                                  <input
                                    className="form-control"
                                    name="street_name"
                                    id="txt-name"
                                    type="text"
                                    // required
                                    value={formData?.street_name || ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        street_name: e.target.value,
                                      })
                                    }
                                    placeholder="Street Name"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="form-group">
                                  <label htmlFor="city">City</label>
                                  <sup style={{ color: "red", fontSize: "13px" }}>
                                    *
                                  </sup>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="city"
                                    required
                                    value={formData?.city || ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        city: e.target.value,
                                      })
                                    }
                                    placeholder="City"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="form-group">
                                  <label htmlFor="state">State</label>
                                  <sup style={{ color: "red", fontSize: "13px" }}>
                                    *
                                  </sup>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="state"
                                    required
                                    value={formData?.state || ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        state: e.target.value,
                                      })
                                    }
                                    placeholder="State"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-6">

                                <div className="form-group">
                                  <label htmlFor="country">Country</label>
                                  <sup style={{ color: "red", fontSize: "13px" }}>*</sup>
                                  <select
                                    className="form-control"
                                    name="category_name"
                                    id="txt-name"
                                    required
                                    value={formData?.country || ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        country: e.target.value,
                                      })
                                    }
                                  >
                                    <option>- select -</option>
                                    {count.map((val) => {
                                      return (
                                        <option value={val.country_id}>
                                          {val.countryname}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>


                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="form-group">
                                  <label htmlFor="pincode">Pin Code</label>
                                  <sup style={{ color: "red", fontSize: "13px" }}>
                                    *
                                  </sup>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="pincode"
                                    required
                                    value={formData?.pincode || ""}
                                    maxLength={6}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        pincode: e.target.value.replace(/[^0-9]/g, ""),
                                      })
                                    }
                                    placeholder="Pincode"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="form-group">
                                  <label htmlFor="street_name">
                                    Contact Number
                                  </label>
                                  <sup style={{ color: "red", fontSize: "13px" }}>
                                    *
                                  </sup>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="phone"
                                    minLength={6}
                                    maxLength={12}
                                    required
                                    value={formData?.contact || ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        contact: e.target.value.replace(/[^0-9+]/g, ""),
                                      })
                                    }
                                    placeholder="Primary contact number"
                                  />
                                </div>
                              </div>
                              <div className="login_footer form-group">
                                <div className="chek-form">
                                  <div className="custome-checkbox">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="agree_terms_and_policy"
                                      id="agree-terms-and-policy"
                                      checked={formData?.is_primary === 0} // Check if is_primary is 1
                                      onChange={(e) =>
                                        setFormData({
                                          ...formData,
                                          is_primary: e.target.checked ? 0 : 1, // Set to 1 if checked, 0 otherwise
                                        })
                                      }
                                    />

                                    <label
                                      className="form-check-label"
                                      for="agree-terms-and-policy"
                                    >
                                      <span>
                                        <a>
                                          Set this as my primary address.</a>
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end gap-5 ">
                                <div className="d-flex justify-content-between gap-5">
                                  <h3 className="heading-4 product-price text-white">

                                    SUBTOTAL              </h3>
                                  <h3 className="heading-4 product-price text-white">
                                    {couponSuccess ? <><s style={{ color: '#3bb77e' }}>₹ {calculateTotalMRP() + calculateTotalSHIPPING()}</s> <div>₹ {calculateTotalMRPCoupon() + calculateTotalSHIPPING()}</div> </> : <div> ₹ {calculateTotalMRP() + calculateTotalSHIPPING()}</div>}
                                  </h3>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end ">
                                <div className="d-flex justify-content-end ">

                                  <h5 className="heading-4 product-price text-secondary">

                                    (PRODUCT TOTAL ₹             </h5>
                                  <h5 className="heading-4 product-price text-secondary">

                                    {couponSuccess ? <><s style={{ color: '#615EFC' }}> {calculateTotalMRP()}</s> <div> {calculateTotalMRPCoupon()})</div> </> : <div>  {calculateTotalMRP()})</div>}
                                  </h5>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end ">
                                <div className="d-flex justify-content-start ">

                                  <h5 className="heading-4 product-price text-secondary">

                                    (SHIPPING CHARGE ₹              </h5>
                                  <h5 className="heading-4 product-price text-secondary">
                                    {calculateTotalSHIPPING()})
                                  </h5>


                                </div>
                              </div>
                              <div className="form-group">
                                <button
                                  type="button"
                                  className="btn hover-up"
                                  style={{ backgroundColor: 'red' }}
                                  onClick={() => handleSubmit()}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                      <Collapse in={newDelivery}>
                        <div
                          className="pt-4"
                          data-wow-delay="0.1s"
                          id="example-new-text"

                        >
                          <div className="form__content">
                            <div className="row">

                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="form-group">
                                  <label htmlFor="name">First Name</label>{" "}
                                  <sup style={{ color: "red", fontSize: "13px" }}>
                                    *
                                  </sup>
                                  <input
                                    className="form-control"
                                    name="product_name"
                                    id="txt-name"
                                    type="text"
                                    style={{backgroundColor:'#fff', color:'#000', border:'1px solid #000'}}
                                    // required
                                    value={newAdd?.first_name || ""}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        first_name: e.target.value,
                                      })
                                    }
                                    placeholder="First Name"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="form-group">
                                  <label htmlFor="name">Last Name</label>{" "}
                                  <sup style={{ color: "red", fontSize: "13px" }}>
                                    *
                                  </sup>
                                  <input
                                    className="form-control"
                                    name="last_name"
                                    id="txt-name"
                                    type="text"
                                    style={{backgroundColor:'#fff', color:'#000', border:'1px solid #000'}}
                                    // required
                                    value={newAdd?.last_name || ""}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        last_name: e.target.value,
                                      })
                                    }
                                    placeholder="Last Name"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-12">
                                <div className="form-group">
                                  <label htmlFor="name">House Name / House Number / Apartment</label>{" "}
                                  <sup style={{ color: "red", fontSize: "13px" }}>
                                    *
                                  </sup>
                                  <input
                                    className="form-control"
                                    name="house_name"
                                    id="txt-name"
                                    type="text"
                                    style={{backgroundColor:'#fff', color:'#000', border:'1px solid #000'}}
                                    // required
                                    value={newAdd.house_name || ""}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        house_name: e.target.value,
                                      })
                                    }
                                    placeholder="House Name / House Number / Apartment"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="form-group">
                                  <label htmlFor="name">Street Name</label>{" "}
                                  <input
                                    className="form-control"
                                    name="street_name"
                                    id="txt-name"
                                    type="text"
                                    style={{backgroundColor:'#fff', color:'#000', border:'1px solid #000'}}
                                    // required
                                    value={newAdd.street_name || ""}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        street_name: e.target.value,
                                      })
                                    }
                                    placeholder="Street Name"
                                  />
                                </div>
                              </div>

                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="form-group">
                                  <label htmlFor="city">City</label>
                                  <sup style={{ color: "red", fontSize: "13px" }}>
                                    *
                                  </sup>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="city"
                                    style={{backgroundColor:'#fff', color:'#000', border:'1px solid #000'}}
                                    required
                                    value={newAdd.city || ""}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        city: e.target.value,
                                      })
                                    }
                                    placeholder="City"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="form-group">
                                  <label htmlFor="state">State</label>
                                  <sup style={{ color: "red", fontSize: "13px" }}>
                                    *
                                  </sup>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="state"
                                    style={{backgroundColor:'#fff', color:'#000', border:'1px solid #000'}}
                                    required
                                    value={newAdd.state || ""}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        state: e.target.value,
                                      })
                                    }
                                    placeholder="State"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="form-group">
                                  <label htmlFor="country">Country</label>
                                  <sup style={{ color: "red", fontSize: "13px" }}>*</sup>
                                  <select
                                    className="form-control"
                                    name="category_name"
                                    id="txt-name"
                                    style={{backgroundColor:'#fff', color:'#000', border:'1px solid #000'}}
                                    required
                                    value={newAdd.country || ""}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        country: e.target.value,
                                      })
                                    }
                                  >
                                    <option>- select -</option>
                                    {count.map((val) => {
                                      return (
                                        
                                        <option value={val.country_id}>
                                          {val.countryname}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>

                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="form-group">
                                  <label htmlFor="pincode">Pin Code</label>
                                  <sup style={{ color: "red", fontSize: "13px" }}>
                                    *
                                  </sup>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="pincode"
                                    style={{backgroundColor:'#fff', color:'#000', border:'1px solid #000'}}
                                    required
                                    value={newAdd.pincode || ""}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        pincode: e.target.value.replace(/[^0-9]/g, ""),
                                      })
                                    }
                                    placeholder="Pincode"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="form-group">
                                  <label htmlFor="street_name">
                                    Contact Number
                                  </label>
                                  <sup style={{ color: "red", fontSize: "13px" }}>
                                    *
                                  </sup>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="phone"
                                    minLength={6}
                                    maxLength={12}
                                    style={{backgroundColor:'#fff', color:'#000', border:'1px solid #000'}}
                                    required
                                    value={newAdd.contact || ""}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        contact: e.target.value.replace(/[^0-9+]/g, ""),
                                      })
                                    }
                                    placeholder="Primary contact number"
                                  />
                                </div>
                              </div>
                              {/* <div className="login_footer form-group">
                              <div className="chek-form">
                                <div className="custome-checkbox">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="agree_terms_and_policy"
                                    id="agree-terms-and-policy"
                                    checked={newAdd.is_primary === 0}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        is_primary: e.target.checked ? 0 : 1,                                      })
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    for="agree-terms-and-policy"
                                  >
                                    <span>
                                      <a>
                                        Set this as my primary address.</a>
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div> */}
                              <div className="d-flex justify-content-end gap-5 ">
                                <div className="d-flex justify-content-between gap-5">
                                  <h3 className="heading-4 product-price text-white">

                                    SUBTOTAL              </h3>
                                  <h3 className="heading-4 product-price text-white">
                                    {couponSuccess ? <><s style={{ color: '#B06161' }}>₹ {calculateTotalMRP() + calculateTotalSHIPPING()}</s> <div>₹ {calculateTotalMRPCoupon() + calculateTotalSHIPPING()}</div> </> : <div> ₹ {calculateTotalMRP() + calculateTotalSHIPPING()}</div>}
                                  </h3>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end ">
                                <div className="d-flex justify-content-end ">

                                  <h5 className="heading-4 product-price text-secondary">

                                    (PRODUCT TOTAL ₹             </h5>
                                  <h5 className="heading-4 product-price text-secondary">

                                    {couponSuccess ? <><s style={{ color: '#B06161' }}> {calculateTotalMRP()}</s> <div> {calculateTotalMRPCoupon()})</div> </> : <div>  {calculateTotalMRP()})</div>}
                                  </h5>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end ">
                                <div className="d-flex justify-content-start ">

                                  <h5 className="heading-4 product-price text-secondary">

                                    (SHIPPING CHARGE ₹              </h5>
                                  <h5 className="heading-4 product-price text-secondary">
                                    {calculateTotalSHIPPING()})
                                  </h5>


                                </div>
                              </div>
                              <div className="form-group">
                                <button
                                  type="button"
                                  className="btn btn-fill-out btn-block hover-up btn-secondary"
                                  onClick={() => handleSubmitNewAddress()}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                      {!newDelivery && !open &&
                        <>
                          <div className="d-flex justify-content-end gap-5 ">
                            <div className="d-flex justify-content-between gap-5">
                              <h3 className="heading-4 product-price text-white">

                                SUBTOTAL             </h3>
                              <h3 className="heading-4 product-price text-white">
                                {couponSuccess ? <><s style={{ color: '#B06161' }}>₹ {calculateTotalMRP() + calculateTotalSHIPPING()}</s> <div>₹ {calculateTotalMRPCoupon() + calculateTotalSHIPPING()}</div> </> : <div> ₹ {calculateTotalMRP() + calculateTotalSHIPPING()}</div>}
                              </h3>
                            </div>
                          </div>
                          <div className="d-flex justify-content-end ">
                            <div className="d-flex justify-content-end ">

                              <h5 className="heading-4 product-price text-secondary">

                                (PRODUCT TOTAL ₹             </h5>
                              <h5 className="heading-4 product-price text-secondary">

                                {couponSuccess ? <><s style={{ color: '#B06161' }}> {calculateTotalMRP()}</s> <div> {calculateTotalMRPCoupon()})</div> </> : <div>  {calculateTotalMRP()})</div>}
                              </h5>
                            </div>
                          </div>
                          <div className="d-flex justify-content-end ">
                            <div className="d-flex justify-content-start ">

                              <h5 className="heading-4 product-price text-secondary">

                                (SHIPPING CHARGE ₹              </h5>
                              <h5 className="heading-4 product-price text-secondary">
                                {calculateTotalSHIPPING()})
                              </h5>


                            </div>
                          </div>
                          <div className="d-flex justify-content-end gap-5 mt-5">
                            <div className="d-flex justify-content-between gap-5 mt-5">
                              <button
                                type="button"
                                className="btn btn-fill-out btn-block btn-secondary hover-up"
                                // onClick={handlePayment}
                                onClick={handleOrder}
                              >
                                Check Out
                              </button>
                            </div>
                          </div>
                        </>

                      }
                    </div>

                  </a>

                </Collapse>
              </div>
            </div>
            <div className="d-block d-md-none d-lg-none">
              <div className="row product-grid">
                {loading && (
                  <div>
                    {/* Loading...  */}
                    <LoaderMain />
                  </div>
                )}
                {!loading && (
                  data.map((item, i) => (
                    <div
                      key={i}
                      className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-6 col-md-4 col-12 col-sm-6 col-xs-6"
                    >
                      <div
                        className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                        data-wow-delay="0.1s"
                      >
                        <div className="product-img-action-wrap">
                          <div className="product-img product-img-zoom">
                            <a
                              onClick={() => handleProductRoute(item.product_slug)}
                            >
                              <img
                                className="default-img"
                                style={{ height: "320px", width: "100%" }}
                                src={producturl + item.thumbnail}
                              // src="../storage/products/11-400x400.jpg"
                              // alt="Nestle Original Coffee-Mate Coffee Creamer"
                              />
                              <img
                                className="hover-img"
                                style={{ height: "320px", width: "100%" }}
                                src={producturl + item.thumbnail2}
                              // alt="Nestle Original Coffee-Mate Coffee Creamer"
                              />
                            </a>
                          </div>
                          <div className="product-action-1">
                            <a
                              aria-label="Quick View"
                              onClick={() => handleProductRoute(item.product_slug)}
                              className="action-btn hover-up js-quick-view-button"
                            >
                              <i className="fi-rs-eye"></i>
                            </a>
                            <a
                              aria-label="Add To Wishlist"
                              // onClick={() => handleAddToWishList(item.product_id)}
                              className="action-btn hover-up js-add-to-wishlist-button"
                            >
                              <i className="fi-rs-heart"></i>
                            </a>
                            {/* <a
                            aria-label="Add To Compare"
                            onClick={() => handleProductRoute(item.product_id)}
                            className="action-btn hover-up js-add-to-compare-button"
                          >
                            <i className="fi-rs-shuffle"></i>
                          </a> */}
                          </div>
                          <div className="product-badges product-badges-position product-badges-mrg"></div>
                        </div>
                        <div className="product-content-wrap">
                          <div className="product-category">
                            <a
                            // onClick={() => handleProductRoute(item.product_id)}
                            >
                              {item?.category_name}
                            </a>
                          </div>
                          <h2 className="text-truncate">
                            <a
                              onClick={() => handleProductRoute(item.product_slug)}
                              title={item?.product_name}
                            >
                              {item?.product_name}
                            </a>
                          </h2>
                          <div className="product-rate-cover">
                            <div className=" d-inline-block">
                              <Rating name="read-only" value={item?.average_stars} readOnly size="small" />
                            </div>
                            <span className="font-small ml-5 text-muted">
                              ({item?.average_stars ?? "Not Yet Rated"})
                            </span>
                          </div>
                          <div className="text-truncate">
                            <span className="font-small text-muted">
                              Sold By{" "}
                              <a
                              // onClick={() =>
                              //   handleProductRoute(item.product_id)
                              // }
                              >
                                {item?.vendor_name ?? "KeralaSpecial"}
                              </a>
                            </span>
                          </div>
                          <div className="product-card-bottom">
                            <div className="product-price">
                              <span>₹ {item.total}</span>
                            </div>
                            <div className="">
                              <Icon
                                icon="openmoji:delete"
                                color="black"
                                className="hover-up"
                                fontSize={30}
                                onClick={() =>
                                  handleDeleteCart(item?.cart_id)
                                }
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                            <div className="detail-qty border radius">
                              <a className="qty-down">
                                <i className="fi-rs-angle-small-down"
                                  onClick={() =>
                                    handleDecrement(
                                      item?.cart_id,
                                      item?.quantity,
                                    )
                                  }
                                ></i>
                              </a>
                              <input
                                type="number"
                                min="1"
                                value={item?.quantity}
                                // onChange={(event) =>
                                //   handleQuantityChange(
                                //     item.cart_id,
                                //     event.target.value
                                //   )
                                // }
                                className="qty-val qty-input"
                              />
                              <a className="qty-up"
                                // onClick={handleIncrement}
                                onClick={() =>
                                  handleIncrement(
                                    item?.cart_id,
                                    item?.quantity,
                                  )
                                }
                              >
                                <i className="fi-rs-angle-small-up"></i>
                              </a>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  )))}


                {!loading && (
                  data.length > 0 &&
                  <>
                    <div className="d-flex justify-content-end gap-5 ">
                      <div className="d-flex justify-content-between gap-5">
                        <h3 className="heading-4 product-price text-success">

                          SUBTOTAL              </h3>
                        <h3 className="heading-4 product-price text-success">
                          {couponSuccess ? <><s style={{ color: '#3bb77e' }}>₹ {calculateTotalMRP() + calculateTotalSHIPPING()}</s> <div>₹ {calculateTotalMRPCoupon() + calculateTotalSHIPPING()}</div> </> : <div> ₹ {calculateTotalMRP() + calculateTotalSHIPPING()}</div>}
                        </h3>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end ">
                      <div className="d-flex justify-content-end ">

                        <h5 className="heading-4 product-price text-secondary">

                          (PRODUCT TOTAL ₹             </h5>
                        <h5 className="heading-4 product-price text-secondary">

                          {couponSuccess ? <><s style={{ color: '#615EFC' }}> {calculateTotalMRP()}</s> <div> {calculateTotalMRPCoupon()})</div> </> : <div>  {calculateTotalMRP()})</div>}
                        </h5>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end ">
                      <div className="d-flex justify-content-start ">

                        <h5 className="heading-4 product-price text-secondary">

                          (SHIPPING CHARGE ₹              </h5>
                        <h5 className="heading-4 product-price text-secondary">
                          {calculateTotalSHIPPING()})
                        </h5>


                      </div>
                    </div>
                    {!delivery ?
                      <div className="d-flex justify-content-end gap-5 mt-5">
                        <div className="d-flex justify-content-between gap-5 mt-5">
                          <button
                            type="button"
                            className="btn btn-fill-out btn-block hover-up"
                            // onClick={handlePayment}
                            onClick={() => setDelivery(!delivery)}
                            aria-controls="delivery-full"
                            aria-expanded={delivery}
                          >
                            Buy Now
                          </button>{" "}
                        </div>
                      </div>
                      :
                      <div className="d-flex justify-content-end gap-5 mt-5">
                        <div className="d-flex justify-content-between gap-5 mt-5">
                          <button
                            type="button"
                            className="btn btn-fill-out btn-block hover-up"
                            // onClick={handlePayment}
                            onClick={() => setDelivery(!delivery)}
                            aria-controls="delivery-full"
                            aria-expanded={delivery}
                          >
                            Back
                          </button>{" "}
                        </div>
                      </div>
                    }

                  </>
                )}
                <Collapse in={delivery}>
                  <a className="btn btn-lg mb-2 mt-30 w-100"
                    style={{
                      backdropFilter: 'inherit',
                      backgroundBlendMode: 'color-burn',
                      backgroundColor: '#3bb77e',
                      fontWeight: 'bolder',
                      color: '#fff',
                      fontSize: '14px',
                      boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'
                    }}
                  >
                    <div className="mt-3" id="delivery-full">
                      <div className="col-lg-12">
                        <div className="d-flex justify-content-start">
                          <h1 className="heading-2 mb-10" style={{ color: '#fff', fontSize: '20px', }}>Delivery Address</h1>

                        </div>
                      </div>
                      <div className="pt-4">
                        <div className=" p-3 rounded border border-warning"
                          style={{
                            backgroundColor: '#fff',
                            alignContent: 'start'
                          }}
                        >
                          <h6 className="heading-2 border-bottom pb-1 text-info d-flex justify-content-between">
                            {" "}
                            <>
                              <Button
                                onClick={() => {
                                  setNewDelivery(!newDelivery); setOpen(false);
                                }}
                                aria-controls="example-new-text"
                                aria-expanded={newDelivery}
                              >   Add a New Delivery Address
                              </Button>
                              {/* <Button
                              onClick={() => {
                                setNewDelivery(false); setOpen(!open);
                              }}
                              aria-controls="example-collapse-text"
                              aria-expanded={open}
                            >Edit Primary Address
                            </Button> */}
                            </>
                          </h6>
                          <div className="" style={{
                            textAlign: 'start', color: '#3bb77e'
                          }}>
                            {address?.map((item, i) => (
                              <div className="my-3" key={i}>
                              <div>
                                <h3 className="heading-4 product-price" style={{ color: '#3bb77e' }}>
                                  <input
                                    className="form-check-input mt-2"
                                    type="radio"
                                    name="delivery_address"
                                    id={`delivery-address-${i}`}
                                    onChange={() => setDelAd(item?.delivery_address_id)}
                                  /> {item?.first_name} {item?.last_name}
                                  &nbsp;
                                  <svg xmlns="http://www.w3.org/2000/svg" onClick={()=>handleDeleteAddress(item?.delivery_address_id)} width="1em" height="1em" viewBox="0 0 48 48"><path fill="#3bb77e" d="M20 10.5v.5h8v-.5a4 4 0 0 0-8 0m-2.5.5v-.5a6.5 6.5 0 1 1 13 0v.5h11.25a1.25 1.25 0 1 1 0 2.5h-2.917l-2 23.856A7.25 7.25 0 0 1 29.608 44H18.392a7.25 7.25 0 0 1-7.224-6.644l-2-23.856H6.25a1.25 1.25 0 1 1 0-2.5zm4 9.25a1.25 1.25 0 1 0-2.5 0v14.5a1.25 1.25 0 1 0 2.5 0zM27.75 19c-.69 0-1.25.56-1.25 1.25v14.5a1.25 1.25 0 1 0 2.5 0v-14.5c0-.69-.56-1.25-1.25-1.25"/></svg>
                                </h3>
                                {item?.house_name && item?.city && item?.pincode ? (
                                  <h5 className="heading-4 product-price" style={{ color: '#3bb77e' }}>
                                    {item?.house_name}, {item?.street_name}, {item?.city}, {item?.state}, {item?.country_name}, {item?.pincode}, PH NO : {item?.contact}
                                  </h5>
                                ) : (
                                  <>No more details</>
                                )}
                              </div>
                            </div>
                            ))}


                          </div></div>

                      </div>

                      <Collapse in={open}>
                        <div
                          className="pt-4"
                          data-wow-delay="0.1s"
                          id="example-collapse-text"

                        >
                          <div className="form__content">
                            <div className="row">

                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="form-group">
                                  <label htmlFor="name">First Name</label>{" "}
                                  <sup style={{ color: "red", fontSize: "13px" }}>
                                    *
                                  </sup>
                                  <input
                                    className="form-control"
                                    name="product_name"
                                    id="txt-name"
                                    type="text"
                                    // required
                                    value={formData?.first_name || ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        first_name: e.target.value,
                                      })
                                    }
                                    placeholder="First Name"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="form-group">
                                  <label htmlFor="name">Last Name</label>{" "}
                                  <sup style={{ color: "red", fontSize: "13px" }}>
                                    *
                                  </sup>
                                  <input
                                    className="form-control"
                                    name="last_name"
                                    id="txt-name"
                                    type="text"
                                    // required
                                    value={formData?.last_name || ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        last_name: e.target.value,
                                      })
                                    }
                                    placeholder="Last Name"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-12">
                                <div className="form-group">
                                  <label htmlFor="name">House Name / House Number / Apartment</label>{" "}
                                  <sup style={{ color: "red", fontSize: "13px" }}>
                                    *
                                  </sup>
                                  <input
                                    className="form-control"
                                    name="house_name"
                                    id="txt-name"
                                    type="text"
                                    // required
                                    value={formData?.house_name || ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        house_name: e.target.value,
                                      })
                                    }
                                    placeholder="House Name / House Number / Apartment"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="form-group">
                                  <label htmlFor="name">Street Name</label>{" "}
                                  {/* <sup style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </sup> */}
                                  <input
                                    className="form-control"
                                    name="street_name"
                                    id="txt-name"
                                    type="text"
                                    // required
                                    value={formData?.street_name || ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        street_name: e.target.value,
                                      })
                                    }
                                    placeholder="Street Name"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="form-group">
                                  <label htmlFor="city">City</label>
                                  <sup style={{ color: "red", fontSize: "13px" }}>
                                    *
                                  </sup>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="city"
                                    required
                                    value={formData?.city || ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        city: e.target.value,
                                      })
                                    }
                                    placeholder="City"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="form-group">
                                  <label htmlFor="state">State</label>
                                  <sup style={{ color: "red", fontSize: "13px" }}>
                                    *
                                  </sup>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="state"
                                    required
                                    value={formData?.state || ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        state: e.target.value,
                                      })
                                    }
                                    placeholder="State"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-6">

                                <div className="form-group">
                                  <label htmlFor="country">Country</label>
                                  <sup style={{ color: "red", fontSize: "13px" }}>*</sup>
                                  <select
                                    className="form-control"
                                    name="category_name"
                                    id="txt-name"
                                    required
                                    value={formData?.country || ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        country: e.target.value,
                                      })
                                    }
                                  >
                                    <option>- select -</option>
                                    {count.map((val) => {
                                      return (
                                        <option value={val.country_id}>
                                          {val.countryname}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>


                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="form-group">
                                  <label htmlFor="pincode">Pin Code</label>
                                  <sup style={{ color: "red", fontSize: "13px" }}>
                                    *
                                  </sup>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="pincode"
                                    required
                                    value={formData?.pincode || ""}
                                    maxLength={6}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        pincode: e.target.value.replace(/[^0-9]/g, ""),
                                      })
                                    }
                                    placeholder="Pincode"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-6">
                                <div className="form-group">
                                  <label htmlFor="street_name">
                                    Contact Number
                                  </label>
                                  <sup style={{ color: "red", fontSize: "13px" }}>
                                    *
                                  </sup>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="phone"
                                    minLength={6}
                                    maxLength={12}
                                    required
                                    value={formData?.contact || ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        contact: e.target.value.replace(/[^0-9+]/g, ""),
                                      })
                                    }
                                    placeholder="Primary contact number"
                                  />
                                </div>
                              </div>
                              <div className="login_footer form-group">
                                <div className="chek-form">
                                  <div className="custome-checkbox">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="agree_terms_and_policy"
                                      id="agree-terms-and-policy"
                                      checked={formData?.is_primary === 0} // Check if is_primary is 1
                                      onChange={(e) =>
                                        setFormData({
                                          ...formData,
                                          is_primary: e.target.checked ? 0 : 1, // Set to 1 if checked, 0 otherwise
                                        })
                                      }
                                    />

                                    <label
                                      className="form-check-label"
                                      for="agree-terms-and-policy"
                                    >
                                      <span>
                                        <a>
                                          Set this as my primary address.</a>
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end gap-5 ">
                                <div className="d-flex justify-content-between gap-5">
                                  <h3 className="heading-4 product-price text-success">

                                    SUBTOTAL              </h3>
                                  <h3 className="heading-4 product-price text-success">
                                    {couponSuccess ? <><s style={{ color: '#3bb77e' }}>₹ {calculateTotalMRP() + calculateTotalSHIPPING()}</s> <div>₹ {calculateTotalMRPCoupon() + calculateTotalSHIPPING()}</div> </> : <div> ₹ {calculateTotalMRP() + calculateTotalSHIPPING()}</div>}
                                  </h3>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end ">
                                <div className="d-flex justify-content-end ">

                                  <h5 className="heading-4 product-price text-secondary">

                                    (PRODUCT TOTAL ₹             </h5>
                                  <h5 className="heading-4 product-price text-secondary">

                                    {couponSuccess ? <><s style={{ color: '#615EFC' }}> {calculateTotalMRP()}</s> <div> {calculateTotalMRPCoupon()})</div> </> : <div>  {calculateTotalMRP()})</div>}
                                  </h5>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end ">
                                <div className="d-flex justify-content-start ">

                                  <h5 className="heading-4 product-price text-secondary">

                                    (SHIPPING CHARGE ₹              </h5>
                                  <h5 className="heading-4 product-price text-secondary">
                                    {calculateTotalSHIPPING()})
                                  </h5>


                                </div>
                              </div>
                              <div className="form-group">
                                <button
                                  type="button"
                                  className="btn hover-up"
                                  style={{ backgroundColor: 'red' }}
                                  onClick={() => handleSubmit()}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                      <Collapse in={newDelivery}>
                        <div
                          className="pt-4"
                          data-wow-delay="0.1s"
                          id="example-new-text"

                        >
                          <div className="form__content">
                            <div className="row">

                              <div className=" col-12">
                                <div className="form-group">
                                  <label htmlFor="name">First Name</label>{" "}
                                  <sup style={{ color: "red", fontSize: "13px" }}>
                                    *
                                  </sup>
                                  <input
                                    className="form-control"
                                    name="product_name"
                                    id="txt-name"
                                    type="text"
                                    // required
                                    style={{backgroundColor:'#fff', color:'#000', border:'1px solid #000'}}
                                    value={newAdd?.first_name || ""}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        first_name: e.target.value,
                                      })
                                    }
                                    placeholder="First Name"
                                  />
                                </div>
                              </div>
                              <div className=" col-12">
                                <div className="form-group">
                                  <label htmlFor="name">Last Name</label>{" "}
                                  <sup style={{ color: "red", fontSize: "13px" }}>
                                    *
                                  </sup>
                                  <input
                                    className="form-control"
                                    name="last_name"
                                    id="txt-name"
                                    style={{backgroundColor:'#fff', color:'#000', border:'1px solid #000'}}
                                    type="text"
                                    // required
                                    value={newAdd?.last_name || ""}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        last_name: e.target.value,
                                      })
                                    }
                                    placeholder="Last Name"
                                  />
                                </div>
                              </div>
                              <div className=" col-12">
                                <div className="form-group">
                                  <label htmlFor="name">House Name / House Number / Apartment</label>{" "}
                                  <sup style={{ color: "red", fontSize: "13px" }}>
                                    *
                                  </sup>
                                  <input
                                    className="form-control"
                                    name="house_name"
                                    id="txt-name"
                                    type="text"
                                    // required
                                    style={{backgroundColor:'#fff', color:'#000', border:'1px solid #000'}}
                                    value={newAdd?.house_name || ""}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        house_name: e.target.value,
                                      })
                                    }
                                    placeholder="House Name / House Number / Apartment"
                                  />
                                </div>
                              </div>
                              <div className=" col-12">
                                <div className="form-group">
                                  <label htmlFor="name">Street Name</label>{" "}
                                  <sup style={{ color: "red", fontSize: "13px" }}>
                                    *
                                  </sup>
                                  <input
                                    className="form-control"
                                    name="street_name"
                                    id="txt-name"
                                    type="text"
                                    style={{backgroundColor:'#fff', color:'#000', border:'1px solid #000'}}
                                    value={newAdd?.street_name || ""}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        street_name: e.target.value,
                                      })
                                    }
                                    placeholder="Street Name"
                                  />
                                </div>
                              </div>

                              <div className=" col-12">
                                <div className="form-group">
                                  <label htmlFor="city">City</label>
                                  <sup style={{ color: "red", fontSize: "13px" }}>
                                    *
                                  </sup>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="city"
                                    required
                                    style={{backgroundColor:'#fff', color:'#000', border:'1px solid #000'}}
                                    value={newAdd?.city || ""}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        city: e.target.value,
                                      })
                                    }
                                    placeholder="City"
                                  />
                                </div>
                              </div>
                              <div className=" col-12">
                                <div className="form-group">
                                  <label htmlFor="state">State</label>
                                  <sup style={{ color: "red", fontSize: "13px" }}>
                                    *
                                  </sup>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="state"
                                    required
                                    style={{backgroundColor:'#fff', color:'#000', border:'1px solid #000'}}
                                    value={newAdd?.state || ""}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        state: e.target.value,
                                      })
                                    }
                                    placeholder="State"
                                  />
                                </div>
                              </div>
                              <div className=" col-12">

                                <div className="form-group">
                                  <label htmlFor="country">Country</label>
                                  <sup style={{ color: "red", fontSize: "13px" }}>*</sup>
                                  <select
                                    className="form-control"
                                    name="category_name"
                                    id="txt-name"
                                    required
                                    style={{backgroundColor:'#fff', color:'#000', border:'1px solid #000'}}
                                    value={newAdd?.country || ""}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        country: e.target.value,
                                      })
                                    }
                                  >
                                    <option>- select -</option>
                                    {count.map((val) => {
                                      return (
                                        <option value={val.country_id}>
                                          {val.countryname}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>

                              <div className=" col-12">
                                <div className="form-group">
                                  <label htmlFor="pincode">Pin Code</label>
                                  <sup style={{ color: "red", fontSize: "13px" }}>
                                    *
                                  </sup>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="pincode"
                                    required
                                    style={{backgroundColor:'#fff', color:'#000', border:'1px solid #000'}}
                                    value={newAdd?.pincode || ""}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        pincode: e.target.value.replace(/[^0-9]/g, ""),
                                      })
                                    }
                                    placeholder="Pincode"
                                  />
                                </div>
                              </div>
                              <div className=" col-12">
                                <div className="form-group">
                                  <label htmlFor="street_name">
                                    Contact Number
                                  </label>
                                  <sup style={{ color: "red", fontSize: "13px" }}>
                                    *
                                  </sup>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="phone"
                                    style={{backgroundColor:'#fff', color:'#000', border:'1px solid #000'}}
                                    minLength={6}
                                    maxLength={12}
                                    required
                                    value={newAdd?.contact || ""}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        contact: e.target.value.replace(/[^0-9+]/g, ""),
                                      })
                                    }
                                    placeholder="Primary contact number"
                                  />
                                </div>
                              </div>
                              {/* <div className="login_footer form-group">
                              <div className="chek-form">
                                <div className="custome-checkbox">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="agree_terms_and_policy"
                                    id="agree-terms-and-policy"
                                    checked={newAdd.is_primary === 0}
                                    onChange={(e) =>
                                      setNewAdd({
                                        ...newAdd,
                                        is_primary: e.target.checked ? 0 : 1,                                      })
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    for="agree-terms-and-policy"
                                  >
                                    <span>
                                      <a>
                                        Set this as my primary address.</a>
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div> */}
                              <div className="d-flex justify-content-end gap-5 ">
                                <div className="d-flex justify-content-between gap-5">
                                  <h5 className="heading-4 product-price text-white">

                                    SUBTOTAL              </h5>
                                  <h5 className="heading-4 product-price text-white">
                                    {couponSuccess ? <><s style={{ color: '#B06161' }}>₹ {calculateTotalMRP() + calculateTotalSHIPPING()}</s> <div>₹ {calculateTotalMRPCoupon() + calculateTotalSHIPPING()}</div> </> : <div> ₹ {calculateTotalMRP() + calculateTotalSHIPPING()}</div>}
                                  </h5>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end ">
                                <div className="d-flex justify-content-end ">

                                  <h6 className="heading-4 product-price text-secondary">

                                    (PRODUCT TOTAL ₹             </h6>
                                  <h6 className="heading-4 product-price text-secondary">

                                    {couponSuccess ? <><s style={{ color: '#B06161' }}> {calculateTotalMRP()}</s> <div> {calculateTotalMRPCoupon()})</div> </> : <div>  {calculateTotalMRP()})</div>}
                                  </h6>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end ">
                                <div className="d-flex justify-content-start ">

                                  <h6 className="heading-4 product-price text-secondary">

                                    (SHIPPING CHARGE ₹              </h6>
                                  <h6 className="heading-4 product-price text-secondary">
                                    {calculateTotalSHIPPING()})
                                  </h6>


                                </div>
                              </div>
                              <div className="form-group mt-20">
                                <button
                                  type="button"
                                  className="btn btn-fill-out btn-block hover-up btn-secondary"
                                  onClick={() => handleSubmitNewAddress()}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                      {!newDelivery && !open &&
                        <>
                          <div className="d-flex justify-content-end gap-5 ">
                            <div className="d-flex justify-content-between gap-5">
                              <h5 className="heading-4 product-price text-white">

                                SUBTOTAL             </h5>
                              <h5 className="heading-4 product-price text-white">
                                {couponSuccess ? <><s style={{ color: '#B06161' }}>₹ {calculateTotalMRP() + calculateTotalSHIPPING()}</s> <div>₹ {calculateTotalMRPCoupon() + calculateTotalSHIPPING()}</div> </> : <div> ₹ {calculateTotalMRP() + calculateTotalSHIPPING()}</div>}
                              </h5>
                            </div>
                          </div>
                          <div className="d-flex justify-content-end ">
                            <div className="d-flex justify-content-end ">

                              <h6 className="heading-4 product-price text-secondary">

                                (PRODUCT TOTAL ₹             </h6>
                              <h6 className="heading-4 product-price text-secondary">

                                {couponSuccess ? <><s style={{ color: '#B06161' }}> {calculateTotalMRP()}</s> <div> {calculateTotalMRPCoupon()})</div> </> : <div>  {calculateTotalMRP()})</div>}
                              </h6>
                            </div>
                          </div>
                          <div className="d-flex justify-content-end ">
                            <div className="d-flex justify-content-start ">
                              <h6 className="heading-4 product-price text-secondary">
                                (SHIPPING CHARGE ₹              </h6>
                              <h6 className="heading-4 product-price text-secondary">
                                {calculateTotalSHIPPING()})
                              </h6>
                            </div>
                          </div>
                          <div className="d-flex justify-content-end gap-5 mt-5">
                            <div className="d-flex justify-content-between gap-5 mt-5">
                              <button
                                type="button"
                                className="btn btn-fill-out btn-block btn-secondary hover-up"
                                // onClick={handlePayment}
                                onClick={handleOrder}
                              >
                                Check Out
                              </button>
                            </div>
                          </div>
                        </>

                      }
                    </div>

                  </a>

                </Collapse>

              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        <ScrollToTop />

      </main>
    </>
  );
}
export default Cart;
