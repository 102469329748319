import React, { useState, useEffect } from "react";
import "../../../../App.css";

function Loader() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Stop loader after 1 second (adjust as needed)

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return null; // Do not render anything if loading is false
  }

  return (
    <div
      className={`preloader ${loading ? "loading" : ""}`}
      id="preloader-active"
    >
      <div className="preloader-loading"></div>
    </div>
  );
}

export default Loader;
