import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { producturl } from '../../../path';

const ImageCarousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlers = useSwipeable({
        onSwipedLeft: () => handleNext(),
        onSwipedRight: () => handlePrevious(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    const handleIndicatorClick = (index) => {
        setCurrentIndex(index);
    };

    return (
        <div className="position-relative" >
            <div className="row justify-content-center">
                <div className="col-12 position-relative" {...handlers} style={{ overflow: 'hidden', textAlign: 'center' }}>
                    <img
                        src={producturl + images[currentIndex]}
                        alt={`Slide ${currentIndex}`}
                        style={{ width: '100%', maxHeight: '500px', objectFit: 'cover', border: '1px solid #3bb77e', borderRadius: '10px' }}
                    />
                    <div className="position-absolute w-100" style={{ bottom: '10px', left: '0' }}>
                        <div className="d-flex justify-content-center">
                            {images.map((_, index) => (
                                <div
                                    key={index}
                                    onClick={() => handleIndicatorClick(index)}
                                    style={{
                                        width: '10px',
                                        height: '10px',
                                        borderRadius: '50%',
                                        border: '1px solid #3bb77e',
                                        background: currentIndex === index ? 'white' : 'transparent',
                                        margin: '0 5px',
                                        cursor: 'pointer',
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImageCarousel;
