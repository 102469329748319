// ExampleCarouselImage.js

import React from "react";
import { carouselurl } from "../../../../../../path";

const ExampleCarouselImage = ({ imageUrl, text }) => (
  <div className="zoom-effect">
    <img
      src={carouselurl + imageUrl}
      alt={text}
      style={{
        width: "100%",
        height: "auto",
        objectFit: "cover",
        borderRadius: "10px",
      }}
    />
  </div>
);

export default ExampleCarouselImage;
