import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { backend } from "../../../../path";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Loader from "../../../layout/header/loader";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../../components/whatsapp connect";
import LoaderMain from "../../../layout/header/loader_sub";

function Registration() {
  const [loading, setLoading] = useState();
  const [formData, setFormData] = useState([]);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  useEffect(() => {
    // Scroll to the top of the page whenever a new page is rendered
    window.scrollTo(0, 0);
  }, []);
  const handleSubmit = () => {
    const requiredFields = [
      "first_name",
      "last_name",
      "email",
      "password",
      "password_confirmation",
    ];
    const emptyFields = requiredFields.filter((field) => !formData[field]);
  
    if (emptyFields.length > 0) {
      toast.error("Please fill in all required fields", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Prevent further execution
    }

    // Email format validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      toast.error("Please enter a valid email address", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Prevent further execution
    }
  
    // Password length validation
    if (formData.password.length < 6) {
      toast.error("Weak Password Found, Try Another With Atleast 6 Characters Long", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Prevent further execution
    }
  
    if (formData.password !== formData.password_confirmation) {
      toast.error("Password Unmatched, Try Again", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
  
    const agreeCheckbox = document.getElementById("agree-terms-and-policy");
    if (!agreeCheckbox.checked) {
      toast.error("Please agree to the terms & policies", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Prevent further execution
    }
    setLoading(true);

    const type = 1;
    formData.type = type;
  
    fetch(backend + "registration", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        setLoading(false);
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );
  
          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            setLoading(false);
            console.error("Email Already Exists");
            toast.error("Email Already Exists", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
        setLoading(false);            
        toast.error("Registration failed", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
  
          return;
        }
  
        response.json().then(() => {
          setLoading(false);
          navigate("/login");
          toast.success("Successfully Registered", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  

  return (
    <>
    {loading &&
    <LoaderMain/>
    }
      <main className="main" id="main-section">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <div className="breadcrumb-item d-inline-block">
                <a href="index.html" title="Home">
                  {" "}
                  Home{" "}
                </a>
              </div>
              <span></span>
              <div className="breadcrumb-item d-inline-block active">
                <div itemprop="item"> Register </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-content pt-150 pb-150">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
                <div className="row">
                  <div className="col-lg-6 col-md-8">
                    <div className="login_wrap widget-taber-content background-white">
                      <div className="padding_eight_all bg-white">
                        <div className="padding_eight_all bg-white">
                          <h3 className="mb-20">Register</h3>
                          <p>Please fill in the information below</p>
                          <br></br>
                        </div>
                        <form
                        // onSubmit={handleSubmit}
                        >
                          <input
                            type="hidden"
                            name="_token"
                            value="bVwKUehCmCMBBOuQToUzdDhTTdTBfg78RlsalVJQ"
                            autocomplete="off"
                          />
                          <div className="form__content">
                            <div className="form-group">
                              <input
                                className="form-control"
                                name="name"
                                id="txt-name"
                                type="text"
                                value={formData.first_name || ""}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    first_name: e.target.value,
                                  })
                                }
                                placeholder="Your first name"
                              />
                            </div>
                            <div className="form-group">
                              <input
                                className="form-control"
                                name="name"
                                id="txt-name"
                                type="text"
                                value={formData.last_name || ""}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    last_name: e.target.value,
                                  })
                                }
                                placeholder="Your last name"
                              />
                            </div>
                            <div className="form-group">
                              <input
                                className="form-control"
                                name="email"
                                id="txt-email"
                                type="email"
                                value={formData.email || ""}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    email: e.target.value,
                                  })
                                }
                                placeholder="Your email address"
                              />
                            </div>
                            <div className="form-group">
                              <input
                                className="form-control"
                                type={showPassword ? "text" : "password"}
                                name="password"
                                value={formData.password || ""}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    password: e.target.value,
                                  })
                                }
                                placeholder="Your password"
                              />
                              <div className="position-relative">
                                <div
                                  className=" position-absolute password-toggle-icon"
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  {showPassword ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={32}
                                      height={32}
                                      viewBox="0 0 512 512"
                                    >
                                      <path
                                        fill="#3BB77E"
                                        d="m63.998 86.005l21.998-21.998L447.999 426.01l-21.998 21.998zM259.34 192.09l60.57 60.57a64.07 64.07 0 0 0-60.57-60.57m-6.68 127.82l-60.57-60.57a64.07 64.07 0 0 0 60.57 60.57"
                                      ></path>
                                      <path
                                        fill="#45B755"
                                        d="M256 352a96 96 0 0 1-92.6-121.34l-69.07-69.08C66.12 187.42 39.24 221.14 16 256c26.42 44 62.56 89.24 100.2 115.18C159.38 400.92 206.33 416 255.76 416A233.47 233.47 0 0 0 335 402.2l-53.61-53.6A95.84 95.84 0 0 1 256 352m0-192a96 96 0 0 1 92.6 121.34L419.26 352c29.15-26.25 56.07-61.56 76.74-96c-26.38-43.43-62.9-88.56-101.18-114.82C351.1 111.2 304.31 96 255.76 96a222.92 222.92 0 0 0-78.21 14.29l53.11 53.11A95.84 95.84 0 0 1 256 160"
                                      ></path>
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={32}
                                      height={32}
                                      viewBox="0 0 1024 1024"
                                    >
                                      <path
                                        fill="#3BB77E"
                                        d="M396 512a112 112 0 1 0 224 0a112 112 0 1 0-224 0m546.2-25.8C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 0 0 0 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3c7.7-16.2 7.7-35 0-51.5M508 688c-97.2 0-176-78.8-176-176s78.8-176 176-176s176 78.8 176 176s-78.8 176-176 176"
                                      ></path>
                                    </svg>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <input
                                className="form-control"
                                type={showPasswordConfirm ? "text" : "password"}
                                name="password_confirmation"
                                id="txt-password-confirmation"
                                placeholder="Password confirmation"
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    password_confirmation: e.target.value,
                                  })
                                }
                              />
                              <div className="position-relative btn-block hover-up">
                                <div
                                  className=" position-absolute password-toggle-icon"
                                  onClick={() =>
                                    setShowPasswordConfirm(!showPasswordConfirm)
                                  }
                                >
                                  {showPasswordConfirm ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={32}
                                      height={32}
                                      viewBox="0 0 512 512"
                                    >
                                      <path
                                        fill="#3BB77E"
                                        d="m63.998 86.005l21.998-21.998L447.999 426.01l-21.998 21.998zM259.34 192.09l60.57 60.57a64.07 64.07 0 0 0-60.57-60.57m-6.68 127.82l-60.57-60.57a64.07 64.07 0 0 0 60.57 60.57"
                                      ></path>
                                      <path
                                        fill="#45B755"
                                        d="M256 352a96 96 0 0 1-92.6-121.34l-69.07-69.08C66.12 187.42 39.24 221.14 16 256c26.42 44 62.56 89.24 100.2 115.18C159.38 400.92 206.33 416 255.76 416A233.47 233.47 0 0 0 335 402.2l-53.61-53.6A95.84 95.84 0 0 1 256 352m0-192a96 96 0 0 1 92.6 121.34L419.26 352c29.15-26.25 56.07-61.56 76.74-96c-26.38-43.43-62.9-88.56-101.18-114.82C351.1 111.2 304.31 96 255.76 96a222.92 222.92 0 0 0-78.21 14.29l53.11 53.11A95.84 95.84 0 0 1 256 160"
                                      ></path>
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={32}
                                      height={32}
                                      viewBox="0 0 1024 1024"
                                    >
                                      <path
                                        fill="#3BB77E"
                                        d="M396 512a112 112 0 1 0 224 0a112 112 0 1 0-224 0m546.2-25.8C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 0 0 0 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3c7.7-16.2 7.7-35 0-51.5M508 688c-97.2 0-176-78.8-176-176s78.8-176 176-176s176 78.8 176 176s-78.8 176-176 176"
                                      ></path>
                                    </svg>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="login_footer form-group">
                            <div className="chek-form">
                              <div className="custome-checkbox">
                                <input
                                  type="hidden"
                                  name="agree_terms_and_policy"
                                  value="0"
                                />
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="agree_terms_and_policy"
                                  id="agree-terms-and-policy"
                                  value="1"
                                />
                                <label
                                  className="form-check-label"
                                  for="agree-terms-and-policy"
                                >
                                  <span>
                                    <a href="/policy">
                                    I agree to the terms &amp; policies.</a>
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <button
                              type="button"
                              className="btn btn-fill-out btn-block hover-up"
                              onClick={handleSubmit}
                            >
                              Continue
                            </button>
                          </div>
                          <br></br>
                          <p>
                            Have an account already?{" "}
                            <a href="/login" className="d-inline-block">
                              Login
                            </a>
                          </p>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 pr-20 d-none d-md-block d-lg-block">
                    {/* <img
                      className="svgInject"
                      alt="Compare"
                      src="storage/general/registration_cart.jpg"
                      height={800}
                    /> */}
                    {/* <Icon icon="noto:shopping-cart" width="750" /> */}
                    <Icon icon="noto:shopping-bags" width="650" hFlip={true} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollToTop/>
      </main>
      <ToastContainer />
    </>
  );
}

export default Registration;
